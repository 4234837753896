import {
  COOKIE_ONLY_NECESSARY,
  COOKIE_USER_PREFERENCE,
} from '../../constants/constants';
import { getCookieValue, isCookieSet, setCookie } from './cookie-repository';

export const isUserPreferenceCookieSet = () => {
  return isCookieSet(COOKIE_USER_PREFERENCE);
};

export const setUserPreferenceCookie = (value: string) => {
  setCookie(COOKIE_USER_PREFERENCE, value);
};

export const isCookieUseDisabledByUser = () => {
  const userPreferenceValue = getCookieValue(COOKIE_USER_PREFERENCE);
  if (
    userPreferenceValue === COOKIE_ONLY_NECESSARY ||
    userPreferenceValue === ''
  ) {
    return true;
  } else {
    return false;
  }
};
