import { Theme } from '@mui/material/styles';
import { theme as msgTheme } from './configuration/themes/msg/theme';
import { theme as inPunctoTheme } from './configuration/themes/inPuncto/theme';
import { appConfiguration } from './configuration';

/**
 * @description This represents the custom distances from the theme
 * @example padding: Distances.BIG
 */
export enum Distances {
  ///////////////////////////////////////////////////////
  ///////////// Custom Distances /////////////////////////
  ///////////////////////////////////////////////////////
  BIG = '80px',
  FIELD = '55px',
  HALF_FIELD_30 = '30px',
  HALF_FIELD_20 = '20px',
  INPUT = '10px',
  BUTTON_LENGTH = '190px',
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    red: Palette['primary'];
    yellow: Palette['primary'];
    accentYellow: Palette['primary'];
    lightRed: Palette['primary'];
    accentRed: Palette['primary'];
    accentGreen: Palette['primary'];
    infoGreen: Palette['primary'];
    white: Palette['primary'];
    disabled: Palette['primary'];
    middleGrey: Palette['primary'];
    border: Palette['primary'];
    darkGrey: Palette['primary'];
    typography: Palette['primary'];
    lightBrown: Palette['primary'];
  }
  interface PaletteOptions {
    red: PaletteOptions['primary'];
    yellow: PaletteOptions['primary'];
    accentYellow: PaletteOptions['primary'];
    lightRed: PaletteOptions['primary'];
    accentRed: PaletteOptions['primary'];
    accentGreen: PaletteOptions['primary'];
    infoGreen: PaletteOptions['primary'];
    white: PaletteOptions['primary'];
    disabled: PaletteOptions['primary'];
    middleGrey: PaletteOptions['primary'];
    border: PaletteOptions['primary'];
    darkGrey: PaletteOptions['primary'];
    typography: PaletteOptions['primary'];
    lightBrown: PaletteOptions['primary'];
  }
}

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    header0: React.CSSProperties;
    header1: React.CSSProperties;
    header1smaller: React.CSSProperties;
    header2: React.CSSProperties;
    profile: React.CSSProperties;
    header3: React.CSSProperties;
    text: React.CSSProperties;
    menu: React.CSSProperties;
    textBold: React.CSSProperties;
    noteItalic: React.CSSProperties;
    buttons: React.CSSProperties;
    search: React.CSSProperties;
    labelButton: React.CSSProperties;
  }
  interface TypographyOptions {
    header0?: React.CSSProperties;
    header1?: React.CSSProperties;
    header1smaller?: React.CSSProperties;
    header2?: React.CSSProperties;
    profile?: React.CSSProperties;
    header3?: React.CSSProperties;
    text?: React.CSSProperties;
    textBold?: React.CSSProperties;
    noteItalic?: React.CSSProperties;
    menu?: React.CSSProperties;
    buttons?: React.CSSProperties;
    search?: React.CSSProperties;
    labelButton?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    header0: true;
    header1: true;
    header1smaller: true;
    header2: true;
    profile: true;
    header3: true;
    text: true;
    textBold: true;
    noteItalic: true;
    menu: true;
    buttons: true;
    search: true;
    labelButton: true;
  }
}

const themes: { [env: string]: Theme } = {
  msg: msgTheme,
  inPuncto: inPunctoTheme,
};

export const theme = themes[appConfiguration()?.theme || 'msg'];
