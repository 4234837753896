import { createTheme } from '@mui/material/styles';

// eslint-disable-next-line import/no-unused-modules
export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1800,
    },
  },

  /**
   * @description This represents the custom colors from the theme
   * @example color: theme.palette.yellow.main
   */
  palette: {
    ///////////////////////////////////////////////////////
    ///////////////// Custom Colors ///////////////////////
    ///////////////////////////////////////////////////////
    primary: {
      light: '#9c4360',
      main: '#A01441', //Primary red
      dark: '#5c0e27',
    },
    secondary: {
      light: '#b3d3df',
      main: '#56A3BC', //Accent blue
      dark: '#3f7e96',
    },
    yellow: {
      main: '#F7CD7F',
    },
    red: {
      main: '#e74337',
    },
    accentYellow: {
      main: '#F3AC57',
    },
    lightRed: {
      main: '#ED6C96',
    },
    accentRed: {
      main: '#DB1B59',
    },
    accentGreen: {
      main: '#82A7A6',
    },
    infoGreen: {
      main: '#74CD63',
    },
    white: {
      main: '#FFFFFF',
    },
    disabled: {
      main: '#F1F1F1',
    },
    middleGrey: {
      main: '#E6E6E6',
    },
    border: {
      main: '#BABABA',
    },
    darkGrey: {
      main: '#6F6F6F',
    },
    typography: {
      main: '#1C1F37',
    },
    lightBrown: {
      main: '#C6B89F',
    },
    ///////////////////////////////////////////////////////
    error: {
      main: '#c4000e',
    },
    success: {
      main: '#5cb85c',
    },
    grey: {
      '50': '#fafafa',
      '100': '#f2f2f2',
      '200': '#eeeeee',
      '300': '#e0e0e0',
      '400': '#bdbdbd',
      '500': '#9f9f9f',
      '600': '#757575',
      '700': '#616161',
      '800': '#424242',
      '900': '#212121',
      A100: 'rgba(234, 234, 234, 0.25)',
      A200: '#CBCBCB',
      A400: '#6F6F6F',
    },
    text: {
      primary: '#1C1F37',
    },
    background: {
      default: '#FCFCFC', //needs to be the new color for the background but needs careful verification at redesign
      paper: '#ffffff', //'#FCFCFC'
    },
  },
  /**
   * @description This represents the custom typography from the theme
   * @example <Typography variant="header1"> Hello </Typography>
   */
  typography: {
    fontFamily: ['"Open Sans"', 'Roboto', 'Arial', 'sans-serif'].join(','),
    fontSize: 16,
    ///////////////////////////////////////////////////////
    ///////////// Custom Variants /////////////////////////
    ///////////////////////////////////////////////////////
    header0: {
      fontFamily: ['Calibri', 'sans-serif'].join(','),
      fontSize: '40px',
      fontWeight: 'bold',
    },
    header1: {
      fontFamily: ['Calibri', 'sans-serif'].join(','),
      fontSize: '32px',
      fontWeight: 'bold',
    },
    header1smaller: {
      fontFamily: ['Calibri', 'sans-serif'].join(','),
      fontSize: '24px',
      fontWeight: 'bold',
    },
    header2: {
      fontFamily: ['Calibri', 'sans-serif'].join(','),
      fontSize: '18px',
      fontWeight: 'bold',
    },
    header3: {
      fontFamily: ['Calibri', 'sans-serif'].join(','),
      fontSize: '16px',
      fontWeight: 'bold',
    },
    profile: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: '14px',
      fontWeight: 'bold',
    },
    textBold: {
      fontFamily: ['Calibri', 'sans-serif'].join(','),
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '20px',
    },
    text: {
      fontFamily: ['Calibri', 'sans-serif'].join(','),
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
    },
    noteItalic: {
      fontFamily: ['Calibri', 'sans-serif'].join(','),
      fontSize: '11px',
      fontWeight: '400',
      fontStyle: 'italic',
    },
    menu: {
      fontFamily: ['Calibri', 'sans-serif'].join(','),
      fontSize: '11px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    buttons: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontSize: '10px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      minWidth: 'max-content',
    },
    labelButton: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontSize: '14px',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    ///////////////////////////////////////////////////////
    h5: {
      fontSize: '1.5rem',
    },
    h6: {
      fontSize: '1rem',
    },
  },
});
