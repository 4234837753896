import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notificationWrapper: {
      width: '100%',
      borderTop: '1px solid #EBEBEB',
      '&:first-child': {
        borderTop: 'none',
      },
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        cursor: 'pointer',
      },
    },
    unreadNotificaton: {
      backgroundColor: 'rgb(160, 200, 215, 0.15)',
    },
    notificationLabel: {
      color: 'white',
      borderRadius: '60px',
      position: 'relative',
      top: '12px',
      left: '30px',
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    criticalNotificationLabel: {
      backgroundColor: theme.palette.primary.main,
    },
    importantNotificationLabel: {
      backgroundColor: '#D08B01',
    },
    informativeNotificationLabel: {
      backgroundColor: theme.palette.secondary.main,
    },
    notificationDate: {
      position: 'relative',
      top: '10px',
      left: '10px',
      color: theme.palette.grey.A200,
      fontSize: '10px',
    },
    closeNotificationIconButton: {
      position: 'relative',
      left: '10px',
      marginTop: '13px',
      padding: '5px',
      [theme.breakpoints.down('xl')]: {
        left: '6px',
      },
    },
    closeNotification: {
      fontSize: '20px',
      color: theme.palette.darkGrey.main,
    },
    notificationTitle: {
      position: 'relative',
      left: '30px',
      fontWeight: 700,
      width: '85%',
      wordBreak: 'break-all',
    },
    notificationDescription: {
      position: 'relative',
      left: '30px',
      fontWeight: 400,
      wordBreak: 'break-word',
      width: '85%',
      marginTop: '3px',
      marginBottom: '10px',
    },
  })
);
export default useStyles;
