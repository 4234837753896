import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadUserPartners,
  setUserPartners,
} from '../../../store/actions/partnerActions';
import { tokenAcquiredSelector, userSelector } from '../../../store/selectors';
import UserMenu from './user-menu/UserMenu';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { handleLogout } from '../../../services/auth';
import Login from './login/Login';

const SignInSignUp: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const tokenAcquired = useSelector(tokenAcquiredSelector);

  useEffect(() => {
    if (user) {
      dispatch(loadUserPartners());
    } else {
      dispatch(setUserPartners([]));
    }
  }, [dispatch, user]);

  return (
    <>
      <AuthenticatedTemplate>
        {tokenAcquired ? (
          <UserMenu logout={handleLogout}></UserMenu>
        ) : (
          <Login />
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </>
  );
};

export default SignInSignUp;
