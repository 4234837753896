import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { clsx } from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  MAX_CONTACT_FORM_MESSAGE_LENGTH,
  MIN_CONTACT_FORM_MESSAGE_LENGTH,
} from '../../constants/constants';
import useWindowDimensions from '../../hooks/window-dimensions';
import { PostMailContactDtoIn } from '../../services/mail-service/dtos/post-mail-contact/post-mail-contact-dto-in';
import { mailService } from '../../services/mail-service/mail-service';
import { setBasicAlert } from '../../store/actions/notificationsActions';
import { userSelector } from '../../store/selectors';
import {
  CheckboxStyleProps,
  useSharedCheckboxStyles,
} from '../../styles/checkbox.styles';
import { useSharedStyles } from '../../styles/Shared.styles';
import { emailRegex, phoneRegex } from '../../util/basic-validations';
import FriendlyCaptcha from '../../util/friendlyCaptcha';
import MPButton2 from '../button/MPButton2';
import MPTextField from '../input/MPTextField';
import LoadingIndicator from '../loading/Loading';
import useStyles from './ContactForm.styles';
import { closeContact } from './ContactFormOverlay';

interface ContactFormProps {
  largeScreenColumns: number;
  titleKey?: string;
  centerTitle?: boolean;
  successMessageKey: string;
  customEmailReceiver?: string; // this indicates that the email does not go to marketplace's contact team
  overlay?: boolean;
  disabled?: boolean;
}

const ContactForm: React.FC<ContactFormProps> = ({
  largeScreenColumns,
  titleKey,
  centerTitle,
  successMessageKey,
  customEmailReceiver,
  overlay,
  disabled,
}) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const checkboxStyleProps: CheckboxStyleProps = { isGreen: true };
  const sharedCheckboxClasses = useSharedCheckboxStyles(checkboxStyleProps);
  const user = useSelector(userSelector);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [messageLength, setMessageLength] = useState(0);
  const [captchaSolution, setCaptchaSolution] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [lastEmail, setLastEmail] = useState(user?.email || '');
  const [lastPhoneNumber, setLastPhoneNumber] = useState('');
  const [contactChannels, setContactChannels] = useState<ContactChannels>({
    emailChannel: true,
    phoneChannel: true,
  });
  const [overlaySmall, setOverlaySmall] = useState(false);
  const { height } = useWindowDimensions();

  type ContactFields = {
    fullName: string;
    email: string;
    phoneNumber: string;
    topic: string;
    message: string;
    privacyPolicy: boolean;
    allowContact: boolean;
  };

  const CONTACT_FIELDS_DEFAULTS: ContactFields = {
    fullName: user?.fullName || '',
    email: user?.email || '',
    phoneNumber: '',
    topic: '',
    message: '',
    privacyPolicy: false,
    allowContact: false,
  };

  interface ContactChannels {
    emailChannel: boolean;
    phoneChannel: boolean;
  }

  interface ContactChannelButton {
    labelKey: string;
    onSelected: () => void;
    isActive: () => boolean;
  }

  const { register, handleSubmit, errors, getValues, setValue } =
    useForm<ContactFields>({
      mode: 'all',
      defaultValues: {
        ...CONTACT_FIELDS_DEFAULTS,
      },
    });

  const contactChannelButtons: ContactChannelButton[] = [
    {
      labelKey: 'byEmailOrPhone',
      onSelected: () => {
        setContactChannels({ emailChannel: true, phoneChannel: true });
      },
      isActive: () =>
        contactChannels.emailChannel && contactChannels.phoneChannel,
    },
    {
      labelKey: 'onlyByEmail',
      onSelected: () => {
        setContactChannels({ emailChannel: true, phoneChannel: false });
      },
      isActive: () =>
        contactChannels.emailChannel && !contactChannels.phoneChannel,
    },
    {
      labelKey: 'onlyByPhone',
      onSelected: () => {
        setContactChannels({ emailChannel: false, phoneChannel: true });
      },
      isActive: () =>
        !contactChannels.emailChannel && contactChannels.phoneChannel,
    },
  ];

  const onSubmit = handleSubmit((data) => {
    setLoading(true);

    const contactFormData: PostMailContactDtoIn = {
      userName: data.fullName,
      userEmail: contactChannels.emailChannel ? data.email : undefined,
      userPhoneNumber: contactChannels.phoneChannel
        ? data.phoneNumber
        : undefined,
      topic: data.topic || ' ',
      message: data.message,
      captchaKey: captchaSolution,
      customEmailReceiver,
    };

    mailService
      .sendContactEmail(contactFormData)
      .then(() => {
        setFormSubmitted(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        const error = err as any;
        const emailSendingError: string = error?.response?.data?.message;
        dispatch(
          setBasicAlert({
            messageKey: emailSendingError,
            severity: 'error',
          })
        );
      });
  });

  const verifyButtonAvailability = useCallback(() => {
    const {
      fullName,
      email,
      phoneNumber,
      message,
      allowContact,
      privacyPolicy,
    } = getValues();

    const anyEmptyFields =
      !fullName ||
      (contactChannels.emailChannel && !email) ||
      (contactChannels.phoneChannel && !phoneNumber) ||
      !message ||
      !allowContact ||
      (!customEmailReceiver && !privacyPolicy);

    setButtonDisabled(
      errors.allowContact !== undefined ||
        errors.email !== undefined ||
        errors.phoneNumber !== undefined ||
        errors.fullName !== undefined ||
        errors.message !== undefined ||
        (!customEmailReceiver && errors.privacyPolicy !== undefined) ||
        anyEmptyFields ||
        !captchaSolution ||
        isLoading
    );
  }, [
    captchaSolution,
    errors.allowContact,
    errors.email,
    errors.phoneNumber,
    errors.fullName,
    errors.message,
    errors.privacyPolicy,
    isLoading,
    contactChannels,
    customEmailReceiver,
    getValues,
  ]);

  useEffect(() => {
    setOverlaySmall((overlay && height <= 786) || false);
  }, [height, overlay]);

  useEffect(() => {
    lastEmail !== '' &&
      setValue('email', lastEmail, {
        shouldValidate: true,
      });
    lastPhoneNumber !== '' &&
      setValue('phoneNumber', lastPhoneNumber, {
        shouldValidate: true,
      });

    // adding lastEmail or lastPhoneNumber will create infinite loop
    /* eslint-disable  react-hooks/exhaustive-deps*/
  }, [contactChannels]);

  useEffect(() => {
    verifyButtonAvailability();
  }, [captchaSolution, verifyButtonAvailability]);

  if (formSubmitted) {
    return (
      <Grid
        container
        item
        xs={12}
        md={8}
        className={classes.contactGridContainer}
      >
        <Box>
          <Typography variant="textBold">
            {t('contactSuccessGreeting')}
          </Typography>
          <Typography variant="text" className={classes.successMessage}>
            {t(successMessageKey)}
          </Typography>
        </Box>
      </Grid>
    );
  }

  return (
    <Grid
      container
      item
      md={largeScreenColumns as any}
      xs={12}
      className={classes.contactGridContainer}
    >
      {titleKey && (
        <Box className={centerTitle ? classes.center : ''}>
          <Typography variant="textBold">{t(titleKey)}</Typography>
        </Box>
      )}
      <form
        noValidate
        onBlur={verifyButtonAvailability}
        onSubmit={onSubmit}
        data-cy="contact-form"
      >
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <MPTextField
              styleVariant="regular-input"
              label={`${t('fullName')} *`}
              inputRef={register({
                required: true,
                minLength: 2,
                maxLength: 100,
              })}
              id="fullName"
              name="fullName"
              error={!!errors.fullName}
              helperText={!!errors.fullName ? t('fullNameError') : ' '}
              inputProps={{
                maxLength: 100,
              }}
              onChange={() => verifyButtonAvailability()}
              disabled={isLoading}
              customClasses={classes.textInput}
              size={overlaySmall ? 'small' : 'medium'}
            />
          </Grid>

          <Grid container spacing={1} className={classes.selectionPart}>
            {contactChannelButtons.map((contactChannelButton) => (
              <Grid key={contactChannelButton.labelKey} item xs={4}>
                <MPButton2
                  styleVariant="contactForm"
                  onClick={() => contactChannelButton.onSelected()}
                  customClasses={
                    contactChannelButton.isActive()
                      ? clsx(
                          classes.activeButton,
                          overlaySmall ? classes.buttonSmall : {}
                        )
                      : clsx(
                          classes.inactiveButton,
                          overlaySmall ? classes.buttonSmall : {}
                        )
                  }
                >
                  {t(contactChannelButton.labelKey)}
                </MPButton2>
              </Grid>
            ))}
          </Grid>

          {contactChannels.emailChannel && (
            <Grid item xs={12}>
              <MPTextField
                styleVariant="regular-input"
                label={`${t('email')} *`}
                id="email"
                name="email"
                inputRef={register({
                  required: true,
                  pattern: { value: emailRegex, message: '' },
                })}
                error={!!errors.email}
                helperText={!!errors.email ? t('emailError') : ' '}
                inputProps={{
                  maxLength: 200,
                }}
                onChange={(e) => {
                  setLastEmail(e.target.value);
                  verifyButtonAvailability();
                }}
                disabled={isLoading}
                customClasses={classes.textInput}
                size={overlaySmall ? 'small' : 'medium'}
              />
            </Grid>
          )}

          {contactChannels.phoneChannel && (
            <Grid item xs={12}>
              <MPTextField
                styleVariant="regular-input"
                label={`${t('phoneNumber')} *`}
                id="phoneNumber"
                name="phoneNumber"
                inputRef={register({
                  required: true,
                  pattern: { value: phoneRegex, message: '' },
                })}
                error={!!errors.phoneNumber}
                helperText={!!errors.phoneNumber ? t('phoneNumberError') : ' '}
                inputProps={{
                  maxLength: 15,
                }}
                onChange={(e) => {
                  setLastPhoneNumber(e.target.value);
                  verifyButtonAvailability();
                }}
                disabled={isLoading}
                customClasses={classes.textInput}
                size={overlaySmall ? 'small' : 'medium'}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <MPTextField
              styleVariant="regular-input"
              label={t('subject')}
              inputRef={register({
                required: false,
                maxLength: 200,
              })}
              id="topic"
              name="topic"
              helperText=" "
              inputProps={{
                maxLength: 200,
              }}
              disabled={isLoading}
              customClasses={classes.textInput}
              size={overlaySmall ? 'small' : 'medium'}
            />
          </Grid>
          <Grid item xs={12}>
            <MPTextField
              styleVariant="regular-input"
              label={`${t('yourMessageToUs')} *`}
              id="message"
              name="message"
              inputRef={register({
                required: true,
                minLength: MIN_CONTACT_FORM_MESSAGE_LENGTH,
              })}
              error={!!errors.message}
              helperText={
                <span className={classes.messageHelperText}>
                  <span>
                    {!!errors.message
                      ? messageLength === 0
                        ? t('messageError')
                        : t('minCharacters', {
                            minCharacters: MIN_CONTACT_FORM_MESSAGE_LENGTH,
                          })
                      : ''}
                  </span>
                  <span className={classes.messageLengthText}>
                    {messageLength
                      ? t('charactersRemaining', {
                          charactersNumber:
                            MAX_CONTACT_FORM_MESSAGE_LENGTH - messageLength,
                        })
                      : t('maxCharacters', {
                          maxCharacters: MAX_CONTACT_FORM_MESSAGE_LENGTH,
                        })}
                  </span>
                </span>
              }
              onChange={(e) => {
                verifyButtonAvailability();
                setMessageLength(e.target.value.length);
              }}
              multiline
              rows={overlay ? 3 : 7}
              inputProps={{
                maxLength: MAX_CONTACT_FORM_MESSAGE_LENGTH,
              }}
              disabled={isLoading}
              customClasses={classes.textInput}
            />
          </Grid>

          {!customEmailReceiver && (
            <Grid item xs={12}>
              <FormControlLabel
                label={
                  <div>
                    <span>{`${t('privacyPolicyReadFirst')} `}</span>
                    <Link
                      className={classes.privacyLink}
                      to={'/data-privacy'}
                      target="_blank"
                    >
                      {t('privacyPolicyReadLink')}
                    </Link>
                    <span>{` ${t('privacyPolicyReadSecond')}`}</span>
                  </div>
                }
                labelPlacement="end"
                id="privacyPolicy"
                name="privacyPolicy"
                control={
                  <Checkbox
                    className={clsx(
                      sharedCheckboxClasses.checkboxInput,
                      classes.checkBox
                    )}
                  />
                }
                className={sharedClasses.formLabel}
                inputRef={register({ required: true })}
                disabled={isLoading}
                data-cy="contact-form-privacy-policy"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <FormControlLabel
              label={t('allowContact')}
              labelPlacement="end"
              id="allowContact"
              name="allowContact"
              control={
                <Checkbox
                  className={clsx(
                    sharedCheckboxClasses.checkboxInput,
                    classes.checkBox
                  )}
                />
              }
              className={sharedClasses.formLabel}
              inputRef={register({ required: true })}
              disabled={isLoading}
              data-cy="contact-form-allow-contact"
            />
          </Grid>
          <Grid container>
            <Grid container item xs={12} className={classes.secondCheckbox}>
              <FriendlyCaptcha
                onFinish={setCaptchaSolution}
                onFail={() => {
                  return;
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className={overlay ? classes.buttonGridOverlay : classes.buttonGrid}
          >
            {overlay ? (
              <MPButton2
                onClick={() => closeContact()}
                styleVariant="tertiary"
                customClasses={classes.buttonSubmit}
              >
                {t('cancelAction')}
              </MPButton2>
            ) : (
              <></>
            )}
            <MPButton2
              styleVariant="primary"
              type="submit"
              disabled={isButtonDisabled || isLoading || disabled}
              customClasses={classes.buttonSubmit}
            >
              {isLoading ? (
                <Box display="flex" height="100%" width="100%">
                  <LoadingIndicator />
                </Box>
              ) : customEmailReceiver || overlay ? (
                t('sendRequest').toUpperCase()
              ) : (
                t('submit').toUpperCase()
              )}
            </MPButton2>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default ContactForm;
