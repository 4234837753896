import { BusinessProductSubType } from '../../../../models/business-product-sub-type';
import ContractPeriodTerms from '../../../../models/contract-period-terms.model';
import {
  ApiType,
  BillingSystem,
  Industry,
  ProductType,
} from '../../../../models/enums';
import Plan from '../../../../models/plan';
import { Product } from '../../../../models/product';
import { WsdlSelector } from '../../../../models/wsdl-selector';

export class GetProductDtoOut {
  id: string;

  displayName: string;

  productType: ProductType;

  shortDescription: string;

  providerId: string;

  providerName: string;

  responsible: string;

  responsibleEmail: string;

  companyDisplayName?: string;

  headlineImageUrl?: string;

  industries: Industry[];

  businessProductSubType?: BusinessProductSubType[];

  baseProductName?: string;

  baseProductLink?: string;

  internal?: boolean;

  statistics?: {
    /**
     * @member {number} [subscriptionCount]
     * @description count of currently active subscriptions
     */
    subscriptionCount?: number;
  };

  customPlanRequested?: boolean;

  plans?: {
    id: string;
    name: string;
    contractPeriodTerms: ContractPeriodTerms;
  }[];

  billingSystem: BillingSystem;

  configuration: {
    apiType: ApiType;
    wsdlSelector?: WsdlSelector;
  };
  publishedAt?: number;

  static fromDto(getProductDtoOut: GetProductDtoOut): Product {
    return {
      id: getProductDtoOut.id,
      productType: getProductDtoOut.productType,
      displayName: getProductDtoOut.displayName,
      shortDescription: getProductDtoOut.shortDescription,
      providerId: getProductDtoOut.providerId,
      providerName: getProductDtoOut.providerName,
      responsible: getProductDtoOut.responsible,
      responsibleEmail: getProductDtoOut.responsibleEmail,
      companyDisplayName: getProductDtoOut.companyDisplayName,
      businessProductSubType: getProductDtoOut.businessProductSubType,
      baseProductLink: getProductDtoOut.baseProductLink,
      baseProductName: getProductDtoOut.baseProductName,
      billingSystem: getProductDtoOut.billingSystem,
      files: {
        contractualDocuments: {
          termsAndConditionsUrl: undefined,
          dataProcessingSpecificationUrl: undefined,
          serviceLevelAgreementUrl: undefined,
        },
        headlineImageUrl: getProductDtoOut.headlineImageUrl,
      },
      industries: getProductDtoOut.industries,
      statistics: {
        subscriptionCount: getProductDtoOut.statistics?.subscriptionCount,
      },
      customPlanRequested: getProductDtoOut.customPlanRequested,
      plans: getProductDtoOut.plans
        ? getProductDtoOut.plans.map((plan: Plan) => ({
            id: plan.id,
            name: plan.name,
            contractPeriodTerms: plan.contractPeriodTerms,
          }))
        : undefined,
      configuration: getProductDtoOut.configuration,
      internal: getProductDtoOut.internal,
      publishedAt: getProductDtoOut.publishedAt,
    };
  }
}
