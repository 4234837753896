import axios from 'axios';
import { appConfiguration } from '../../configuration';
import {
  authScopes,
  getCurrentAccount,
  acquireTokenSilentOrRefreshToken,
} from '../auth';
import { PostEmailReviewerDtoIn } from './dtos/post-email-reviewer/post-email-reviewer-dto-in';
import { PostMailContactDtoIn } from './dtos/post-mail-contact/post-mail-contact-dto-in';
class MailService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async sendContactEmail(message: PostMailContactDtoIn): Promise<void> {
    return axios.post(`${this.baseUrl}/mail/contact`, message);
  }

  async sendEmailToReviewer(
    emailReviewerDtoIn: PostEmailReviewerDtoIn
  ): Promise<any> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return axios.post(
      `${this.baseUrl}/mail/contact-reviewer`,
      emailReviewerDtoIn,
      {
        headers: {
          authorization: `Bearer ${authResult.idToken}`,
        },
      }
    );
  }
}

export const mailService = new MailService(
  appConfiguration()?.coreApiUrl ?? ''
);
