import { BillingSystem, PaymentMethodType } from '../models/enums';

export const SHORT_MONTH_KEYS = [
  'shortJanuary',
  'shortFebruary',
  'shortMarch',
  'shortApril',
  'shortMay',
  'shortJune',
  'shortJuly',
  'shortAugust',
  'shortSeptember',
  'shortOctober',
  'shortNovember',
  'shortDecember',
];
export const IMAGE_MIME_TYPES = ['image/jpeg', 'image/png', 'image/tiff'];
export const DOCUMENT_MIME_TYPES = ['application/pdf'];
export const EURO_ISO_4217 = 'eur';
export const NUMBER_OF_NOTIFICATIONS_IN_ONE_BATCH = 10;
export const SERVICE_SPECIFICATION_VALIDATION_TIMEOUT = 1000 * 60 * 2;
export const BILLING_SYSTEM_PAYMENT_METHODS = {
  [BillingSystem.NONE]: [PaymentMethodType.WIRE_TRANSFER],
  [BillingSystem.MSG]: [PaymentMethodType.WIRE_TRANSFER],
  [BillingSystem.STRIPE]: [
    PaymentMethodType.CARD,
    PaymentMethodType.SEPA,
    PaymentMethodType.WIRE_TRANSFER,
  ],
};
export const MAX_BLOCKS_PER_PRODUCT = 3;
export const MAX_FILE_SIZE = 20 * 1024 * 1024;
export const MAX_FILE_SIZE_MB = 20;
export const DEFAULT_PRICE_PLAN_NAME = 'Standard Pricing';
export const SNACKBAR_EXPIRATION_TIME_MS = 3000;
export const MIN_CONTACT_FORM_MESSAGE_LENGTH = 10;
export const MAX_CONTACT_FORM_MESSAGE_LENGTH = 10000;
export const MAX_FILTER_OPTIONS_SELECTIONS_FOR_LABEL = 9;
export const MAX_PRODUCT_CARDS_PER_ROW = 5;
export const FILTER_QUERY_PARAMETER_KEY = 'filter';
export const DAYS_UNTIL_PRODUCT_IS_CONSIDERED_NEW = 14;
export const MAX_ADVANTAGES = 4;

// Files universal i18n labels
export const TERMS_AND_CONDITIONS_I18N_LABEL = 'terms&Conditions';
export const SERVICE_LEVEL_AGREEMENT_I18N_LABEL = 'serviceLevelAgreement';
export const DATA_PROCESSING_SPECIFICATION_I18N_LABEL =
  'dataProcessingSpecification';
export const ADDITIONAL_DOCUMENTS_I18N_LABEL = 'additionalDocuments';

export const CARD_WIDTH = '262px';
export const CARD_HEIGHT = '357px';
export const CARD_WIDTH_WITH_LATERAL_MARGINS = '272px';
export const COOKIE_EXPIRATION_IN_MSEC = 34560000000; //Calculation for : 400(days) * 24 * 60 * 60 * 1000
export const COOKIE_USER_PREFERENCE = 'cookieUserPreference';
export const COOKIE_ACCEPT_ALL = 'allAccepted';
export const COOKIE_ONLY_NECESSARY = 'onlyNecessary';
export const UNIQUE_CROPPED_IMAGE_FILE_NAME =
  'MP_CROPPED_IMAGE_UNIQUE_FILE_NAME';
