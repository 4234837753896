import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    yellow: Palette['primary'];
    accentYellow: Palette['primary'];
    lightRed: Palette['primary'];
    accentRed: Palette['primary'];
    accentGreen: Palette['primary'];
    infoGreen: Palette['primary'];
    white: Palette['primary'];
    disabled: Palette['primary'];
    middleGrey: Palette['primary'];
    border: Palette['primary'];
    darkGrey: Palette['primary'];
    typography: Palette['primary'];
  }
  interface PaletteOptions {
    yellow: PaletteOptions['primary'];
    accentYellow: PaletteOptions['primary'];
    lightRed: PaletteOptions['primary'];
    accentRed: PaletteOptions['primary'];
    accentGreen: PaletteOptions['primary'];
    infoGreen: PaletteOptions['primary'];
    white: PaletteOptions['primary'];
    disabled: PaletteOptions['primary'];
    middleGrey: PaletteOptions['primary'];
    border: PaletteOptions['primary'];
    darkGrey: PaletteOptions['primary'];
    typography: PaletteOptions['primary'];
  }
}

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    header0: React.CSSProperties;
    header1: React.CSSProperties;
    header1smaller: React.CSSProperties;
    header2: React.CSSProperties;
    profile: React.CSSProperties;
    header3: React.CSSProperties;
    text: React.CSSProperties;
    menu: React.CSSProperties;
    textBold: React.CSSProperties;
    noteItalic: React.CSSProperties;
    buttons: React.CSSProperties;
    search: React.CSSProperties;
    labelButton: React.CSSProperties;
  }
  interface TypographyOptions {
    header0?: React.CSSProperties;
    header1?: React.CSSProperties;
    header1smaller?: React.CSSProperties;
    header2?: React.CSSProperties;
    profile?: React.CSSProperties;
    header3?: React.CSSProperties;
    text?: React.CSSProperties;
    textBold?: React.CSSProperties;
    noteItalic?: React.CSSProperties;
    menu?: React.CSSProperties;
    buttons?: React.CSSProperties;
    search?: React.CSSProperties;
    labelButton?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    header0: true;
    header1: true;
    header1smaller: true;
    header2: true;
    profile: true;
    header3: true;
    text: true;
    textBold: true;
    noteItalic: true;
    menu: true;
    buttons: true;
    search: true;
    labelButton: true;
  }
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1800,
    },
  },

  /**
   * @description This represents the custom colors from the theme
   * @example color: theme.palette.yellow.main
   */
  palette: {
    ///////////////////////////////////////////////////////
    ///////////////// Custom Colors ///////////////////////
    ///////////////////////////////////////////////////////
    primary: {
      light: '#404040',
      main: '#404040', //'#005c99', //'#890b0b', //Primary red
      dark: '#161d7a', //'#680404', // change this //#680404  //#040b68
    },
    secondary: {
      light: '#b3d3df',
      main: '#005c99', //Accent blue
      dark: '#3f7e96',
    },
    yellow: {
      main: '#358fe8', //'#F7CD7F',
    },
    red: {
      main: '#e74337',
    },
    accentYellow: {
      main: '#F3AC57',
    },
    lightRed: {
      main: '#358fe8', //'#e83535',  //#358fe8
      light: '#ffffff',
    },
    accentRed: {
      main: '#161d7a', //'#680404',  //#040b68
    },
    accentGreen: {
      main: '#82A7A6',
    },
    infoGreen: {
      main: '#74CD63',
    },
    white: {
      main: '#FFFFFF',
    },
    disabled: {
      main: '#F1F1F1',
    },
    middleGrey: {
      main: '#E6E6E6',
    },
    border: {
      main: '#BABABA',
    },
    darkGrey: {
      main: '#6F6F6F',
    },
    typography: {
      main: '#1C1F37',
    },
    lightBrown: {
      main: '#C6B89F',
    },
    ///////////////////////////////////////////////////////
    error: {
      main: '#c4000e',
    },
    success: {
      main: '#5cb85c',
    },
    grey: {
      '50': '#fafafa',
      '100': '#f2f2f2',
      '200': '#eeeeee',
      '300': '#e0e0e0',
      '400': '#bdbdbd',
      '500': '#9f9f9f',
      '600': '#757575',
      '700': '#616161',
      '800': '#424242',
      '900': '#212121',
      A100: 'rgba(234, 234, 234, 0.25)',
      A200: '#CBCBCB',
      A400: '#6F6F6F',
    },
    text: {
      primary: '#1C1F37',
    },
    background: {
      default: '#FCFCFC', //needs to be the new color for the background but needs careful verification at redesign
      paper: '#ffffff', //'#FCFCFC'
    },
  },
  /**
   * @description This represents the custom typography from the theme
   * @example <Typography variant="header1"> Hello </Typography>
   */
  typography: {
    fontFamily: ['"Open Sans"', 'Roboto', 'Arial', 'sans-serif'].join(','),
    fontSize: 16,
    ///////////////////////////////////////////////////////
    ///////////// Custom Variants /////////////////////////
    ///////////////////////////////////////////////////////
    header0: {
      fontFamily: ['Calibri', 'sans-serif'].join(','),
      fontSize: '40px',
      fontWeight: 'bold',
    },
    header1: {
      fontFamily: ['Calibri', 'sans-serif'].join(','),
      fontSize: '32px',
      fontWeight: 'bold',
    },
    header1smaller: {
      fontFamily: ['Calibri', 'sans-serif'].join(','),
      fontSize: '24px',
      fontWeight: 'bold',
    },
    header2: {
      fontFamily: ['Calibri', 'sans-serif'].join(','),
      fontSize: '18px',
      fontWeight: 'bold',
      lineHeight: '22px',
    },
    header3: {
      fontFamily: ['Calibri', 'sans-serif'].join(','),
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '20px',
    },
    textBold: {
      fontFamily: ['Calibri', 'sans-serif'].join(','),
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '20px',
    },
    profile: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '20px',
    },
    text: {
      fontFamily: ['Calibri', 'sans-serif'].join(','),
      fontSize: '14px',
      fontWeight: '400',
    },
    noteItalic: {
      fontFamily: ['Calibri', 'sans-serif'].join(','),
      fontSize: '11px',
      fontWeight: '400',
      fontStyle: 'italic',
    },
    menu: {
      fontFamily: ['Calibri', 'sans-serif'].join(','),
      fontSize: '11px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    buttons: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontSize: '10px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      minWidth: 'max-content',
    },
    labelButton: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontSize: '14px',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    ///////////////////////////////////////////////////////
    h5: {
      fontSize: '1.5rem',
    },
    h6: {
      fontSize: '1rem',
    },
  },
});
