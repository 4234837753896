import {
  AccountInfo,
  AuthenticationResult,
  AuthError,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { Box, Hidden } from '@mui/material';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import MPAlert from './components/alert/MPAlert';
import ConfirmModal from './components/confirm-modal/ConfirmModal';
import ContactFormOverlay from './components/contact-form/ContactFormOverlay';
import CookieModal from './components/cookie-modal/CookieBanner';
import Footer from './components/footer/Footer';
import LoadingIndicator from './components/loading/Loading';
import { NavigationBar } from './components/navigation-bar/NavigationBar';
import ScrollToTop from './components/scroll-to-top/ScrollToTop';
import Sidebar from './components/sidebar/Sidebar';
import { htmlHead } from './configuration/html-heads';
import { useNotifications } from './hooks/notifications';
import routes from './routes';
import {
  forgotPasswordRequest,
  getCurrentAccount,
  isSessionExpired,
  msalPca,
} from './services/auth';
import {
  autoLogin,
  loginSuccess,
  logoutSuccess,
  tokenAcquired,
} from './store/actions/authActions';
import { setBasicAlert } from './store/actions/notificationsActions';
import {
  loadBatchOfUserNotifications,
  loadUnreadNotificationsCount,
  updateCurrentPageNumber,
} from './store/actions/userNotificationsActions';
import {
  notificationsBasicAlertSelector,
  partnerCurrentPartner,
} from './store/selectors';
import { isUserPreferenceCookieSet } from './util/cookies/cookies';

const Dashboard = lazy(() => import('./dynamic-pages/Dashboard'));
const ProductPage = lazy(() => import('./dynamic-pages/ProductPage'));
const Checkout = lazy(() => import('./dynamic-pages/Checkout'));
const NotFound = lazy(() => import('./pages/mp404'));
const Invitation = lazy(() => import('./dynamic-pages/Invitation'));
const ProductCatalog = lazy(() => import('./dynamic-pages/ProductCatalog'));
const Homepage = lazy(() => import('./pages/homepage'));
const Imprint = lazy(() => import('./pages/imprint'));
const DataPrivacy = lazy(() => import('./pages/data-privacy'));
//const OurVision = lazy(() => import('./pages/our-vision')); // will be used again later
const TermsOfUse = lazy(() => import('./pages/terms-of-use'));

interface AppContentProps {
  handleAllCookies: () => void;
  handleSelectedCookies: () => void;
}

const AppContent: React.FC<AppContentProps> = (props) => {
  const currentPartner = useSelector(partnerCurrentPartner);
  const alert = useSelector(notificationsBasicAlertSelector);
  const dispatch = useDispatch();
  const location = useLocation();
  useNotifications();
  const footerDisplayed = !location.pathname.includes(routes.dashboard.base);
  const [cookieFlag, setCookieFlag] = useState<boolean>();

  useEffect(() => {
    if (currentPartner && currentPartner.id) {
      dispatch(updateCurrentPageNumber(0));
      dispatch(loadBatchOfUserNotifications(currentPartner.id, 0));
      dispatch(loadUnreadNotificationsCount(currentPartner.id));
    }
  }, [dispatch, currentPartner]);

  useEffect(() => {
    setCookieFlag(isUserPreferenceCookieSet());
  }, []);

  useEffect(() => {
    const eventListener = msalPca.addEventCallback((message: EventMessage) => {
      switch (message.eventType) {
        case EventType.LOGIN_SUCCESS: {
          dispatch(
            loginSuccess(
              (message.payload as AuthenticationResult)?.account as AccountInfo
            )
          );
          break;
        }
        case EventType.LOGIN_FAILURE: {
          // If login flow fails with forgot password error
          if (
            (message?.error as AuthError)?.errorMessage?.indexOf(
              'AADB2C90118'
            ) > -1
          ) {
            // start the reset password flow
            msalPca
              .loginRedirect({
                ...forgotPasswordRequest,
              })
              .catch((err) => {
                console.log('error at loginRedirect: ', err);
                dispatch(
                  setBasicAlert({
                    messageKey: 'generalErrorMessage',
                    severity: 'error',
                  })
                );
              });
          }
          break;
        }
        case EventType.LOGOUT_SUCCESS: {
          dispatch(logoutSuccess());
          break;
        }
        case EventType.ACQUIRE_TOKEN_SUCCESS: {
          //when token is fetched
          dispatch(tokenAcquired());
        }
      }
    });

    // autologin on refresh
    // getCurrentAccount checks the cached id token for the account
    const currentAccount = getCurrentAccount();
    if (currentAccount) {
      isSessionExpired()
        .then((expired) => {
          if (!expired) {
            dispatch(autoLogin(currentAccount));
            if (currentPartner && currentPartner.id) {
              dispatch(updateCurrentPageNumber(0));
              dispatch(loadBatchOfUserNotifications(currentPartner.id, 0));
              dispatch(loadUnreadNotificationsCount(currentPartner.id));
            }
          }
        })
        .catch((error) => {
          console.log('token fetching failed, logging out:', error);
          console.log(location);
          //only logout locally, cookies on b2c server aren't cleared
          if (
            !location.pathname.includes(routes.dashboard.base) &&
            location.pathname !== routes.invitation
          ) {
            msalPca
              .logoutRedirect({
                onRedirectNavigate: () => {
                  return false;
                },
              })
              .catch((e) => {
                console.log('error at logoutRedirect: ', e);
                dispatch(
                  setBasicAlert({
                    messageKey: 'generalErrorMessage',
                    severity: 'error',
                  })
                );
              });
          } else {
            msalPca.logoutRedirect().catch((e) => {
              console.log('error at logoutRedirect: ', e);
              dispatch(
                setBasicAlert({
                  messageKey: 'generalErrorMessage',
                  severity: 'error',
                })
              );
            });
          }
        });
    }

    return () => {
      if (eventListener) {
        msalPca.removeEventCallback(eventListener);
      }
    };
    // Do not add currentPartner as dependency since it will lead to infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const routingComponent = (
    <Switch>
      <Route exact path={routes.home} component={Homepage} />
      <Route path={routes.checkout.base} component={Checkout} />
      <Route path={routes.dashboard.base} component={Dashboard} />
      <Route path={routes.service} component={ProductPage} />
      <Route path={routes.invitation} component={Invitation} />
      <Route path={routes.productCatalog} component={ProductCatalog} />
      <Route path={routes.imprint} component={Imprint} />
      <Route path={routes.dataPrivacy} component={DataPrivacy} />
      {/* <Route path={routes.ourVision} component={OurVision} /> // will be used again later*/}
      <Route path={routes.termsOfUse} component={TermsOfUse} />
      <Route path={routes.notFound} component={NotFound} />
    </Switch>
  );

  const noSidebarDisplayed =
    location.pathname.includes('/checkout') ||
    location.pathname.includes(routes.dashboard.base);

  return (
    <Box id={''}>
      <Helmet>{htmlHead}</Helmet>
      <ConfirmModal />
      <MPAlert alert={alert} />
      <ContactFormOverlay />
      <ScrollToTop />
      {!noSidebarDisplayed && (
        <Hidden mdDown>
          <Box id="sidebar">
            <Sidebar />
          </Box>
        </Hidden>
      )}
      <Box
        display="flex"
        flexDirection="column"
        position="relative"
        id={noSidebarDisplayed ? undefined : 'container'}
        minHeight={'100vh'}
      >
        <Box id="navbar">
          <NavigationBar />
        </Box>
        <Suspense
          fallback={
            <Box display="flex" height="100vh">
              <LoadingIndicator />
            </Box>
          }
        >
          <Box id={noSidebarDisplayed ? undefined : 'content'}>
            {routingComponent}
          </Box>
        </Suspense>
        {footerDisplayed && (
          <Box id="footer">
            <Footer />
          </Box>
        )}
        {!cookieFlag && (
          <CookieModal
            onSelected={() => {
              setCookieFlag(true);
              props.handleSelectedCookies();
            }}
            onAll={() => {
              setCookieFlag(true);
              props.handleAllCookies();
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default AppContent;
