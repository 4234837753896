import { htmlHead as msgHtmlHead } from './msg/html-head';
import { htmlHead as inPunctoHtmlHead } from './inPuncto/html-head';
import { appConfiguration } from '..';

const htmlHeads: { [env: string]: any[] } = {
  msg: msgHtmlHead,
  inPuncto: inPunctoHtmlHead,
};

export const htmlHead = htmlHeads[appConfiguration()?.htmlHeader || 'msg'];
