import React, { useEffect, useRef } from 'react';
import { appConfiguration } from '../configuration';
import { WidgetInstance } from 'friendly-challenge';

type Props = {
  onFinish: (solution: string) => void;
  onFail: (error: any) => void;
};

const FriendlyCaptcha: React.FC<Props> = ({ onFinish, onFail }) => {
  const container = useRef<any>();
  const widget = useRef<WidgetInstance>();

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        startMode: 'none',
        doneCallback: onFinish,
        errorCallback: onFail,
      });
    }
  }, [container, onFail, onFinish]);

  return (
    <div
      ref={container}
      className="frc-captcha"
      data-sitekey={`${appConfiguration()?.friendlyCaptchaSiteKey}`}
      style={{ width: '100%' }}
    />
  );
};

export default FriendlyCaptcha;
