import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'sticky',
      height: 'inherit',
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: theme.palette.white.main,
      color: theme.palette.typography.main,
      whiteSpace: 'nowrap',
      transition: 'all 0.5s',
      boxShadow: '0px 3px 5px 0px rgb(0 0 0 / 10%);',
    },
    container: {
      height: '100%',
      maxWidth: '2090px !important',
    },
    leftAndRight: {
      height: '45px',
      paddingRight: '54px',
      alignItems: 'center',
      paddingBottom: '50px',
      paddingTop: '5px',
    },
    toolbar: {
      height: '100%',
    },
    leftPart: {
      paddingLeft: '54px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
    },
    rightPart: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      minHeight: '45px',
    },
    backButtonIcon: {
      width: '36px',
      height: '36px',
      borderRadius: '50%',
      border: '1px solid',
      borderColor: theme.palette.border.main,
      marginRight: '10px',
    },
    backButtonLink: {
      textDecoration: 'none',
      color: theme.palette.typography.main,
    },
    activeMenuButton: {
      borderBottom: `2px ${theme.palette.primary.main} solid`,
      transition: 'none',
      borderRadius: 0,
    },
    logoLabel: {
      marginLeft: '9px',
      color: theme.palette.grey[800],
      textTransform: 'none',
      fontSize: '25px',
    },
    logoButton: {
      color: 'transparent !important',
      paddingLeft: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    rightNavbarContent: {
      textAlign: 'right',
    },
    searchButton: {
      marginRight: '36px',
      display: 'none',
    },
    languageMenu: {
      marginRight: '22px',
    },
    notificationBellIcon: {
      color: theme.palette.darkGrey.main,
      zIndex: 1,
    },
    notificationBadge: {
      backgroundColor: theme.palette.secondary.dark,
      color: 'white',
      transform: `translate(${40}%,-${25}%)`,
      paddingTop: '1px',
      height: '18px',
      width: '18px',
      minWidth: '18px',
      [theme.breakpoints.down('xl')]: {
        paddingTop: 0,
      },
    },
    notificationCenter: {
      borderTopLeftRadius: '24px',
      borderBottomLeftRadius: '24px',
      position: 'fixed',
      top: '76px',
      right: 0,
      width: '337px',
      height: '766px',
      backgroundColor: 'white',
      zIndex: 1250,
      boxShadow:
        '0px 0px 1px rgb(48 49 51 / 10%), 0px 4px 8px rgb(48 49 51 / 15%)',
      [theme.breakpoints.down('xl')]: {
        top: '58px',
        height: '591px',
      },
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 0',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#7F7F7F',
        borderRadius: '10px',
        '&:hover': {
          border: `1px solid ${theme.palette.secondary.main}`,
        },
      },
    },
    settingIcon: {
      display: 'none',
      position: 'absolute',
      right: '18px',
      top: '9px',
      [theme.breakpoints.down('xl')]: {
        right: '12px',
      },
    },
    notificationCenterTitle: {
      fontSize: '14px',
      fontWeight: 700,
      position: 'relative',
      top: '20px',
      left: '27px',
      height: '54px',
    },
    notificationWrapper: {
      width: '100%',
      borderTop: '2px solid #EBEBEB',
      '&:first-child': {
        borderTop: 'none',
      },
    },
    notificationBox: {
      width: '100%',
    },
    unreadNotificaton: {
      backgroundColor: 'rgb(160, 200, 215, 0.15)',
    },
    notificationLabel: {
      color: 'white',
      borderRadius: '60px',
      position: 'relative',
      top: '11px',
      left: '27px',
      paddingLeft: '14px',
      fontSize: '12px',
    },
    criticalNotificationLabel: {
      backgroundColor: theme.palette.primary.main,
      width: '78px',
    },
    importantNotificationLabel: {
      backgroundColor: '#D08B01',
      width: '93px',
    },
    informativeNotificationLabel: {
      backgroundColor: theme.palette.secondary.main,
      width: '104px',
    },
    notificationDate: {
      position: 'relative',
      top: '12px',
      left: '18px',
      color: theme.palette.grey.A200,
      fontSize: '12px',
    },
    closeNotificationIconButton: {
      position: 'relative',
      left: '9px',
      [theme.breakpoints.down('xl')]: {
        left: '5px',
      },
    },
    closeNotification: {
      fontSize: '18px',
      color: theme.palette.darkGrey.main,
    },
    notificationTitle: {
      position: 'relative',
      left: '27px',
      fontWeight: 700,
      width: '85%',
      wordBreak: 'break-all',
    },
    notificationDescription: {
      position: 'relative',
      left: '27px',
      fontWeight: 400,
      wordBreak: 'break-word',
      width: '85%',
      marginTop: '3px',
      marginBottom: '9px',
    },
    infiniteScroll: {
      height: '800px',
      overflow: 'hidden',
    },
    emptyNotificationCenter: {
      position: 'relative',
      left: '27px',
      marginTop: '9px',
    },
    notificationButton: {
      padding: '0px',
      marginRight: '5px',
      marginLeft: '14px',
      marginBottom: '5px',
      width: '30px',
    },
    hideNavigationLink: {
      display: 'none',
    },
  })
);
export default useStyles;
