import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginButton: {
      padding: '5px',
      textTransform: 'uppercase',
      color: theme.palette.grey[900],
      fontSize: '20px',
      lineHeight: '100% !important',
      [theme.breakpoints.down('xl')]: {
        fontSize: '16px !important',
      },
    },
  })
);
export default useStyles;
