import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      position: 'absolute',
      width: '100%',
      fontFamily: theme.typography.fontFamily,
      backgroundColor: theme.palette.grey[800],
      color: 'white',
      bottom: 0,
      paddingTop: theme.spacing(0.4),
      paddingBottom: theme.spacing(0.4),
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
      },
      //same values used in App.css for the #content margins
      paddingLeft: '77px',
      paddingRight: '77px',
    },
    companyNameItem: {
      lineHeight: '25px',
      fontSize: '0.8rem',
      textAlign: 'center',
      fontWeight: 300
    },
    linksContainer: {
      lineHeight: '25px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      width: 'fit-content',
      fontWeight: 300,
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(1.5),
        width: '100%',
      },
    },
    linkWrapper: {
      display: 'flex',
      maxWidth: 'fit-content',
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        justifyContent: 'center',
      },
    },
    link: {
      display: 'block',
      alignSelf: 'center',
      fontSize: '0.8rem',
      color: 'white',
      textDecoration: 'none',
      width: 'max-content',
      '&:hover': {
        textDecoration: 'underline !important',
      },
    },
    divider: {
      backgroundColor: 'white',
      height: '100%',
      fontSize: '0.8rem',
      margin: '9px',
      display: 'inline',
    },
  })
);

export default useStyles;
