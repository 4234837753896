import { TextField, TextFieldProps } from '@mui/material';
import { clsx } from 'clsx';
import React from 'react';
import { MPInputBaseProps } from './MPInputBase';
import useStyles from './MPInputBase.styles';

const MPTextField: React.FC<MPInputBaseProps & TextFieldProps> = ({
  isHidden,
  customClasses,
  styleVariant,
  onBlur,
  dataCy,
  ...textFieldProps
}) => {
  const classes = useStyles();
  return (
    <TextField
      {...textFieldProps}
      variant="outlined"
      className={clsx(classes.textFieldRoot, customClasses)}
      hidden={true}
      color="secondary"
      margin="normal"
      inputMode="none"
      fullWidth
      onBlur={onBlur}
      data-cy={dataCy}
    />
  );
};

export default MPTextField;
