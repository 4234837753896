import * as signalR from '@microsoft/signalr';
import { appConfiguration } from '../configuration';
import { SignalRChannel } from '../models/enums';
import { authScopes, getCurrentAccount, msalPca } from './auth';

type NotificationHandler = (data: any) => void;

class NotificationService {
  private _connection: signalR.HubConnection;
  private _connectionEstablished = false;
  public partnerId: string;
  public userId: string;

  constructor(partnerId: string, userId: string) {
    this.partnerId = partnerId;
    this.userId = userId;
    this._connection = new signalR.HubConnectionBuilder()
      .withUrl(`${appConfiguration()?.coreApiUrl}/notifications`, {
        accessTokenFactory: async () =>
          (
            await msalPca.acquireTokenSilent({
              scopes: authScopes,
              account: getCurrentAccount(),
            })
          ).idToken,
        headers: {
          'partner-id': this.partnerId,
          'user-id': this.userId,
        },
      })
      .withAutomaticReconnect()
      .build();

    this._connection
      .start()
      .then(() => (this._connectionEstablished = true))
      .catch((e) =>
        console.log(`Could not setup notification connection: ${e.message | e}`)
      );

    this._connection.onclose((e) => {
      console.log(`SignalR connection closed. ${e?.message}`);
    });
  }

  get connectionEstablished(): boolean {
    return this._connectionEstablished;
  }

  registerSubscriptionHandler(handler: NotificationHandler) {
    this._connection.on(SignalRChannel.SUBSCRIPTIONS, handler);
  }

  clearSubscriptionHandler() {
    this._connection.off(SignalRChannel.SUBSCRIPTIONS);
  }

  registerPartnerHandler(handler: NotificationHandler) {
    this._connection.on(SignalRChannel.PARTNERS, handler);
  }

  clearPartnerHandler() {
    this._connection.off(SignalRChannel.PARTNERS);
  }

  registerUserNotificationHandler(handler: NotificationHandler) {
    this._connection.on(SignalRChannel.NOTIFICATIONS, handler);
  }

  clearUserNotificationHandler() {
    this._connection.off(SignalRChannel.NOTIFICATIONS);
  }

  registerProductHandler(handler: NotificationHandler) {
    this._connection.on(SignalRChannel.PRODUCTS, handler);
  }

  clearProductHandler() {
    this._connection.off(SignalRChannel.PRODUCTS);
  }

  close() {
    this._connection
      .stop()
      .catch((e) =>
        console.log(
          `There was an error on closing the connection: ${e.message | e}`
        )
      );
    this._connectionEstablished = false;
  }
}

let notificationService: NotificationService;

export const getNotificationService = (partnerId: string, userId: string) => {
  notificationService =
    notificationService || new NotificationService(partnerId, userId);

  if (notificationService.partnerId !== partnerId) {
    notificationService.close();
    notificationService = new NotificationService(partnerId, userId);
  }
  return notificationService;
};
