import { groupBy, map, mapKeys } from 'lodash';
import { Reducer } from 'redux';
import {
  ProductCoreCost,
  SubscriptionCoreCost,
} from '../../models/subscriptionCosts';
import { findFirstUnitLabel } from '../../util/util';
import {
  SubscriptionsCostsActions,
  SubscriptionsCostsActionTypes,
} from '../actions/subscriptionsCostsActions';

export interface SubscriptionsCostsState {
  costsBySubscriptions: { [subscriptionId: string]: SubscriptionCoreCost };
  costsByProducts: { [productId: string]: ProductCoreCost };
  loading: boolean;
  error: any;
}

const initialState: SubscriptionsCostsState = {
  costsBySubscriptions: {},
  costsByProducts: {},
  loading: false,
  error: null,
};

const bySubscriptionToByProduct = ({
  subscription,
  ...byProduct
}: SubscriptionCoreCost): ProductCoreCost => {
  return {
    ...byProduct,
    productId: subscription.productId,
    productName: subscription.productName,
    unitLabel: findFirstUnitLabel(subscription.plan),
  };
};

const groupCostByProduct = (
  subscriptionCost: SubscriptionCoreCost[]
): { [productId: string]: ProductCoreCost } =>
  mapKeys(
    map(groupBy(subscriptionCost, 'subscription.productId'), (c) =>
      c.reduce((c1, c2) => ({
        ...c1,
        currentCost: c1.currentCost + c2.currentCost,
        currentUsage: c1.currentUsage + c2.currentUsage,
      }))
    ).map(bySubscriptionToByProduct),
    'productId'
  );

export const subscriptionsCostsReducer: Reducer<
  SubscriptionsCostsState,
  SubscriptionsCostsActions
> = (state = initialState, action): SubscriptionsCostsState => {
  switch (action.type) {
    case SubscriptionsCostsActionTypes.LOAD_COSTS_SUCCESS: {
      return {
        ...state,
        costsBySubscriptions: mapKeys(action.payload, 'subscription.id'),
        costsByProducts: groupCostByProduct(action.payload),
        loading: false,
        error: null,
      };
    }
    case SubscriptionsCostsActionTypes.LOAD_COSTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    case SubscriptionsCostsActionTypes.SET_COSTS_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default:
      return state;
  }
};
