/* eslint-disable react/jsx-key */
import React from 'react';
import { getLocalPicturePath } from '../../local-dev-utils';

export const htmlHead = [
  <meta name="viewport" content="width=device-width, initial-scale=1" />,
  <link rel="icon" href={getLocalPicturePath('/icons/favicon.png')} />,
  <meta name="theme-color" content="#000000" />,
  <link rel="apple-touch-icon" href="/img/logo192.png" />,
  <link rel="manifest" href="/manifest.json" />,
  <link rel="stylesheet" href="/fonts/roboto.css" />,
  <link rel="stylesheet" href="/fonts/open-sans.css" />,
  <title>Care for Innovation Marketplace</title>,
  <meta
    name="description"
    content="To digitally map complex processes or extend your customer journey, you don't have to develop the corresponding software - it's already there. Take advantage of our range of digital products and expand your business with future-oriented services as quickly and easily as never before: risk-free, with usage-based pricing and validated product quality."
  ></meta>,
];
