import {
  Box,
  Checkbox,
  DialogActions,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { clsx } from 'clsx';
import React, { ChangeEvent, useContext, useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routes from '../../routes';
import { CookieContext } from '../../util/global-context';
import MPButton2 from '../button/MPButton2';
import useStyles from './CookieBanner.styles';
import {
  CheckboxStyleProps,
  useSharedCheckboxStyles,
} from '../../styles/checkbox.styles';

interface CookieModalProps {
  onAll: () => void;
  onSelected: () => void;
}

const CookieBanner: React.FC<CookieModalProps> = (props) => {
  const { statisticCookie, setStatisticCookieContext } =
    useContext(CookieContext);
  const [sidebarDisplayed, setSidebarDisplayed] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const checkboxStyleProps: CheckboxStyleProps = { isGreen: true, isSmall: true};
  const sharedCheckboxClasses = useSharedCheckboxStyles(checkboxStyleProps);
  const location = useLocation();

  const handleSelectCookies = (event: ChangeEvent<HTMLInputElement>) => {
    //currently we just have cookies for statistics.
    //add logic here, if further cookies added
    if (event.target.checked) {
      setStatisticCookieContext(true);
    } else {
      setStatisticCookieContext(false);
    }
  };
  useEffect(() => {
    if (
      location.pathname.includes('/checkout') ||
      location.pathname.includes(routes.dashboard.base)
    ) {
      setSidebarDisplayed(false);
    } else {
      setSidebarDisplayed(true);
    }
  }, [location]);

  return (
    <Box
      id="cookie-container"
      className={clsx(
        classes.modalWrapper,
        sidebarDisplayed ? '' : classes.modalSizeNoSidebar
      )}
    >
      <Box id="cookie" className={classes.contentWrapper}>
        <Box className={classes.textWrapper}>
          <Typography variant="header2">{t('weValueYourPrivacy')}</Typography>
          <Box className={classes.smallTextWrapper}>
            <Typography variant="text"> {t('cookieShortText')}</Typography>
            <Typography variant="text">
              <RouterLink className={classes.linkColor} to={routes.dataPrivacy}>
                {t('readMore')}
              </RouterLink>
            </Typography>
          </Box>
          <Box className={classes.checkboxWrapper}>
            <FormGroup row={true}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="necessary"
                    checked={true}
                    className={sharedCheckboxClasses.checkboxInput}
                  />
                }
                className={classes.formLabel}
                label={t(`necessary`)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleSelectCookies}
                    name="statistics"
                    checked={statisticCookie}
                    className={sharedCheckboxClasses.checkboxInput}
                  />
                }
                className={classes.formLabel}
                label={t(`statistics`)}
              />
            </FormGroup>
          </Box>
        </Box>
        <DialogActions className={classes.buttonWrapper}>
          <MPButton2
            styleVariant="tertiary"
            customClasses={classes.button}
            onClick={() => {
              setStatisticCookieContext(statisticCookie);
              props.onSelected();
            }}
          >
            {t('acceptSelected')}
          </MPButton2>
          <MPButton2
            styleVariant="primary"
            onClick={() => {
              props.onAll();
            }}
          >
            {t('acceptAll')}
          </MPButton2>
        </DialogActions>
      </Box>
    </Box>
  );
};

export default CookieBanner;
