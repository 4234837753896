import { InvoiceItem } from '../../../../models/cost-item';
import { SubscriptionCoreCost } from '../../../../models/subscriptionCosts';
import { GetCostByPartnerIdSubscriptionDtoOut } from './get-cost-by-partner-id-subscription-dto-out';

export class GetCostByPartnerIdDtoOut {
  subscription: GetCostByPartnerIdSubscriptionDtoOut;

  /**
   * @description the cost generated by the subscription for the previous billing periods
   * @member {number} [previousCost]
   */
  previousCost: number;

  /**
   * @description the cost generated by the subscription for the current billing period
   * @member {number} [currentCost]
   */
  currentCost: number;

  /**
   * @description the subscription usage for the previous billing periods (number of calls | number of pages | number of documents | ...)
   * @member {number} [previousUsage]
   */
  previousUsage: number;

  /**
   * @description the subscription usage for the current billing period (number of calls | number of pages | number of documents | ...)
   * @member {number} [currentUsage]
   */
  currentUsage: number;

  /**
   * @description The currency of the cost amount
   * @member {string} [currency]
   */
  currency: string;

  /**
   * @description Calculated invoice items
   * @returns {InvoiceItem[]} [items]
   */
  items: InvoiceItem[];

  static fromDto(
    getCostByPartnerIdDtoOut: GetCostByPartnerIdDtoOut
  ): SubscriptionCoreCost {
    return {
      ...getCostByPartnerIdDtoOut,
    };
  }
}
