import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeLanguage: {
      fontWeight: 'bold',
      color: `${theme.palette.primary.main} !important`,
    },
    language: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      minWidth: '34px',
      padding: '5px',
      fontSize: '18px',
      lineHeight: '100%',
      color: theme.palette.grey[900],
      [theme.breakpoints.down('xl')]: {
        fontSize: '16px !important',
      },
    },
  })
);
export default useStyles;
