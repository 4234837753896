import {
  PublicClientApplication,
  Configuration,
  RedirectRequest,
  SilentRequest,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import { appConfiguration } from '../configuration';

const tenant = `${appConfiguration()?.auth.tenantName}.onmicrosoft.com`;
const instance = `https://${appConfiguration()?.auth.tenantName}.b2clogin.com/`;

const userFlows = {
  signUp: 'B2C_1_signup',
  signUpSignIn: 'B2C_1_signupsignin',
  resetPassword: 'B2C_1_resetpassword',
};

export const authScopes = [
  'openid',
  'profile',
  'offline_access',
  `${appConfiguration()?.auth.clientId}`,
];

const authorities = {
  signUp: `${instance}${tenant}/${userFlows.signUp}`,
  signUpSignIn: `${instance}${tenant}/${userFlows.signUpSignIn}`,
  resetPassword: `${instance}${tenant}/${userFlows.resetPassword}`,
};

const clientId = appConfiguration()?.auth.clientId || '';

const config: Configuration = {
  auth: {
    authority: authorities.signUpSignIn,
    knownAuthorities: [`${appConfiguration()?.auth.tenantName}.b2clogin.com`],
    clientId: clientId,
    redirectUri: (window as any).Cypress
      ? 'http://localhost:3000'
      : window.location.origin,
    postLogoutRedirectUri: (window as any).Cypress
      ? 'http://localhost:3000'
      : window.location.origin,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    tokenRenewalOffsetSeconds: 10,
  },
};

export const msalPca = new PublicClientApplication(config);

export const acquireTokenSilentOrRefreshToken = async (
  request: SilentRequest
) => {
  try {
    //acquire access token from cache or via refresh token
    return await msalPca.acquireTokenSilent(request);
  } catch (error) {
    //if there's user interaction required (session expired, refresh token expired) create a login popup
    if (error instanceof InteractionRequiredAuthError) {
      return await msalPca.acquireTokenPopup(request);
    }
    throw error;
  }
};

export const getCurrentAccount = () =>
  msalPca.getActiveAccount() || msalPca.getAllAccounts()[0];

export const authRequest: RedirectRequest = {
  scopes: authScopes,
};

export const forgotPasswordRequest: RedirectRequest = {
  scopes: authScopes,
  authority: authorities.resetPassword,
};

export const handleLogin = async (i18n: any) => {
  await msalPca.loginRedirect({
    ...authRequest,
    extraQueryParameters: {
      ui_locales: i18n.language,
    },
  });
};

// this function checks the cache if either the access token or the refresh token are still valid, throws an error if not
export const isSessionExpired = async () => {
  await msalPca.acquireTokenSilent({
    scopes: authScopes,
    account: getCurrentAccount(),
  });
  return false;
};

export const handleLogout = async () => {
  await msalPca.logoutRedirect();
};
