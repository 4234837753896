import { useEffect, useState } from 'react';

export const useModalHashRouteToggle = (
  modalHash: string
): {
  hashIsActive: boolean;
  toggleHash: (open: boolean) => void;
} => {
  const [hashIsActive, setHashIsActive] = useState<boolean>(false);

  const toggleHash = (open: boolean): void => {
    if (open) {
      window.location.assign(modalHash); // navigate to same url but with the specified hash
    } else {
      history.pushState(null, '', ' '); // remove the hash
    }
  };

  useEffect(() => {
    // function for handling hash change in browser, toggling modal open
    const handleOnHashChange = () => {
      const isHashMatch = window.location.hash === modalHash;
      setHashIsActive(isHashMatch);
    };

    // event listener for hashchange event
    window.addEventListener('hashchange', handleOnHashChange);

    // remove the event listener on unmounting
    return () => window.removeEventListener('hashchange', handleOnHashChange);
  }, [modalHash]);

  return { hashIsActive, toggleHash };
};
