import { Dialog, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useModalHashRouteToggle } from '../../hooks/modal-hash-route-toggle';
import ContactForm from './ContactForm';
import useStyles from './ContactForm.styles';

export let openContact: () => void;
export let closeContact: () => void;

const ContactFormOverlay: React.FC = () => {
  const classes = useStyles();
  const CONTACT_MODAL_HASH = '#contact';
  const { hashIsActive, toggleHash } =
    useModalHashRouteToggle(CONTACT_MODAL_HASH);
  const [contactOpen, setContactOpen] = useState(false);

  useEffect(() => {
    openContact = () => {
      setContactOpen(true);
      toggleHash(true);
    };
    closeContact = () => {
      setContactOpen(false);
      toggleHash(false);
    };
  });

  return (
    <Dialog
      open={contactOpen && hashIsActive}
      className={classes.dialog}
      onClose={() => {
        setContactOpen(false);
        toggleHash(false);
      }}
      scroll="body"
    >
      <Grid container className={classes.pictureWrapper}>
        <img
          src="/img/question.png"
          alt="question"
          className={classes.questionIcon}
        />
      </Grid>
      <Grid className={classes.dialogGrid}>
        <ContactForm
          largeScreenColumns={12}
          titleKey=""
          centerTitle={true}
          successMessageKey="contactSuccessMessage"
          overlay
        />
      </Grid>
    </Dialog>
  );
};

export default ContactFormOverlay;
