export const convertUTCUnixTimestampToDate = (timestamp: number): string => {
  return Intl.DateTimeFormat('default', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }).format(timestamp * 1000 - new Date().getTimezoneOffset() * 60_000);
};

export const convertUnixTimestampToDateAndTime = (
  timestamp: number
): string => {
  return Intl.DateTimeFormat('default', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(timestamp);
};

export const convertUnixTimestampToTime = (timestamp: number): string => {
  return Intl.DateTimeFormat('default', {
    hour: 'numeric',
    minute: 'numeric',
  }).format(timestamp);
};

export const convertDateToUnixTimestamp = (date: Date): number => {
  return date.getTime() / 1000;
};

export const checkIfNotificationWasCreatedToday = (
  createdAt: number
): boolean => {
  const today = new Date();
  const notificationCreationDay = new Date(createdAt);
  return (
    today.setHours(0, 0, 0, 0) === notificationCreationDay.setHours(0, 0, 0, 0)
  );
};

export const numberOfDaysBetweenTimestamps = (
  timestamp1: number,
  timestamp2: number
): number => {
  const differenceInMilliseconds = timestamp1 - timestamp2;
  const differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24)
  );

  return differenceInDays;
};
