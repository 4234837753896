/* eslint-disable import/no-named-as-default*/
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector, {
  DetectorOptions,
} from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { appConfiguration } from './configuration';

export const supportedLngs = [
  { key: 'de', text: 'Deutsch' },
  { key: 'en', text: 'English' },
];

const detectorOptions: DetectorOptions = {
  order: [
    'path',
    'querystring',
    'cookie',
    'localStorage',
    'sessionStorage',
    'subdomain',
  ],
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage'],
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'de',
    supportedLngs: supportedLngs.map((l) => l.key),
    debug: process.env.NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false,
    },
    react: {
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    },
    detection: detectorOptions,
    backend: {
      loadPath: appConfiguration()?.local?.localePath
        ? `env/${
            appConfiguration()?.local?.localePath
          }/locales/{{lng}}/{{ns}}.json`
        : '/locales/{{lng}}/{{ns}}.json',
    },
  })
  .catch((err) => {
    console.log('error at i18n.init: ', err);
  });
export default i18n;
