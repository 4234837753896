import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '..';
import { SubscriptionCoreCost } from '../../models/subscriptionCosts';
import { subscriptionCostsService } from '../../services/cost/cost-service';

export enum SubscriptionsCostsActionTypes {
  LOAD_COSTS = '[SubscriptionsCosts] Load costs by subscriptions',
  LOAD_COSTS_SUCCESS = '[SubscriptionsCosts] Load costs by subscriptions success',
  LOAD_COSTS_FAIL = '[SubscriptionsCosts] Load costs by subscriptions fail',
  SET_COSTS_LOADING = '[SubscriptionsCosts] Costs by subscriptions are loading',
}

interface loadCostsAction {
  type: typeof SubscriptionsCostsActionTypes.LOAD_COSTS;
}

export const loadCosts: ActionCreator<
  ThunkAction<Promise<any>, AppState, null, loadCostsAction>
> = () => {
  return async (dispatch: Dispatch, getState) => {
    try {
      const currentPartner = getState().partner.currentPartner;
      if (!currentPartner) {
        dispatch(loadCostsFail('Current partner not loaded'));
        return;
      }
      dispatch(setCostsloading(true));
      const costs = await subscriptionCostsService.getCostsForPartner(
        currentPartner.id
      );
      dispatch(loadCostsSuccess(costs));
    } catch (err) {
      dispatch(loadCostsFail(err));
    }
  };
};

interface loadCostsSuccessAction {
  type: typeof SubscriptionsCostsActionTypes.LOAD_COSTS_SUCCESS;
  payload: SubscriptionCoreCost[];
}

const loadCostsSuccess = (
  payload: SubscriptionCoreCost[]
): loadCostsSuccessAction => ({
  type: SubscriptionsCostsActionTypes.LOAD_COSTS_SUCCESS,
  payload,
});

interface loadCostsFailAction {
  type: typeof SubscriptionsCostsActionTypes.LOAD_COSTS_FAIL;
  payload: any;
}

const loadCostsFail = (payload: any): loadCostsFailAction => ({
  type: SubscriptionsCostsActionTypes.LOAD_COSTS_FAIL,
  payload,
});

interface SetCostsloadingAction {
  type: typeof SubscriptionsCostsActionTypes.SET_COSTS_LOADING;
  payload: boolean;
}

const setCostsloading = (payload: boolean): SetCostsloadingAction => ({
  type: SubscriptionsCostsActionTypes.SET_COSTS_LOADING,
  payload,
});

export type SubscriptionsCostsActions =
  | loadCostsAction
  | loadCostsSuccessAction
  | loadCostsFailAction
  | SetCostsloadingAction;
