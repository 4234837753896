import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { FontWeightProperty } from '../types/types';
import { Distances } from '../AppTheme';
import { AspectRatio, CustomStyleDimension } from '../models/enums';

export const useSharedStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionTitle: {
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(7),
    },
    card: {
      padding: `45px 40px`,
      border: '2px solid transparent',
      boxSizing: 'border-box',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '45px',
      cursor: 'pointer',
      userSelect: 'none',
      '-moz-user-select': 'none',
      '-webkit-user-select': 'none',
      '-ms-user-select': 'none',
      '&:hover': {
        borderColor: theme.palette.primary.main,
        '& > #cardTitle': {
          color: theme.palette.primary.main,
        },
      },
    },
    button: {
      transition: 'all 0.10s',
      padding: '0px 16px',
      fontWeight: theme.typography.fontWeightLight as FontWeightProperty,
      fontSize: '1.5rem',
      color: theme.palette.primary.main,
      textTransform: 'none',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '70px',
      lineHeight: 1.4,
      '&:hover': {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
      },
    },
    divider: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(4),
      borderTop: `0px solid ${theme.palette.primary.main}`,
      borderWidth: '2px',
      backgroundColor: 'transparent',
    },
    buttonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '5px',
      marginBottom: Distances.HALF_FIELD_20,
      justifyContent: 'flex-end',
    },
    moduleContainer: {
      marginTop: '60px',
      '& > .MuiGrid-item': {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },
    marginTopHalfField: {
      marginTop: '30px',
    },
    marginTopField: {
      marginTop: Distances.FIELD,
    },
    marginBottomHalfField: {
      marginBottom: Distances.HALF_FIELD_30,
    },
    marginTopBig: {
      marginTop: '80px',
    },
    marginRight: {
      marginRight: theme.spacing(2),
    },
    formLabel: {
      '&.MuiFormControlLabel-root': {
        paddingLeft: theme.spacing(0.5),
        marginTop: theme.spacing(2),
        fontSize: '14px',
        marginLeft: '0px',
      },
      '& .MuiFormControlLabel-label': {
        marginLeft: theme.spacing(1),
        fontSize: '14px',
      },
    },
    pointerCursor: {
      cursor: 'pointer',
    },
  })
);

/**
 *
 * @param aspectRatio the aspect ratio of the displayed image
 * @param useMaxProperty sometimes we want to set `maxWidth` but some other times `width`, this flag tells us which one we should use
 * @param multiplier in case the image needs to be bigger we can use this value to increase the default sizes
 */
export const getImageSizeForAspectRatio = (
  aspectRatio: AspectRatio,
  dimension: CustomStyleDimension[],
  multiplier = 1
) => {
  const widthDefaultPxSize =
    aspectRatio === AspectRatio.ONE_BY_ONE
      ? '100px'
      : aspectRatio === AspectRatio.FOUR_BY_THREE
      ? '240px'
      : '250px'; // would be for the third and last aspect ratio (5:3)

  const heightDefaultPxSize =
    aspectRatio === AspectRatio.ONE_BY_ONE
      ? '100px'
      : aspectRatio === AspectRatio.FOUR_BY_THREE
      ? '180px'
      : '150px'; // would be for the third and last aspect ratio (5:3)

  const widthProperty = `calc(${widthDefaultPxSize} * ${multiplier})`;
  const heightProperty = `calc(${heightDefaultPxSize} * ${multiplier})`;

  let finalDimensions = {};

  dimension.forEach((dimension) => {
    switch (dimension) {
      case CustomStyleDimension.DEFAULT_HEIGHT_AND_WEIGHT: {
        finalDimensions = {
          ...finalDimensions,
          width: widthProperty,
          height: heightProperty,
        };
        break;
      }
      case CustomStyleDimension.MAX_HEIGHT_AND_WEIGHT: {
        finalDimensions = {
          ...finalDimensions,
          maxWidth: widthProperty,
          maxHeight: heightProperty,
        };
        break;
      }
      case CustomStyleDimension.MIN_HEIGHT_AND_WEIGHT: {
        finalDimensions = {
          ...finalDimensions,
          minWidth: widthProperty,
          minHeight: heightProperty,
        };
        break;
      }
    }
  });

  return finalDimensions;
};
