import { DashboardProductSubscription } from '../../../models/dashboard-product';
import { DashboardStatistics } from '../../../models/dashboard-statistics';

export class GetPartnerProductStatisticsDtoOut {
  /**
   * @member {string} [id]
   */
  id: string;

  /**
   * @member {number} [successRateAvg]
   */
  successRateAvg: number;

  /**
   * @member {number} [latencyAvg]
   */
  latencyAvg: number;

  /**
   * @member {number} [subscriptionCount]
   * @description count of currently active subscriptions
   */
  subscriptionCount?: number;

  /**
   * @member {DashboardProductSubscription[]} [subscriptions]
   */
  subscriptions: DashboardProductSubscription[];

  static fromDto(
    getPartnersStatisticsAllDtoOut: GetPartnerProductStatisticsDtoOut
  ): DashboardStatistics {
    return getPartnersStatisticsAllDtoOut;
  }
}
