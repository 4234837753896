import { AuthenticatedTemplate } from '@azure/msal-react';
import { Box, Button, Typography } from '@mui/material';
import { clsx } from 'clsx';
import { Dictionary } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { getLocalPicturePath } from '../../configuration/local-dev-utils';
import routes from '../../routes';
import MPButton2 from '../button/MPButton2';
import { openContact } from '../contact-form/ContactFormOverlay';
import useStyles from './Sidebar.styles';

interface SidebarSection {
  key: string;
  iconPath?: string;
  toPath: string;
}

enum SidebarSections {
  HOME = 'HOME',
  ABOUT = 'ABOUT',
  DASHBOARD = 'DASHBOARD',
  ALL_SOLUTIONS = 'ALL_SOLUTIONS',
}

const Sidebar: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const route = location.pathname;

  const [sidebarConfig, setSidebarConfig] = useState<
    Dictionary<SidebarSection>
  >({
    [SidebarSections.HOME]: {
      key: 'home',
      toPath: routes.home,
    },
    // [SidebarSections.ABOUT]: {
    //   key: 'about',
    //   toPath: routes.ourVision,
    // }, // will be used again later
    [SidebarSections.DASHBOARD]: {
      key: 'dashboard',
      iconPath: '/icons/sidebar/dashboard.svg',
      toPath: routes.dashboard.base,
    },
    [SidebarSections.ALL_SOLUTIONS]: {
      key: 'allSolutions',
      toPath: routes.productCatalog,
    },
  });

  useEffect(() => {
    setSidebarConfig({
      ...sidebarConfig,
      // [SidebarSections.ABOUT]: {
      //   ...sidebarConfig[SidebarSections.ABOUT],
      //   iconPath:
      //     location.pathname === routes.ourVision
      //       ? '/icons/sidebar/our_vision_WHITE.svg'
      //       : '/icons/sidebar/our_vision.svg',
      // }, // will be used again later
      [SidebarSections.HOME]: {
        ...sidebarConfig[SidebarSections.HOME],
        iconPath:
          location.pathname === routes.home
            ? '/icons/sidebar/home_WHITE.svg'
            : '/icons/sidebar/home.svg',
      },
      [SidebarSections.ALL_SOLUTIONS]: {
        ...sidebarConfig[SidebarSections.ALL_SOLUTIONS],
        iconPath:
          location.pathname === routes.productCatalog
            ? '/icons/sidebar/all_solutions_WHITE.svg'
            : '/icons/sidebar/all_solutions.svg',
      },
    });
    // sidebarConfig will not be added to the dependency array, since it causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    sidebarConfig && (
      <Box className={classes.sidebar}>
        <Box className={classes.marginBottomMedium}>
          <Button
            component={Link}
            to={sidebarConfig[SidebarSections.HOME].toPath}
            className={classes.logoButton}
          >
            <img
              src={getLocalPicturePath('/img/logo_sidebar.png')}
              alt={'msg.Marketplace'}
            />
          </Button>
        </Box>
        <Box className={classes.marginBottomMedium}>
          <Typography variant="menu" className={classes.headline}>
            {t('start')}
          </Typography>
          <Button
            component={Link}
            to={sidebarConfig[SidebarSections.HOME].toPath}
            className={clsx(
              classes.menuItemButton,
              route === routes.home && classes.focus
            )}
          >
            <Typography
              variant="header3"
              fontWeight="regular"
              className={classes.menuItemTypography}
            >
              <img
                src={sidebarConfig[SidebarSections.HOME].iconPath}
                alt={sidebarConfig[SidebarSections.HOME].key}
                className={classes.menuItemIcon}
              />
              {t(sidebarConfig[SidebarSections.HOME].key)}
            </Typography>
          </Button>
          {/* <Button
            component={Link}
            to={sidebarConfig[SidebarSections.ABOUT].toPath}
            className={clsx(
              classes.menuItemButton,
              route === routes.ourVision && classes.focus
            )}
          >
            <Typography
              variant="header3"
              fontWeight="regular"
              className={classes.menuItemTypography}
            >
              <img
                src={sidebarConfig[SidebarSections.ABOUT].iconPath}
                alt={sidebarConfig[SidebarSections.ABOUT].key}
                className={classes.menuItemIcon}
              />
              {t(sidebarConfig[SidebarSections.ABOUT].key)}
            </Typography>
          </Button>  // will be used again later*/}
          <AuthenticatedTemplate>
            <Button
              data-cy="dashboard-button"
              component={Link}
              to={sidebarConfig[SidebarSections.DASHBOARD].toPath}
              className={clsx(
                classes.menuItemButton,
                route.startsWith(routes.dashboard.base) && classes.focus
              )}
            >
              <Typography
                variant="header3"
                fontWeight="regular"
                className={classes.menuItemTypography}
              >
                <img
                  src={sidebarConfig[SidebarSections.DASHBOARD].iconPath}
                  alt={sidebarConfig[SidebarSections.DASHBOARD].key}
                  className={classes.menuItemIcon}
                />
                {t(sidebarConfig[SidebarSections.DASHBOARD].key)}
              </Typography>
            </Button>
          </AuthenticatedTemplate>
        </Box>
        <Box className={classes.marginBottomMedium}>
          <Typography variant="menu" className={classes.headline}>
            {t('solutions')}
          </Typography>
          <Button
            component={Link}
            to={sidebarConfig[SidebarSections.ALL_SOLUTIONS].toPath}
            className={clsx(
              classes.menuItemButton,
              route === routes.productCatalog && classes.focus
            )}
          >
            <Typography
              variant="header3"
              fontWeight="regular"
              className={classes.menuItemTypography}
            >
              <img
                src={sidebarConfig[SidebarSections.ALL_SOLUTIONS].iconPath}
                alt={sidebarConfig[SidebarSections.ALL_SOLUTIONS].key}
                className={classes.menuItemIcon}
              />
              {t(sidebarConfig[SidebarSections.ALL_SOLUTIONS].key)}
            </Typography>
          </Button>
        </Box>
        <Box className={classes.containerContact}>
          <img
            src="/img/question.png"
            alt="question"
            className={classes.questionIcon}
          />
          <Box className={classes.contentContact}>
            <Typography variant="header3" className={classes.typographyContact}>
              {t('questions')}
            </Typography>
            <MPButton2 styleVariant="contactMenu" onClick={() => openContact()}>
              {t('contactUs')}
            </MPButton2>
          </Box>
        </Box>
      </Box>
    )
  );
};

export default Sidebar;
