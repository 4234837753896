export enum CollectionMethod {
  CHARGE_AUTOMATICALLY = 'charge_automatically',
  SEND_INVOICE = 'send_invoice',
}

export enum PaymentMethodId {
  INVOICE_PAYMENT_ID = 'invoice_payment_id',
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  TERMINATED = 'terminated',
  PENDING = 'pendingDate',
  NEW = 'new',
  REJECTED = 'rejected',
  FINALIZED = 'finalized',
  TECHNICAL_CONFIGURATION = 'technical configuration',
}

export enum SubscriptionApiKey {
  PRIMARY = 'primaryKey',
  SECONDARY = 'secondaryKey',
}

export enum MemberStatus {
  PENDING = 'pending',
  ACTIVE = 'active',
  UPDATING = 'updating',
  NOT_FOUND = 'not_found',
}

export enum AvailabilityStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export enum PlanSourceType {
  PER_REQUEST = 'per_request',
  RESPONSE_HEADER = 'response_header',
  PROVIDER_PUSH = 'provider_push',
}

export enum AuthMode {
  NONE = 'none',
  BASIC = 'basic',
  OAUTH = 'oauth',
  CUSTOM_HEADER = 'custom_header',
  CUSTOM_FLOW = 'custom_flow',
}

export enum ProductStatus {
  DRAFT = 'draft',
  IN_REVIEW = 'in_review',
  IN_TECHNICAL_CONFIGURATION = 'in_technical_configuration',
  APPROVED = 'approved',
  PENDING = 'pending',
  ACTIVE = 'active',
  FAILED = 'failed',
  INACTIVE = 'inactive',
}

export enum SignalRChannel {
  SUBSCRIPTIONS = 'subscriptions',
  PARTNERS = 'partners',
  PRODUCTS = 'products',
  NOTIFICATIONS = 'notifications',
}

export enum SignalRMessageType {
  EMPTY = 'empty',
  SUBSCRIPTION_ACTIVATED = 'subscription-activated',
  SUBSCRIPTION_CANCELED = 'subscription-canceled',
  SUBSCRIPTION_TERMINATED = 'subscription-terminated',
  MEMBER_ROLE_CHANGED = 'member-role-changed',
  PRODUCT_CREATED = 'product-created',
  PRODUCT_CREATION_FAILED = 'product-creation-failed',
  PARTNER_UPDATED = 'partner-updated',
}

export enum SpecFormat {
  OPENAPI = 'openapi+json-link',
  WSDL = 'wsdl-link',
}

export enum NotificationSeverityType {
  INFORMATIVE = 'INFORMATIVE',
  IMPORTANT = 'IMPORTANT',
  CRITICAL = 'CRITICAL',
}

export enum NotificationReadStatus {
  READ = 'READ',
  UNREAD = 'UNREAD',
}

export enum NotificationStatus {
  AVAILABLE = 'AVAILABLE',
  ARCHIVED = 'ARCHIVED',
}

export enum NotificationType {
  SERVICE_SUBSCRIPTION_CREATED = 'SERVICE_SUBSCRIPTION_CREATED',
}

export enum SubscriptionNotificationPhase {
  USER_READY_TO_USE = 'USER_READY_TO_USE',
  PARTNER_READY_TO_USE = 'PARTNER_READY_TO_USE',
  PARTNER_NEW_SUBSCRIBER = 'PARTNER_NEW_SUBSCRIBER',
}

export enum PeriodTermsTimeUnit {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
}

export enum ApiType {
  REST = 'rest',
  SOAP = 'soap',
  SOAP_TO_REST = 'soap_to_rest',
  CUSTOM = 'custom',
  NONE = 'none',
}

export enum ProductAction {
  SEND_TO_REVIEW = 'send-to-review',
  REVIEW_ACCEPTED = 'review-accepted',
  REVIEW_DECLINED = 'review-declined',
  TECHNICAL_CONFIGURATION_FINISHED = 'technical-configuration-finished',
  PUBLISH = 'publish',
}

export enum SubscriptionAction {
  CONFIRM = 'confirm-subscription',
  REJECT = 'reject-subscription',
}

export enum SelfServicePricePlanType {
  ONE_TIME = 'ONE_TIME',
  RECURRING = 'RECURRING',
  CUSTOM = 'CUSTOM',
}

export enum Category {
  ALL_PRODUCTS = 'ALL_PRODUCTS',
  PRODUCTS = 'PRODUCTS',
  SALES_MANAGEMENT = 'SALES_MANAGEMENT',
  PROPOSAL = 'PROPOSAL',
  CONTRACT = 'CONTRACT',
  CLAIMS_BENEFITS = 'CLAIMS_BENEFITS',
  PAYMENT = 'PAYMENT',
  CAPITAL_INVESTMENT = 'CAPITAL_INVESTMENT',
  REINSURANCE = 'REINSURANCE',
  SALES_AND_CUSTOMER = 'SALES_AND_CUSTOMER',
  INFORMATION_AND_REPORTING = 'INFORMATION_AND_REPORTING',
  STRATEGY = 'STRATEGY',
  PLANNING_AND_CONTROLLING = 'PLANNING_AND_CONTROLLING',
  RISK_MANAGEMENT = 'RISK_MANAGEMENT',
  PROCESSES_AND_ORGANIZATION = 'PROCESSES_AND_ORGANIZATION',
  HUMAN_RESOURCES = 'HUMAN_RESOURCES',
  PROJECTS = 'PROJECTS',
  MARKETING_AND_COMMUNICATION = 'MARKETING_AND_COMMUNICATION',
  IT = 'IT',
  INPUT_OUTPUT = 'INPUT_OUTPUT',
  REWE = 'REWE',
  LEGAL_AND_COMPLIANCE = 'LEGAL_AND_COMPLIANCE',
  PROCUREMENT = 'PROCUREMENT',
  INTERNAL_SERVICES = 'INTERNAL_SERVICES',
  HEALTH = 'HEALTH',
}

export enum Industry {
  CROSS_INDUSTRY = 'CROSS_INDUSTRY',
  AUTOMOTIVE = 'AUTOMOTIVE',
  BANKING = 'BANKING',
  CUSTOMER_PRODUCTS = 'CUSTOMER_PRODUCTS',
  FOOD = 'FOOD',
  HEALTHCARE = 'HEALTHCARE',
  INSURANCE = 'INSURANCE',
  LIFE_SCIENCE_AND_CHEMICALS = 'LIFE_SCIENCE_AND_CHEMICALS',
  MANUFACTURING = 'MANUFACTURING',
  PUBLIC_SECTOR = 'PUBLIC_SECTOR',
  REINSURANCE = 'REINSURANCE',
  TELECOMMUNICATION = 'TELECOMMUNICATION',
  TRAVEL_AND_LOGISTICS = 'TRAVEL_AND_LOGISTICS',
  UTILITIES = 'UTILITIES',
  OTHER = 'OTHER',
}

export enum BusinessProductType {
  PRODUCT_ADDON = 'PRODUCT_ADDON',
  SERVICE_WORKSHOP = 'SERVICE_WORKSHOP',
}

export enum FulfillmentMethod {
  EMAIL = 'Email',
  API = 'API',
}

export enum PlanName {
  PAY_AS_YOU_GO = 'PAY_AS_YOU_GO',
  BASIC = 'BASIC',
  BUSINESS = 'BUSINESS',
  ENTERPRISE = 'ENTERPRISE',
  FREE_TIER = 'FREE_TIER',
}

export enum Interval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum PriceModel {
  FIXED = 'fixed',
  TRANSACTIONAL = 'transactional',
  ONE_OFF = 'one-off',
  GRADUATED_TRANSACTIONAL = 'graduated-transactional',
}

export enum SecurityActionType {
  MEMBER_ROLE_CHANGE,
  PAYMENT_METHODS_MODIFICATION,
  API_KEYS_RENEWAL,
}

export enum ProductType {
  LISTING = 'listing',
  SOFTWARE_AS_A_SERVICE = 'software_as_a_service',
}

export enum BillingSystem {
  STRIPE = 'Stripe',
  NONE = 'None',
  MSG = 'Msg',
}

export enum PaymentMethodType {
  CARD = 'card',
  SEPA = 'sepa',
  WIRE_TRANSFER = 'wire-transfer',
}

export enum BlockType {
  VIDEO = 'video',
  TEXT_AND_IMAGE = 'text-and-image',
  IMAGE_AND_TEXT = 'image-and-text',
  TEXT = 'text',
}

export enum TextBlockContentAlignment {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export enum ProductsFilterType {
  SOLUTION = 'solution',
  INDUSTRY = 'industry',
  PROVIDER = 'provider',
}

/* eslint-disable import/no-unused-modules*/
export enum AspectRatio {
  ONE_BY_ONE = 1,
  FOUR_BY_THREE = 4 / 3,
  FIVE_BY_THREE = 5 / 3,
}

export enum CustomStyleDimension {
  MIN_HEIGHT_AND_WEIGHT = 'min',
  MAX_HEIGHT_AND_WEIGHT = 'max',
  DEFAULT_HEIGHT_AND_WEIGHT = 'default',
}

export enum PurchaseType {
  ONE_TIME = 'ONE_TIME',
  RECURRING = 'RECURRING',
}