import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Distances } from '../../AppTheme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sidebar: {
      position: 'fixed',
      display: 'flex',
      flexDirection: 'column',
      width: 'inherit',
      height: '100%',
      paddingLeft: Distances.HALF_FIELD_30,
      paddingTop: Distances.HALF_FIELD_20,
      background: theme.palette.primary.main,
      overflowY: 'auto',
      overflowX: 'hidden',
      zIndex: 100 //should be higher than top navBar, so it can't overlap
    },
    logoButton: {
      color: 'transparent !important',
      paddingLeft: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    marginBottomMedium: {
      marginBottom: Distances.FIELD,
    },
    headline: {
      marginBottom: Distances.INPUT,
      color: theme.palette.lightRed.main,
    },
    menuItemButton: {
      display: 'block',
      width: '100%',
      padding: Distances.HALF_FIELD_20,
      borderRadius: '10px 0px 0px 10px',
      textAlign: 'left',
      textTransform: 'none',
      color: theme.palette.white.main,
      '&:hover': {
        background: theme.palette.accentRed.main,
      },
      '& > span': {
        display: 'flex',
      },
    },
    menuItemTypography: {
      alignItems: 'center',
    },
    menuItemIcon: {
      marginRight: '7px',
    },
    focus: {
      backgroundColor: theme.palette.primary.dark,
      filter: 'brightness(130%)',
    },

    containerContact: {
      marginTop: 'auto',
      bottom: 0,
      width: '180px',
      height: '112px',
      marginRight: Distances.HALF_FIELD_30,
      borderRadius: '10px',
      color: theme.palette.white.main,
      background: theme.palette.accentRed.main,
      marginBottom: Distances.HALF_FIELD_30,
    },
    contentContact: {
      marginTop: 'auto',
      bottom: '52px',
      position: 'relative',
      display: 'grid',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      color: theme.palette.white.main,
    },
    typographyContact: {
      marginBottom: Distances.INPUT,
      fontWeight:'400',
    },
    questionIcon: {
      position: 'relative',
      top: '-33px',
      bottom: '-36px',
      left: '58px',
    },
  })
);

export default useStyles;
