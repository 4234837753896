import { appConfiguration } from '../../configuration';
import {
  authScopes,
  getCurrentAccount,
  acquireTokenSilentOrRefreshToken,
} from '../auth';
import axios from '../httpClient';
import { GetPartnerProductStatisticsDtoOut } from './dtos/get-partners-statistics-all-dto-out';

class PartnerStatisticsService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async getProductDashboardStatistics(
    partnerId: string,
    productId: string
  ): Promise<GetPartnerProductStatisticsDtoOut> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    const response = (
      await axios.get(
        `${this.baseUrl}/partners/${partnerId}/products/${productId}/statistics`,
        {
          headers: {
            Authorization: `Bearer ${authResult.idToken}`,
          },
        }
      )
    ).data;
    return GetPartnerProductStatisticsDtoOut.fromDto(response);
  }
}

export const partnerStatisticsService = new PartnerStatisticsService(
  appConfiguration()?.coreApiUrl ?? ''
);
