import { COOKIE_EXPIRATION_IN_MSEC } from '../../constants/constants';

export const getCookieValue = (name: string) => {
  const cookieList = decodeURIComponent(document.cookie).split(';');
  let cookieSettingValue = '';
  for (let i = 0; i < cookieList.length; i++) {
    let c = cookieList[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      cookieSettingValue = c.substring(name.length + 1, c.length);
    }
  }
  return cookieSettingValue;
};

const getCookieExpirationDate = () => {
  const now = new Date();
  const time = now.getTime();
  const expireTime = time + COOKIE_EXPIRATION_IN_MSEC;
  now.setTime(expireTime);
  return now;
};

export const setCookie = (name: string, value: string) => {
  const cookieExpirationDate = getCookieExpirationDate();
  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${cookieExpirationDate.toUTCString()} path=/`;
};

export const isCookieSet = (name: string) => {
  const cookieList = decodeURIComponent(document.cookie).split(';');
  let cookieSettingValue = '';
  for (let i = 0; i < cookieList.length; i++) {
    let c = cookieList[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      cookieSettingValue = c.substring(name.length + 1, c.length);
    }
  }
  return !!cookieSettingValue;
};
