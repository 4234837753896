import { AvailabilityStatus } from '../../../../models/enums';
import { RoleConfig, UserPartner } from '../../../../models/partner';

export class GetPartnersUsersDtoOut {
  id: string;
  companyName: string;
  displayName?: string;
  roles: RoleConfig[];
  partnerCreatedInAPIM: boolean | undefined;
  partnerCreatedInStripe: boolean | undefined;
  hasSubscriptions: boolean;
  hasServices: boolean;
  availabilityStatus: AvailabilityStatus;
  consumerFrameContractSigned: boolean;
  providerFrameContractSigned: boolean;
  shortDescription: string;

  static fromDto(getPartnersDtoOut: GetPartnersUsersDtoOut): UserPartner {
    return {
      ...getPartnersDtoOut,
    };
  }
}
