import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '..';
import { Product } from '../../models/product';
import { productsService } from '../../services/products/products-service';
import { ProductsState } from '../reducers/productsReducer';

export enum ProductsActionTypes {
  LOAD_ALL = 'Load all products',
  LOAD_BY_ID_SUCCESS = 'Load product by id success',
  LOAD_BY_ID_FAIL = 'Load product by id fail',
  LOAD_ALL_SUCCESS = 'Load all success',
  SET_LOADING = 'Products loading',
  CREATE_PRODUCT_SUCCESS = 'Create a new product success',
  UPDATE_PRODUCT_SUCCESS = 'Update a product success',
}

interface LoadAllProductsAction {
  type: typeof ProductsActionTypes.LOAD_ALL;
}

export const loadAllProducts: ActionCreator<
  ThunkAction<Promise<any>, AppState, null, LoadAllProductsAction>
> = () => {
  return async (dispatch: Dispatch, getState) => {
    if (Object.keys(getState().products.products).length <= 1) {
      try {
        dispatch(setProductsLoading(true));
        const products = await productsService.getAllProducts();
        productsService.setNewFlagOnAllProducts(products);
        dispatch(loadAllProductsSuccess(products));
      } catch (err) {
        console.error(err);
      }
    }
  };
};

interface LoadProductByIdSuccessAction {
  type: typeof ProductsActionTypes.LOAD_BY_ID_SUCCESS;
  data: Product;
}

const loadProductByIdSuccess = (
  data: Product
): LoadProductByIdSuccessAction => ({
  type: ProductsActionTypes.LOAD_BY_ID_SUCCESS,
  data,
});

interface LoadProductByIdFailAction {
  type: typeof ProductsActionTypes.LOAD_BY_ID_FAIL;
  data: string;
}

const loadProductByIdFail = (data: string): LoadProductByIdFailAction => ({
  type: ProductsActionTypes.LOAD_BY_ID_FAIL,
  data,
});

interface CreateProductSuccessAction {
  type: typeof ProductsActionTypes.CREATE_PRODUCT_SUCCESS;
  data: Product;
}

interface UpdateProductSuccessAction {
  type: typeof ProductsActionTypes.UPDATE_PRODUCT_SUCCESS;
  data: Product;
}

export const loadProductById: ActionCreator<
  ThunkAction<Promise<any>, ProductsState, null, LoadProductByIdSuccessAction>
> = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setProductsLoading(true));
      const product = await productsService.getProductById(id);
      productsService.setNewFlagOnProduct(product);
      dispatch(loadProductByIdSuccess(product));
    } catch (err: any) {
      dispatch(
        loadProductByIdFail(
          err?.response?.status === 404
            ? '404'
            : err?.response?.data?.message || err?.message || '500'
        )
      );
      console.error(err);
    }
  };
};

interface LoadAllProductsSuccessAction {
  type: typeof ProductsActionTypes.LOAD_ALL_SUCCESS;
  data: Product[];
}

const loadAllProductsSuccess = (
  data: Product[]
): LoadAllProductsSuccessAction => ({
  type: ProductsActionTypes.LOAD_ALL_SUCCESS,
  data,
});

interface SetProductsLoadingAction {
  type: typeof ProductsActionTypes.SET_LOADING;
  payload: boolean;
}

const setProductsLoading = (loading: boolean) => ({
  type: ProductsActionTypes.SET_LOADING,
  payload: loading,
});

export type ProductsActions =
  | LoadAllProductsAction
  | LoadProductByIdSuccessAction
  | LoadProductByIdFailAction
  | LoadAllProductsSuccessAction
  | SetProductsLoadingAction
  | CreateProductSuccessAction
  | UpdateProductSuccessAction;
