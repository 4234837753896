import React from 'react';
import { Box, Hidden, Button, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { handleLogin } from '../../../../services/auth';
import useStyles from './Login.styles';

const Login: React.FC = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Box component="span">
      <Hidden mdDown>
        <Button
          color="inherit"
          className={classes.loginButton}
          onClick={() => handleLogin(i18n)}
          data-cy="login-button"
        >
          {t('login')}
        </Button>
      </Hidden>

      <Hidden mdUp>
        <MenuItem onClick={() => handleLogin(i18n)} data-cy="login-button">
          {t('login')}
        </MenuItem>
      </Hidden>
    </Box>
  );
};

export default Login;
