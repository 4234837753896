import { Block } from '../../../../models/block';
import { BusinessProductSubType } from '../../../../models/business-product-sub-type';
import ContractPeriodTerms from '../../../../models/contract-period-terms.model';
import { Documentation } from '../../../../models/documentation';
import {
  ApiType,
  AvailabilityStatus,
  BillingSystem,
  Industry,
  Interval,
  ProductStatus,
  ProductType,
  PurchaseType,
} from '../../../../models/enums';
import { PriceComponentType } from '../../../../models/price.model';
import { Product } from '../../../../models/product';
import { ProductAdvantage } from '../../../../models/product-advantage';
import { UseCases } from '../../../../models/useCases';
import { WsdlSelector } from '../../../../models/wsdl-selector';
import { ProductFilesDtoOut } from './product-files-dto-out';

export class GetProductByIdDtoOut {
  id: string;

  displayName: string;

  productType: ProductType;

  shortDescription: string;

  longDescription: string;

  providerId: string;

  providerName: string;

  responsible: string;

  responsibleEmail: string;

  companyDisplayName?: string;

  providerShortDescription: string;

  industries: Industry[];

  files: ProductFilesDtoOut;

  advantages: ProductAdvantage[];

  baseProductName: string;

  baseProductLink: string;

  statistics: {
    /**
     * @member {number} [subscriptionCount]
     * @description count of currently active subscriptions
     */
    subscriptionCount?: number;
  };

  status: ProductStatus;

  availabilityStatus: AvailabilityStatus;

  individualAvailabilityStatus: AvailabilityStatus;

  billingSystem: BillingSystem;

  configuration: {
    apiType: ApiType;
    wsdlSelector?: WsdlSelector;
  };

  customPlanRequested?: boolean;

  plans: {
    id: string;

    /**
     * @description Indicates the name of the price plan
     */
    name: string;

    /**
     * @description Indicates the initial and prolongation terms of a contract
     */
    contractPeriodTerms?: ContractPeriodTerms;

    purchaseType: PurchaseType;

    sellingPrice: {
      billingCycle?: {
        interval: Interval;
        intervalCount: number;
      };
      currency: string;
      priceComponents: PriceComponentType[];
    };
  }[];

  useCases: UseCases;
  documentation: Documentation;
  blocks: [Block];
  businessProductSubType: BusinessProductSubType[];
  publishedAt: number;

  static fromDto(getProductByIdDtoOut: GetProductByIdDtoOut): Product {
    return { ...getProductByIdDtoOut };
  }
}
