import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Distances } from '../../AppTheme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contactGridContainer: {
      maxWidth: '100%',
      fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.down('md')]: {
        paddingRight: 0,
      },
    },
    messageHelperText: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    messageLengthText: {
      color: 'rgba(0, 0, 0, 0.4)',
    },
    privacyLink: {
      color: 'black',
      fontWeight: 700,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline !important',
      },
    },
    textInput: {
      marginTop: '0px !important',
      marginBottom: '3px !important',
      '& > label': {
        fontSize: '14px',
      },
    },
    secondCheckbox: {
      marginTop: '5px',
      justifyContent: 'center',
    },
    buttonGrid: {
      display: 'flex',
      justifyContent: 'center',
    },
    buttonGridOverlay: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonSmall: {
      height: '40px !important',
    },
    successMessage: {
      marginTop: theme.spacing(3),
    },
    center: {
      textAlign: 'center',
      width: '100%',
    },
    selectionPart: {
      paddingBottom: Distances.HALF_FIELD_20,
    },
    activeButton: {
      backgroundColor: theme.palette.accentGreen.main,
      color: `${theme.palette.white.main} !important`,
      '&:hover': {
        backgroundColor: '#516665',
      },
    },
    inactiveButton: {
      backgroundColor: 'white',
      border: '1px solid #BABABA',
      color: `${theme.palette.darkGrey.main} !important`,
      fontWeight: 200,
      '&:hover': {
        backgroundColor: 'white',
      },
    },
    buttonSubmit: {
      margin: '0',
      marginTop: Distances.HALF_FIELD_20,
      marginBottom: Distances.HALF_FIELD_20,
      marginLeft: Distances.HALF_FIELD_20,
      '& > span  > .MuiTypography-root': {
        display: 'block',
        alignSelf: 'center',
      },
    },
    dialog: {
      '& .MuiDialog-paper': {
        overflow: 'visible',
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: '12px',
        minWidth: '600px',
        maxWidth: '840px',
        minHeight: '200px',
        width: '66vw',
        '@media (max-width: 600px)': {
          minWidth: 'auto !important',
          width: '100vw !important',
        },
      },
    },
    dialogGrid: {
      marginLeft: Distances.HALF_FIELD_30,
      marginRight: Distances.HALF_FIELD_30,
      marginTop: Distances.HALF_FIELD_30,
    },
    checkBox: {
      margin: '10px',
    },
    questionIcon: {
      position: 'absolute',
      marginTop: '-36px',
    },
    pictureWrapper: {
      justifyContent: 'center',
    },
  })
);

export default useStyles;
