import axios from '../httpClient';
import countryRegionData, { Country } from 'country-region-data';
import Partner, {
  RoleConfig,
  NewPartnerApplication,
  NewPartnerMember,
  PartnerMember,
  Role,
  Scope,
  UserPartner,
} from '../../models/partner';
import {
  authScopes,
  getCurrentAccount,
  acquireTokenSilentOrRefreshToken,
} from '../auth';
import { PostPartnerDtoOut } from './dtos/post-partner/post-partners-dto-out';
import { GetPartnerByIdDtoOut } from './dtos/get-partner-by-id/get-partner-by-id-dto-out';
import PutPartnerDtoOut from './dtos/put-partner/put-partner-dto-out';
import PutPartnerDtoIn from './dtos/put-partner/put-partner-dto-in';
import { GetPartnersUsersDtoOut } from './dtos/get-partners-users/get-partners-users-dto-out';
import PostPartnerDtoIn from './dtos/post-partner/post-partner-dto-in';
import { PartnerFields } from '../../dynamic-pages/dashboard/partner-details/PartnerForm';
import { convertLogo } from '../../util/util';
import { SecurityLog } from '../../models/security-log';
import { GetPartnerSecurityLogs } from './dtos/get-partner-security-logs/get-partner-by-id-dto-out';
import { appConfiguration } from '../../configuration';
import { MemberStatus } from '../../models/enums';
import { PostPartnerApplicationDtoOut } from './dtos/post-partner-application/post-partner-application-dto-out';

class PartnerService {
  private baseUrl: string;
  private supportedCountries = [
    'DE',
    'AT',
    'RO',
    'CH',
    'US',
    'GB',
    'BE',
    'FR',
    'ES',
    'SE',
  ];

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async createPartner(
    partnerFields: PartnerFields,
    contactName: string,
    contactEmail: string
  ): Promise<Partner> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    const postPartnerDtoIn = PostPartnerDtoIn.toDto(
      partnerFields,
      contactName,
      contactEmail,
      await convertLogo(partnerFields.logo as FileList)
    );

    return PostPartnerDtoOut.fromDto(
      (
        await axios.post(`${this.baseUrl}/partners`, postPartnerDtoIn, {
          headers: { Authorization: `Bearer ${authResult.idToken}` },
        })
      ).data
    );
  }

  async updatePartner(
    partnerId: string | undefined,
    partnerFields: PartnerFields,
    contactName: string,
    contactEmail: string
  ): Promise<Partner> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    const putPartnersDtoIn = PutPartnerDtoIn.toDto(
      partnerFields,
      contactName,
      contactEmail,
      await convertLogo(partnerFields.logo as FileList)
    );

    return PutPartnerDtoOut.fromDto(
      (
        await axios.put(
          `${this.baseUrl}/partners/${partnerId}`,
          putPartnersDtoIn,
          {
            headers: { Authorization: `Bearer ${authResult.idToken}` },
          }
        )
      ).data
    );
  }

  async getUserPartners(): Promise<UserPartner[]> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return (
      await axios.get(`${this.baseUrl}/partners`, {
        headers: { Authorization: `Bearer ${authResult.idToken}` },
      })
    ).data.map(GetPartnersUsersDtoOut.fromDto);
  }

  async getPartnerById(partnerId: string): Promise<Partner> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return GetPartnerByIdDtoOut.fromDto(
      (
        await axios.get(`${this.baseUrl}/partners/${partnerId}`, {
          headers: { Authorization: `Bearer ${authResult.idToken}` },
        })
      ).data
    );
  }

  async addNewMember(
    partnerId: string,
    member: NewPartnerMember
  ): Promise<PartnerMember> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return (
      await axios.post(
        `${this.baseUrl}/partners/${partnerId}/members`,
        member,
        {
          headers: { Authorization: `Bearer ${authResult.idToken}` },
        }
      )
    ).data;
  }

  async removeMember(
    partnerId: string,
    memberId: string,
    withdrawEmail: string | undefined
  ): Promise<PartnerMember> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });
    return (
      await axios.delete(
        `${this.baseUrl}/partners/${partnerId}/members/${memberId || ''}`,
        {
          headers: {
            Authorization: `Bearer ${authResult.idToken}`,
            'mp-withdraw-email': withdrawEmail,
          },
        }
      )
    ).data;
  }

  async updateMember(
    partnerId: string,
    member: PartnerMember
  ): Promise<PartnerMember> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return (
      await axios.put(
        `${this.baseUrl}/partners/${partnerId}/members/${member.user.id}`,
        member.roles,
        {
          headers: { Authorization: `Bearer ${authResult.idToken}` },
        }
      )
    ).data;
  }

  async addNewApplication(
    partnerId: string,
    application: NewPartnerApplication
  ): Promise<PostPartnerApplicationDtoOut> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return (
      await axios.post(
        `${this.baseUrl}/partners/${partnerId}/applications`,
        application,
        { headers: { Authorization: `Bearer ${authResult.idToken}` } }
      )
    ).data;
  }

  async getPartnerSecurityLogs(partnerId: string): Promise<SecurityLog[]> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return (
      (
        await axios.get(`${this.baseUrl}/partners/security-logs/${partnerId}`, {
          headers: { Authorization: `Bearer ${authResult.idToken}` },
        })
      ).data as GetPartnerSecurityLogs
    ).securityLogs;
  }

  getSupportedCountries(): Country[] {
    return countryRegionData.filter((c) =>
      this.supportedCountries.includes(c.countryShortCode)
    );
  }

  getPartnerRoles(role: Role, productIds?: string[]): RoleConfig[] {
    switch (role) {
      case Role.MEMBER:
      case Role.OWNER:
        return [{ role: role, scope: Scope.PARTNER }];
      case Role.MAINTAINER:
        return [
          {
            role: role,
            scope: Scope.PRODUCT,
            entityIds: productIds,
          },
        ];
    }
  }

  async processInvitation(
    partnerId: string,
    email: string
  ): Promise<MemberStatus> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return (
      await axios.post(
        `${this.baseUrl}/partners/${partnerId}/members/invitation`,
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${authResult.idToken}`,
            email: email,
          },
        }
      )
    ).data;
  }
}

export const partnerService = new PartnerService(
  appConfiguration()?.coreApiUrl ?? ''
);
