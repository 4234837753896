import { mapKeys, mapValues } from 'lodash';
import { Reducer } from 'redux';
import { Subscription } from '../../models/subscription';
import {
  SubscriptionsActions,
  SubscriptionsActionTypes,
} from '../actions/subscriptionsActions';

export interface SubscriptionsState {
  subscriptions: { [id: string]: Subscription };
  subscriptionDetailsStatus: {
    [id: string]: {
      loadingPrimaryKey: boolean;
      loadingSecondaryKey: boolean;
      subscriptionKeyLoadError: boolean;
    }
  }
  subscriptionsLoading: boolean;  
  loadingCreateSubscription: boolean;
  subscriptionCreateErrorMessage: string | undefined;
}

const initialSubscriptionsState: SubscriptionsState = {
  subscriptions: {},
  subscriptionDetailsStatus: {},
  subscriptionsLoading: false,
  loadingCreateSubscription: false,
  subscriptionCreateErrorMessage: undefined,
};

export const subscriptionsReducer: Reducer<
  SubscriptionsState,
  SubscriptionsActions
> = (state = initialSubscriptionsState, action) => {
  switch (action.type) {
    case SubscriptionsActionTypes.CREATE_SUBSCRIPTION_SUCCESS: {
      const newSubscription = action.payload;
      state.subscriptions[newSubscription.id] = newSubscription;
      state.subscriptionDetailsStatus[newSubscription.id] = {
        loadingPrimaryKey: false,
        loadingSecondaryKey: false,
        subscriptionKeyLoadError: false
      };
      return {
        ...state,
        subscriptionCreateErrorMessage: undefined,
        loadingCreateSubscription: false,
      };
    }
    case SubscriptionsActionTypes.CREATE_SUBSCRIPTION_FAIL: {
      return {
        ...state,
        subscriptionCreateErrorMessage: action.payload,
      };
    }
    case SubscriptionsActionTypes.LOAD_BY_PARTNERID_SUCCESS: {
      const subscriptions = mapKeys(
        action.payload,
        'id'
      );
      return {
        ...state,
        subscriptions: subscriptions,
        subscriptionDetailsStatus: mapValues(subscriptions, () => ({
          loadingPrimaryKey: false,
          loadingSecondaryKey: false,
          subscriptionKeyLoadError: false
        })),
        subscriptionsLoading: false
      };
    }
    case SubscriptionsActionTypes.SET_SUBSCRIPTIONS_LOADING: {
      return {
        ...state,
        subscriptionsLoading: action.payload,
      };
    }
    case SubscriptionsActionTypes.SET_LOADING_SUBSCRIPTION_CREATE: {
      return {
        ...state,
        loadingCreateSubscription: action.payload,
      };
    }
    case SubscriptionsActionTypes.SET_PRIMARY_KEY_LOADING: {
      const subscriptionStatus = action.payload;
      return {
        ...state,
        subscriptionDetailsStatus: {
          ...state.subscriptionDetailsStatus,
          [subscriptionStatus.subscriptionId]: {
            ...state.subscriptionDetailsStatus[subscriptionStatus.subscriptionId],
            loadingPrimaryKey: subscriptionStatus.loading
          },
        }
      };
    }
    case SubscriptionsActionTypes.SET_SECONDARY_KEY_LOADING: {
      const subscriptionStatus = action.payload;
      return {
        ...state,
        subscriptionDetailsStatus: {
          ...state.subscriptionDetailsStatus,
          [subscriptionStatus.subscriptionId]: {
            ...state.subscriptionDetailsStatus[subscriptionStatus.subscriptionId],
            loadingSecondaryKey: subscriptionStatus.loading
          },
        }
      };
    }
    case SubscriptionsActionTypes.SET_PRIMARY_KEY: {
      const renewResult = action.payload;
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [renewResult.id]: {
            ...state.subscriptions[renewResult.id],
            primaryKey: renewResult.primaryKey,
          },
        }
      };
    }
    case SubscriptionsActionTypes.SET_SECONDARY_KEY: {
      const renewResult = action.payload;
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [renewResult.id]: {
            ...state.subscriptions[renewResult.id],
            secondaryKey: renewResult.secondaryKey,
          },
        }
      };
    }
    case SubscriptionsActionTypes.SET_SUBSCRIPTION: {
      const subscription = action.payload;
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [subscription.id]: subscription,
        },
      };
    }
    case SubscriptionsActionTypes.GET_SUBSCRIPTION_KEYS_SUCCESS: {
      const response = action.payload;
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [response.id]: {
            ...state.subscriptions[response.id],
            primaryKey: response.primaryKey,
            secondaryKey: response.secondaryKey
          },
        }
      };
    }
    case SubscriptionsActionTypes.GET_SUBSCRIPTION_KEYS_ERROR: {
      const response = action.payload;
      return {
        ...state,
        subscriptionDetailsStatus: {
          ...state.subscriptionDetailsStatus,
          [response.subscriptionId]: {
            ...state.subscriptionDetailsStatus[response.subscriptionId],
            subscriptionKeyLoadError: response.isError
          },
        }
      };
    }
    default:
      return state;
  }
};
