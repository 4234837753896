import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '..';
import { MemberStatus } from '../../models/enums';
import Partner, {
  NewPartnerMember,
  PartnerApplication,
  PartnerMember,
  UserPartner,
} from '../../models/partner';
import { SecurityLog } from '../../models/security-log';
import { localService } from '../../services/localService';
import { partnerService } from '../../services/partners/partner-service';
import { setBasicAlert } from './notificationsActions';

export enum PartnerActionTypes {
  LOAD_PARTNERS_OF_USER_SUCCESS = 'user partners loaded',
  LOAD_PARTNER_SUCCESS = 'partner loaded',
  SET_PARTNER_LOADING = 'partner is loading',
  SET_PARTNER_FORM_SUBMISSION_LOADING = 'partner form is submitting',
  SET_PARTNERS_LOADING = 'partners are loading',
  SET_PARTNER_ERROR = 'error occurred in partner state',
  ADD_MEMBER_SUCCESS = 'add member success',
  ADD_MEMBER_LOADING = 'add member is loading',
  ADD_MEMBER_ERROR = 'add member error',
  REMOVE_MEMBER_SUCCESS = 'remove member success',
  REMOVE_MEMBER_LOADING = 'remove member is loading',
  REMOVE_MEMBER_ERROR = 'remove member error',
  UPDATE_MEMBER_ROLE_SUCCESS = 'update member role success',
  UPDATE_MEMBER_ROLE_LOADING = 'update member role is loading',
  UPDATE_MEMBER_ROLE_ERROR = 'update member role error',
  ADD_APPLICATION_SUCCESS = 'add application success',
  ADD_APPLICATION_LOADING = 'add application is loading',
  ADD_APPLICATION_ERROR = 'add application error',
  SET_CURRENT_PARTNER = 'set current partner',
  SET_DASHBOARD_PARTNER_EDIT = 'set dashboard partner edit state',
  SET_DASHBOARD_PARTNER_CREATE = 'set dashboard partner create state',
  SET_PARTNER_SECURITY_LOGS = 'set partner security logs',
  SET_INVITATION_STATUS = 'set invitation status',
  SET_INVITATION_LOADING = 'invitation status loading',
}

interface LoadUserPartnersAction {
  type: PartnerActionTypes;
  payload: UserPartner[];
}

export const setUserPartners = (
  partners: UserPartner[]
): LoadUserPartnersAction => ({
  type: PartnerActionTypes.LOAD_PARTNERS_OF_USER_SUCCESS,
  payload: partners,
});

interface LoadPartnerAction {
  type: PartnerActionTypes;
  payload?: Partner;
}

export const setPartner = (partner?: Partner): LoadPartnerAction => ({
  type: PartnerActionTypes.LOAD_PARTNER_SUCCESS,
  payload: partner,
});

interface SetLoadingPartnerAction {
  type: PartnerActionTypes;
  payload: boolean;
}

const setPartnerLoading = (loading: boolean): SetLoadingPartnerAction => ({
  type: PartnerActionTypes.SET_PARTNER_LOADING,
  payload: loading,
});

interface SetLoadingPartnerFormSubmissionAction {
  type: PartnerActionTypes;
  payload: boolean;
}

export const setPartnerFormSubmissionLoading = (
  loading: boolean
): SetLoadingPartnerFormSubmissionAction => ({
  type: PartnerActionTypes.SET_PARTNER_FORM_SUBMISSION_LOADING,
  payload: loading,
});

interface SetLoadingPartnersAction {
  type: PartnerActionTypes;
  payload: boolean;
}

const setPartnersLoading = (loading: boolean): SetLoadingPartnersAction => ({
  type: PartnerActionTypes.SET_PARTNERS_LOADING,
  payload: loading,
});

interface SetPartnerSecurityLogsAction {
  type: PartnerActionTypes;
  payload: SecurityLog[];
}

const setPartnerSecurityLogs = (
  securityLogs: SecurityLog[]
): SetPartnerSecurityLogsAction => ({
  type: PartnerActionTypes.SET_PARTNER_SECURITY_LOGS,
  payload: securityLogs,
});

interface SetErrorPartnerAction {
  type: PartnerActionTypes;
  payload: string;
}

export const setPartnerError = (
  errorMessage: string
): SetErrorPartnerAction => ({
  type: PartnerActionTypes.SET_PARTNER_ERROR,
  payload: errorMessage,
});

interface SetCurrentPartnerAction {
  type: PartnerActionTypes;
  payload: UserPartner | undefined;
}

export const setCurrentPartner = (
  userPartner?: UserPartner
): SetCurrentPartnerAction => {
  if (userPartner) {
    localService.setCurrentPartner(userPartner);
  }

  return {
    type: PartnerActionTypes.SET_CURRENT_PARTNER,
    payload: userPartner,
  };
};

interface SetCurrentDashboardPartnerAction {
  type: PartnerActionTypes;
  payload: boolean;
}

const setDashboardPartnerCreateState = (
  isCreateMode: boolean
): SetCurrentDashboardPartnerAction => {
  return {
    type: PartnerActionTypes.SET_DASHBOARD_PARTNER_CREATE,
    payload: isCreateMode,
  };
};

export const setDashboardPartnerCreate: ActionCreator<
  ThunkAction<Promise<any>, AppState, null, LoadPartnerAction>
> = (isCreateMode: boolean) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setDashboardPartnerCreateState(isCreateMode));
    } catch (err) {
      console.error(err);
    }
  };
};

export const loadPartner: ActionCreator<
  ThunkAction<Promise<any>, AppState, null, LoadPartnerAction>
> = (partnerId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setPartnerLoading(true));
      const partner = await partnerService.getPartnerById(partnerId);
      dispatch(setPartner(partner));
    } catch (err) {
      console.error(err);
    }
  };
};

interface LoadPartnerSecurityLogsAction {
  type: PartnerActionTypes;
}

export const loadPartnerSecurityLogs: ActionCreator<
  ThunkAction<Promise<any>, AppState, null, LoadPartnerSecurityLogsAction>
> = (partnerId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const securityLogs = await partnerService.getPartnerSecurityLogs(
        partnerId
      );
      dispatch(setPartnerSecurityLogs(securityLogs));
    } catch (err) {
      console.error(err);
    }
  };
};

interface AddMemberSuccessAction {
  type: PartnerActionTypes;
  payload: PartnerMember;
}

const addMemberSuccess = (member: PartnerMember): AddMemberSuccessAction => ({
  type: PartnerActionTypes.ADD_MEMBER_SUCCESS,
  payload: member,
});

interface AddMemberLoadingAction {
  type: PartnerActionTypes;
  payload: boolean;
}

const addMemberLoading = (loading: boolean): AddMemberLoadingAction => ({
  type: PartnerActionTypes.ADD_MEMBER_LOADING,
  payload: loading,
});

interface AddMemberErrorAction {
  type: PartnerActionTypes;
  payload: string;
}

export const addMemberError = (errorMessage: string): AddMemberErrorAction => ({
  type: PartnerActionTypes.ADD_MEMBER_ERROR,
  payload: errorMessage,
});

export const addMember: ActionCreator<
  ThunkAction<Promise<any>, AppState, null, AddMemberSuccessAction>
> = (partnerId: string, newMember: NewPartnerMember, callback: () => void) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(addMemberLoading(true));
      const member = await partnerService.addNewMember(partnerId, newMember);
      dispatch(addMemberSuccess(member));
      callback();
    } catch (err: any) {
      dispatch(addMemberError(err?.response?.data?.message));
      console.error(err.response);
    }
  };
};

interface AddApplicationSuccessAction {
  type: PartnerActionTypes;
  payload: PartnerApplication;
}

export const addApplicationSuccess = (
  application: PartnerApplication
): AddApplicationSuccessAction => ({
  type: PartnerActionTypes.ADD_APPLICATION_SUCCESS,
  payload: application,
});

interface AddApplicationLoadingAction {
  type: PartnerActionTypes;
  payload: boolean;
}

export const addApplicationLoading = (
  loading: boolean
): AddApplicationLoadingAction => ({
  type: PartnerActionTypes.ADD_APPLICATION_LOADING,
  payload: loading,
});

interface AddApplicationErrorAction {
  type: PartnerActionTypes;
  payload: string;
}

export const addApplicationError = (
  errorMessage: string
): AddApplicationErrorAction => ({
  type: PartnerActionTypes.ADD_APPLICATION_ERROR,
  payload: errorMessage,
});

interface RemoveMemberSuccessAction {
  type: PartnerActionTypes;
  payload: {
    userId: string;
    email: string;
  };
}

const removeMemberSuccess = (
  removedMemberId: string,
  removedMemberEmail: string
): RemoveMemberSuccessAction => ({
  type: PartnerActionTypes.REMOVE_MEMBER_SUCCESS,
  payload: {
    userId: removedMemberId,
    email: removedMemberEmail,
  },
});

interface RemoveMemberLoadingAction {
  type: PartnerActionTypes;
  payload: string;
}

const removeMemberLoading = (
  removedMemberEmail: string
): RemoveMemberLoadingAction => ({
  type: PartnerActionTypes.REMOVE_MEMBER_LOADING,
  payload: removedMemberEmail,
});

interface RemoveMemberErrorAction {
  type: PartnerActionTypes;
  payload: string;
}

const removeMemberError = (errorMessage: string): RemoveMemberErrorAction => ({
  type: PartnerActionTypes.REMOVE_MEMBER_ERROR,
  payload: errorMessage,
});

export const removeMember: ActionCreator<
  ThunkAction<Promise<any>, AppState, null, RemoveMemberSuccessAction>
> = (
  partnerId: string,
  memberId: string,
  memberEmail: string,
  withdrawEmail: string | undefined
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(removeMemberLoading(memberEmail));
      await partnerService.removeMember(partnerId, memberId, withdrawEmail);
      dispatch(removeMemberSuccess(memberId, memberEmail));
    } catch (err: any) {
      dispatch(removeMemberError(err.message));
      console.error(err);
    }
  };
};

interface UpdateMemberRoleSuccessAction {
  type: PartnerActionTypes;
  payload: PartnerMember;
}

const updateMemberRoleSuccess = (
  member: PartnerMember
): UpdateMemberRoleSuccessAction => ({
  type: PartnerActionTypes.UPDATE_MEMBER_ROLE_SUCCESS,
  payload: member,
});

interface UpdateMemberRoleLoadingAction {
  type: PartnerActionTypes;
  payload: boolean;
}

const updateMemberRoleLoading = (
  loading: boolean
): UpdateMemberRoleLoadingAction => ({
  type: PartnerActionTypes.UPDATE_MEMBER_ROLE_LOADING,
  payload: loading,
});

interface UpdateMemberRoleErrorAction {
  type: PartnerActionTypes;
  payload: string;
}

export const updateMemberRoleError = (
  errorMessage: string
): UpdateMemberRoleErrorAction => ({
  type: PartnerActionTypes.UPDATE_MEMBER_ROLE_ERROR,
  payload: errorMessage,
});

export const updateMemberRole: ActionCreator<
  ThunkAction<Promise<any>, AppState, null, UpdateMemberRoleSuccessAction>
> = (partnerId: string, updatedMember: PartnerMember, callback: () => void) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updateMemberRoleLoading(true));
      const member = await partnerService.updateMember(
        partnerId,
        updatedMember
      );
      dispatch(updateMemberRoleSuccess(member));
      callback();
    } catch (err: any) {
      dispatch(updateMemberRoleError(err.response?.data?.message || err));
      console.error(err.response);
    }
  };
};

export const loadUserPartners: ActionCreator<
  ThunkAction<Promise<any>, AppState, null, LoadUserPartnersAction>
> = (currentPartnerId?: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setPartnersLoading(true));
      const partners = await partnerService.getUserPartners();
      dispatch(setUserPartners(partners));
      if (partners && partners.length === 0) {
        dispatch(setPartnerLoading(false));
      } else {
        const wantedPartnerId =
          currentPartnerId || localService.getCurrentPartner()?.id;

        const currentPartner = wantedPartnerId
          ? partners.find((p) => p.id === wantedPartnerId) || partners[0]
          : partners[0];

        if (currentPartner) {
          dispatch(setCurrentPartner(currentPartner));
          dispatch(setPartnerLoading(false));
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
};

interface SetInvitationStatusAction {
  type: PartnerActionTypes;
  payload: MemberStatus;
}

interface MemberInvitationLoadingAction {
  type: PartnerActionTypes;
  payload: boolean;
}

const setInvitationLoading = (
  loading: boolean
): MemberInvitationLoadingAction => ({
  type: PartnerActionTypes.SET_INVITATION_LOADING,
  payload: loading,
});

const setInvitationStatus = (
  invitationStatus: MemberStatus
): SetInvitationStatusAction => ({
  type: PartnerActionTypes.SET_INVITATION_STATUS,
  payload: invitationStatus,
});

export const handleMemberInvitationAccepted: ActionCreator<
  ThunkAction<Promise<any>, AppState, null, SetInvitationStatusAction>
> = (partnerId: string, email: string, currentEmail: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setInvitationLoading(true));

      const decodedEmail = decodeURIComponent(email);
      if (decodedEmail.toLowerCase() !== currentEmail.toLowerCase()) {
        dispatch(
          setBasicAlert({
            messageKey: 'wrongUserInvitation',
            severity: 'error',
          })
        );
        dispatch(setInvitationStatus(MemberStatus.NOT_FOUND));
        return;
      }
      const status = await partnerService.processInvitation(
        partnerId,
        decodedEmail
      );
      dispatch(setInvitationStatus(status));
      if (status === MemberStatus.NOT_FOUND) {
        dispatch(
          setBasicAlert({
            messageKey: 'invitationExpiredMessage',
            severity: 'error',
          })
        );
      }
      dispatch(setInvitationLoading(false));
    } catch (err) {
      console.error(err);
      dispatch(
        setBasicAlert({
          messageKey: 'generalErrorMessage',
          severity: 'error',
        })
      );
      dispatch(setInvitationStatus(MemberStatus.NOT_FOUND));
    }
  };
};

export type PartnerActions =
  | LoadUserPartnersAction
  | LoadPartnerAction
  | SetLoadingPartnerAction
  | SetLoadingPartnerFormSubmissionAction
  | SetErrorPartnerAction
  | AddMemberSuccessAction
  | AddMemberLoadingAction
  | AddMemberErrorAction
  | RemoveMemberSuccessAction
  | RemoveMemberLoadingAction
  | RemoveMemberErrorAction
  | UpdateMemberRoleSuccessAction
  | UpdateMemberRoleLoadingAction
  | UpdateMemberRoleErrorAction
  | AddApplicationSuccessAction
  | AddApplicationLoadingAction
  | AddApplicationErrorAction
  | SetCurrentPartnerAction
  | SetCurrentDashboardPartnerAction
  | SetPartnerSecurityLogsAction
  | SetInvitationStatusAction
  | MemberInvitationLoadingAction;
