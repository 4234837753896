import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() =>
  createStyles({
    loadingWrapper: {
      margin: 'auto',
      '& > div': {
        display: 'flex',
      },
    },
  })
);
export default useStyles;
