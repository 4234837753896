import axios from 'axios';
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';

axios.defaults.timeout = 120_000;

axiosRetry(axios, {
  retries: 0,
  shouldResetTimeout: true,
  // Retry delay: 1=2s 2=4s 3=8s
  retryDelay: (retryCount) => {
    return Math.pow(2, retryCount) * 1000;
  },
  // Retry on
  // Http verbs: GET, HEAD, OPTIONS, PUT, DELETE
  // Http statuses: [500-599] or canceled requests (ex. timeouts)
  retryCondition: (error) => {
    return (
      isNetworkOrIdempotentRequestError(error) || error.code === 'ECONNABORTED'
    );
  },
});

export default axios;
