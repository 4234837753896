import { mapKeys } from 'lodash';
import { Reducer } from 'redux';
import { NotificationReadStatus } from '../../models/enums';
import { NotificationDataType } from '../../models/userNotification';
import {
  UserNotificationsActions,
  UserNotificationsActionTypes,
} from '../actions/userNotificationsActions';

export interface UserNotificationState {
  notifications: { [id: string]: NotificationDataType };
  numberOfUnreadNotifications: number;
  currentPage: number;
  hasMorePages: boolean;
  numberOfNotificationsInLastBatch: number;
}

const initialState: UserNotificationState = {
  notifications: {},
  numberOfUnreadNotifications: 0,
  currentPage: 0,
  hasMorePages: true,
  numberOfNotificationsInLastBatch: 0,
};

export const userNoticationsReducer: Reducer<
  UserNotificationState,
  UserNotificationsActions
> = (state = initialState, action) => {
  switch (action.type) {
    case UserNotificationsActionTypes.LOAD_FIRST_BATCH_OF_NOTIFICATIONS_SUCCESS: {
      const newNotifications = mapKeys(action.data, 'id');
      return {
        ...state,
        notifications: newNotifications,
        currentPage: state.currentPage + 1,
        numberOfNotificationsInLastBatch: action.data.length,
        hasMorePages: true,
      };
    }
    case UserNotificationsActionTypes.LOAD_BATCH_OF_NOTIFICATIONS_SUCCESS: {
      const newNotifications = mapKeys(action.data, 'id');
      return {
        ...state,
        notifications: { ...state.notifications, ...newNotifications },
        currentPage: state.currentPage + 1,
        numberOfNotificationsInLastBatch: action.data.length,
      };
    }
    case UserNotificationsActionTypes.NOTIFICATION_RECEIVED: {
      const newNotification = (action.data as unknown) as NotificationDataType;
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [newNotification.id]: newNotification,
        },
        numberOfUnreadNotifications: state.numberOfUnreadNotifications + 1,
      };
    }
    case UserNotificationsActionTypes.GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS: {
      return {
        ...state,
        numberOfUnreadNotifications: action.data,
      };
    }
    case UserNotificationsActionTypes.UPDATE_HAS_MORE_PAGES: {
      return {
        ...state,
        hasMorePages: (action.data as unknown) as boolean,
      };
    }
    case UserNotificationsActionTypes.UPDATE_CURRENT_PAGE_NUMBER: {
      return {
        ...state,
        currentPage: action.data,
      };
    }
    case UserNotificationsActionTypes.READ_ALL_NOTIFICATIONS_SUCCESS: {
      const notifications = state.notifications;
      for (const key of Object.keys(notifications)) {
        notifications[key].readStatus = NotificationReadStatus.READ;
      }

      return {
        ...state,
        numberOfUnreadNotifications: 0,
        currentPage: 0,
        notifications: notifications,
      };
    }
    case UserNotificationsActionTypes.REMOVE_NOTIFICATION_BY_ID: {
      const notifications = {
        ...state.notifications,
      };
      delete notifications[action.data];

      return {
        ...state,
        notifications: notifications,
      };
    }
    default:
      return state;
  }
};
