import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  partnerCurrentPartner,
  userSelector,
} from '../../../../store/selectors';
import { Link } from 'react-router-dom';
import routes from '../../../../routes';
import {
  Menu,
  MenuItem,
  Divider,
  Typography,
  Box,
  Button,
} from '@mui/material';
import useStyles from './UserMenu.styles';

interface UserMenuProps {
  logout: () => void;
}

const UserMenu = ({ logout }: UserMenuProps) => {
  const classes = useStyles();
  const user = useSelector(userSelector);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const partner = useSelector(partnerCurrentPartner);

  const showMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    logout();
  };

  return !!user ? (
    <Box component="span">
      <Button
        className={classes.profileButton}
        aria-label="account of current user"
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={showMenu}
        color="inherit"
        data-testid="user-menu"
      >
        <img
          className={classes.profileImage}
          src="/img/profile1.png"
          alt="profile"
        />
        <div className={classes.profilePicturePlaceholder}>
          <span>
            {user.firstName?.[0] || '?'}
            {user.lastName?.[0] || '?'}
          </span>
        </div>
        <Typography className={classes.profileName} data-cy="username-text">
          {user.firstName} {user.lastName}
        </Typography>
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        disableScrollLock={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem className={classes.menuItem}>
          <Typography
            color="textSecondary"
            variant="header3"
            fontWeight="regular"
          >
            {user.email}
          </Typography>
        </MenuItem>
        {partner ? (
          <MenuItem className={classes.menuItem}>
            <Typography
              color="textSecondary"
              variant="header3"
              fontWeight="regular"
            >
              {partner?.companyName}
            </Typography>
          </MenuItem>
        ) : (
          <span />
        )}
        <Divider></Divider>
        <MenuItem
          component={Link}
          to={routes.profile}
          onClick={handleClose}
          className={classes.displayNone}
        >
          <Typography variant="header3" fontWeight="regular">
            {t('profile')}
          </Typography>
        </MenuItem>
        <MenuItem
          component={Link}
          to={routes.dashboard.base}
          onClick={handleClose}
        >
          <Typography variant="header3" fontWeight="regular">
            {t('dashboard')}
          </Typography>
        </MenuItem>
        <Divider></Divider>
        <MenuItem
          onClick={handleLogout}
          data-testid="logout"
          data-cy="logout-button"
        >
          <Typography variant="header3" fontWeight="regular">
            {t('logout')}
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  ) : (
    <div></div>
  );
};

export default UserMenu;
