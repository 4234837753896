import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '..';
import { SubscriptionCoreUsage } from '../../models/subscriptionUsages';
import { subscriptionUsagesService } from '../../services/usage/usage-service';

export enum SubscriptionsUsagesActionTypes {
  LOAD_USAGE = '[SubscriptionsUsages] Load usages by subscriptions',
  LOAD_USAGE_SUCCESS = '[SubscriptionsUsages] Load usages by subscriptions success',
  LOAD_USAGE_FAIL = '[SubscriptionsUsages] Load usages by subscriptions fail',
  SET_USAGE_LOADING = '[SubscriptionsUsages] Usages by subscriptions are loading',
}

interface loadUsageAction {
  type: typeof SubscriptionsUsagesActionTypes.LOAD_USAGE;
}

export const loadUsage: ActionCreator<
  ThunkAction<Promise<any>, AppState, null, loadUsageAction>
> = (startDate: Date, endDate: Date) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      const currentPartner = getState().partner.currentPartner;
      if (!currentPartner) {
        dispatch(loadUsageFail('Current partner not loaded'));
        return;
      }
      dispatch(setUsagesloading(true));
      const usages =
        await subscriptionUsagesService.getUsagesWithinIntervalForPartner(
          currentPartner.id,
          startDate,
          endDate
        );
      dispatch(loadUsageSuccess(usages));
    } catch (err) {
      dispatch(loadUsageFail(err));
    }
  };
};

interface loadUsageSuccessAction {
  type: typeof SubscriptionsUsagesActionTypes.LOAD_USAGE_SUCCESS;
  payload: SubscriptionCoreUsage[];
}

const loadUsageSuccess = (
  payload: SubscriptionCoreUsage[]
): loadUsageSuccessAction => ({
  type: SubscriptionsUsagesActionTypes.LOAD_USAGE_SUCCESS,
  payload,
});

interface loadUsageFailAction {
  type: typeof SubscriptionsUsagesActionTypes.LOAD_USAGE_FAIL;
  payload: any;
}

const loadUsageFail = (payload: any): loadUsageFailAction => ({
  type: SubscriptionsUsagesActionTypes.LOAD_USAGE_FAIL,
  payload,
});

interface SetUsagesloadingAction {
  type: typeof SubscriptionsUsagesActionTypes.SET_USAGE_LOADING;
  payload: boolean;
}

const setUsagesloading = (payload: boolean): SetUsagesloadingAction => ({
  type: SubscriptionsUsagesActionTypes.SET_USAGE_LOADING,
  payload,
});

export type SubscriptionsUsagesActions =
  | loadUsageAction
  | loadUsageSuccessAction
  | loadUsageFailAction
  | SetUsagesloadingAction;
