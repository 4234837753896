import { cloneDeep } from 'lodash';
import { Reducer } from 'redux';
import { ProductApiSpecification } from '../../models/productApiSpecification';
import {
  ProductsApiSpecificationActions,
  ProductsApiSpecificationActionTypes,
} from '../actions/productsApiSpecificationActions';

export interface ProductsApiSpecificationState {
  productsApiSpecification: { [productId: string]: ProductApiSpecification | string };
  loading: boolean;
}

const initialProductsApiSpecificationState: ProductsApiSpecificationState = {
  productsApiSpecification: {},
  loading: false,
};

export const productsApiSpecificationReducer: Reducer<
  ProductsApiSpecificationState,
  ProductsApiSpecificationActions
> = (state = initialProductsApiSpecificationState, action) => {
  switch (action.type) {
    case ProductsApiSpecificationActionTypes.LOAD_PRODUCT_API_SPECIFICATION_BY_PRODUCT_ID_SUCCESS: {
      return {
        ...state,
        productsApiSpecification: cloneDeep({
          ...state.productsApiSpecification,
          [action.payload.productId]: action.payload.data,
        }),
        loading: false,
      };
    }
    case ProductsApiSpecificationActionTypes.SET_LOADING: {
      return {
        ...state,
        loading: (action.payload as unknown) as boolean,
      };
    }
    default:
      return state;
  }
};
