import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ApiType } from '../../models/enums';
import { ProductApiSpecification } from '../../models/productApiSpecification';
import { productsService } from '../../services/products/products-service';
import { ProductsApiSpecificationState } from '../reducers/productsApiSpecificationReducer';

export enum ProductsApiSpecificationActionTypes {
  LOAD_PRODUCT_API_SPECIFICATION_BY_PRODUCT_ID_SUCCESS = 'Load product api specification by product id success',
  SET_LOADING = 'Product api specification loading',
}

interface SetSpecificationLoadingAction {
  type: typeof ProductsApiSpecificationActionTypes.SET_LOADING;
  payload: boolean;
}

const setProductApiSpecificationLoading = (loading: boolean) => ({
  type: ProductsApiSpecificationActionTypes.SET_LOADING,
  payload: loading,
});

interface LoadProductApiSpecificationByProductIdSuccessAction {
  type: typeof ProductsApiSpecificationActionTypes.LOAD_PRODUCT_API_SPECIFICATION_BY_PRODUCT_ID_SUCCESS;
  payload: {
    productId: string;
    data: ProductApiSpecification | string;
  };
}

const loadProductApiSpecificationByProductIdSuccess = (
  productId: string,
  data: ProductApiSpecification | string
): LoadProductApiSpecificationByProductIdSuccessAction => ({
  type: ProductsApiSpecificationActionTypes.LOAD_PRODUCT_API_SPECIFICATION_BY_PRODUCT_ID_SUCCESS,
  payload: {
    productId,
    data,
  },
});

export const loadProductApiSpecificationByProductId: ActionCreator<
  ThunkAction<
    Promise<any>,
    ProductsApiSpecificationState,
    null,
    LoadProductApiSpecificationByProductIdSuccessAction
  >
> = (productId: string, apiType: ApiType) => {
  return async (dispatch: Dispatch, getState) => {
    if (
      productId &&
      !Object.keys(
        getState().productsApiSpecification.productsApiSpecification
      ).includes(productId)
    ) {
      try {
        dispatch(setProductApiSpecificationLoading(true));

        const apiSpecification =
          apiType !== ApiType.CUSTOM && apiType !== ApiType.NONE
            ? await productsService.getProductApiSpecificationByProductId(
                productId,
                apiType
              )
            : ({} as ProductApiSpecification);
        dispatch(
          loadProductApiSpecificationByProductIdSuccess(
            productId,
            apiSpecification
          )
        );
      } catch (err) {
        dispatch(
          loadProductApiSpecificationByProductIdSuccess(
            productId,
            {} as ProductApiSpecification
          )
        );
        console.error(err);
      }
    }
  };
};

export type ProductsApiSpecificationActions =
  | SetSpecificationLoadingAction
  | LoadProductApiSpecificationByProductIdSuccessAction;
