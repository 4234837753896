import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Distances } from '../../../../AppTheme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      backgroundColor: 'transparent !important',
      cursor: 'default',
    },
    profileButton: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '1000px',
      textTransform: 'none',
      paddingLeft: '10px',
      paddingRight: '10px',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      height: '36px',
    },
    profileImage: {
      borderRadius: '50%',
      border: '2px solid white',
      marginRight: theme.spacing(1),
      width: '21px',
      height: '21px',
      overflow: 'auto',
      backgroundColor: 'white',
      display: 'none',
    },
    profilePicturePlaceholder: {
      width: '21px',
      height: '21px',
      backgroundColor: 'rgba(255, 255, 255, 0.25)',
      fontSize: '11px',
      borderRadius: '13px',
      marginRight: '8px',
      color: '#FFFFFF',
      padding: '1px 0px',
    },
    profileName: {
      fontSize: '13px',
      lineHeight: Distances.HALF_FIELD_20,
      color: 'white',
    },
    displayNone: {
      display: 'none',
    },
  })
);
export default useStyles;
