import { appConfiguration } from '../../configuration';
import { SERVICE_SPECIFICATION_VALIDATION_TIMEOUT } from '../../constants/constants';
import { isProductNew } from '../../models-helpers/product-helper';
import {
  ApiType,
  Category,
  ProductAction,
  SpecFormat,
} from '../../models/enums';
import { Product } from '../../models/product';
import { ProductApiSpecification } from '../../models/productApiSpecification';
import {
  authScopes,
  getCurrentAccount,
  acquireTokenSilentOrRefreshToken,
} from '../auth';
import axios from '../httpClient';
import { GetProductBusinessDataDtoOut } from './dtos/get-product-business-data/get-product-business-data-dto-out';
import { GetProductByIdDtoOut } from './dtos/get-product-by-id/get-product-by-id-dto-out';
import { GetProductSearchResultDtoOut } from './dtos/get-product-search/get-product-search-dto-out';
import { GetProductDtoOut } from './dtos/get-products-all/get-products-all-dto-out';
import { PatchWebappProductActionsDtoIn } from './dtos/patch-product-action/patch-product-action-dto-in';
import { PatchWebappProductActionsDtoOut } from './dtos/patch-product-action/patch-product-action-dto-out';
import { PatchWebappProductBusinessInformationDtoIn } from './dtos/patch-product-business-information/patch-product-business-information-dto-in';
import { PatchWebappProductBusinessInformationDtoOut } from './dtos/patch-product-business-information/patch-product-business-information-dto-out';
import { PatchProductFilesDtoIn } from './dtos/patch-product-files/patch-product-files-in';
import { PatchWebappProductFilesDtoOut } from './dtos/patch-product-files/patch-product-files-out';
import { PostProductDtoIn } from './dtos/post-product-dto/post-product-dto-in';
import { PostProductDtoOut } from './dtos/post-product-dto/post-product-dto-out';
import { PostValidateSpecificationFileDtoIn } from './dtos/post-validate-specification-file/post-validate-specification-file-dto-in';

class ProductsService {
  private baseUrl: string;
  private apimBaseUrl: string;

  constructor(baseUrl: string, apimBaseUrl: string) {
    this.baseUrl = baseUrl;
    this.apimBaseUrl = apimBaseUrl;
  }

  async getAllProducts(): Promise<Product[]> {
    return (await axios.get(`${this.baseUrl}/products`)).data?.map(
      GetProductDtoOut.fromDto
    );
  }

  async getProductById(id: string): Promise<Product> {
    return GetProductByIdDtoOut.fromDto(
      (await axios.get(`${this.baseUrl}/products/${id}`)).data
    );
  }

  async getProductBusinessDataById(
    productId: string,
    partnerId: string
  ): Promise<GetProductBusinessDataDtoOut> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return (
      await axios.get(`${this.baseUrl}/product-business-data/${productId}`, {
        headers: {
          Authorization: `Bearer ${authResult.idToken}`,
          'partner-id': partnerId,
        },
      })
    ).data;
  }

  async searchProducts(
    searchString: string,
    category: string
  ): Promise<GetProductSearchResultDtoOut[]> {
    return (
      await axios.get(
        `${this.baseUrl}/products/search?searchString=${searchString}${
          category && category !== Category.ALL_PRODUCTS
            ? `&category=${category}`
            : ''
        }`
      )
    ).data;
  }

  async getProductApiSpecificationByProductId(
    productId: string,
    apiType: ApiType
  ): Promise<ProductApiSpecification | string> {
    return (
      await axios.get(
        `${this.apimBaseUrl}/product/${productId}/specification`,
        {
          params: {
            format:
              ApiType.SOAP === apiType ? SpecFormat.WSDL : SpecFormat.OPENAPI,
          },
        }
      )
    ).data;
  }

  async validateSpecificationFile(
    specification: PostValidateSpecificationFileDtoIn,
    partnerId: string
  ): Promise<any> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return axios.post(
      `${this.apimBaseUrl}/specification-file/validate`,
      specification,
      {
        headers: {
          Authorization: `Bearer ${authResult.idToken}`,
          'partner-id': partnerId,
        },
        timeout: SERVICE_SPECIFICATION_VALIDATION_TIMEOUT,
      }
    );
  }

  async createNewEmptyProduct(
    productPayload: PostProductDtoIn
  ): Promise<PostProductDtoOut> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    const response = axios.post(`${this.baseUrl}/products`, productPayload, {
      headers: {
        Authorization: `Bearer ${authResult.idToken}`,
      },
    });

    return (await response).data;
  }

  async patchProduct(
    productId: string,
    partnerId: string,
    draftProductPayload: PatchWebappProductBusinessInformationDtoIn
  ): Promise<PatchWebappProductBusinessInformationDtoOut> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    const response = axios.patch(
      `${this.baseUrl}/products/${productId}/information`,
      draftProductPayload,
      {
        headers: {
          Authorization: `Bearer ${authResult.idToken}`,
          'partner-id': partnerId,
        },
      }
    );

    return (await response).data;
  }

  async publishProduct(
    productId: string,
    partnerId: string
  ): Promise<PatchWebappProductActionsDtoOut> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });
    const productPayload: PatchWebappProductActionsDtoIn = {
      action: ProductAction.PUBLISH,
    };

    const response = await axios.patch(
      `${this.baseUrl}/products/${productId}/actions`,
      productPayload,
      {
        headers: {
          Authorization: `Bearer ${authResult.idToken}`,
          'partner-id': partnerId,
        },
      }
    );

    return response.data;
  }

  async submitForReview(productId: string, partnerId: string) {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });
    const productPayload: PatchWebappProductActionsDtoIn = {
      action: ProductAction.SEND_TO_REVIEW,
    };

    const response = await axios.patch(
      `${this.baseUrl}/products/${productId}/actions`,
      productPayload,
      {
        headers: {
          Authorization: `Bearer ${authResult.idToken}`,
          'partner-id': partnerId,
        },
      }
    );

    return response.data;
  }

  async updateProductFiles(
    productId: string,
    partnerId: string,
    files: PatchProductFilesDtoIn
  ): Promise<PatchWebappProductFilesDtoOut> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });
    return (
      await axios.patch(`${this.baseUrl}/products/${productId}/files`, files, {
        headers: {
          Authorization: `Bearer ${authResult.idToken}`,
          'partner-id': partnerId,
        },
      })
    ).data;
  }

  setNewFlagOnProduct(product: Product) {
    if (isProductNew(product)) {
      product.isNew = true;
    } else {
      product.isNew = false;
    }
  }

  setNewFlagOnAllProducts(products: Product[]) {
    products.forEach((product) => {
      this.setNewFlagOnProduct(product);
    });
  }
}

export const productsService = new ProductsService(
  appConfiguration()?.coreApiUrl ?? '',
  appConfiguration()?.apimApiUrl ?? ''
);
