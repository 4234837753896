import { DAYS_UNTIL_PRODUCT_IS_CONSIDERED_NEW } from '../constants/constants';
import { ContractualDocuments } from '../models/contractual-documents';
import { Industry } from '../models/enums';
import { UserPartner } from '../models/partner';
import Plan from '../models/plan';
import { Product } from '../models/product';
import { numberOfDaysBetweenTimestamps } from '../util/date-time';

export const determineProductTabs = (
  productData: Product | any,
  currentPartner?: UserPartner
) => {
  const productTabs = ['details'];

  if (productData) {
    const hasPrices =
      productData.plans &&
      (productData.plans as Plan[]).some((plan) => {
        return (
          !plan.privacy?.private ||
          (currentPartner?.id &&
            plan.privacy.partners?.includes(currentPartner.id))
        );
      })
        ? true
        : false;
    const hasApiSpecification = productData.files?.specificationFileUrl
      ? true
      : false;

    const hasUseCases =
      productData.useCases &&
      (productData.useCases.introduction ||
        productData.useCases.useCasesUrls.find((url: string) => url !== null))
        ? true
        : false;
    const hasDocumentation =
      productData.documentation &&
      (productData.documentation.introduction ||
        productData.documentation.documentationUrls.find(
          (url: string) => url !== null
        ))
        ? true
        : false;
    if (hasUseCases) {
      productTabs.push('useCases');
    }
    if (hasDocumentation) {
      productTabs.push('documentation');
    }
    if (hasApiSpecification) {
      productTabs.push('apiSpecification');
    }
    if (hasPrices) {
      productTabs.push('pricing');
    }
  }

  productTabs.push('contact');

  return productTabs;
};

export const isProductNew = (product: Product) => {
  if (product.publishedAt !== undefined) {
    const currentDate = Date.now();
    const nrDays = numberOfDaysBetweenTimestamps(
      currentDate,
      product.publishedAt
    );

    if (nrDays <= DAYS_UNTIL_PRODUCT_IS_CONSIDERED_NEW) {
      return true;
    }
  }
  return false;
};

export const computeExistingFilesUrls = (
  fileUrls?: ContractualDocuments | null,
  additionalDocumentsUrl?: string
): string[] => {
  const existingFilesUrls = [];
  if (fileUrls?.dataProcessingSpecificationUrl) {
    existingFilesUrls.push(fileUrls.dataProcessingSpecificationUrl);
  }
  if (fileUrls?.serviceLevelAgreementUrl) {
    existingFilesUrls.push(fileUrls.serviceLevelAgreementUrl);
  }
  if (fileUrls?.termsAndConditionsUrl) {
    existingFilesUrls.push(fileUrls.termsAndConditionsUrl);
  }
  if (additionalDocumentsUrl) {
    existingFilesUrls.push(additionalDocumentsUrl);
  }
  return existingFilesUrls;
};

export const generateIndustryTranslationKey = (
  industry: Industry | string
): string => {
  return `INDUSTRY_${industry}`;
};

export const sortByNewAndAlph = (products: Product[]): Product[] => {
  const newProds: Product[] = [];
  const oldProds: Product[] = [];
  products.forEach(function (a) {
    if (a.isNew === true) {
      newProds.push(a);
    } else {
      oldProds.push(a);
    }
  });
  newProds.sort((a, b) => {
    if (a?.publishedAt && b?.publishedAt) {
      if (a.publishedAt < b.publishedAt) {
        return 1;
      } else if (a.publishedAt > b.publishedAt) return -1;
      else return 0;
    } else {
      return 0;
    }
  });
  oldProds.sort((a, b) => {
    if (a?.displayName && b?.displayName) {
      const result = a.displayName.localeCompare(b.displayName);
      if (typeof result === undefined) {
        return 0;
      } else return result;
    } else return 0;
  });
  return newProds.concat(oldProds);
};
