import { PartnerFields } from '../../../../dynamic-pages/dashboard/partner-details/PartnerForm';
import { AttachmentDto } from '../../../../dtos/attachment';
import { PartnerMember } from '../../../../models/partner';

export default class PutPartnerDtoIn {
  companyName: string;
  displayName?: string;
  billingAddress: string;
  billingCity: string;
  billingPostalCode: string;
  billingCountry: string;
  homePage?: string;
  taxNumber?: string;
  vatNumber?: string;
  contactName: string;
  contactEmail: string;
  members?: PartnerMember[];
  logo: AttachmentDto;
  shortDescription: string;

  static toDto(
    data: PartnerFields,
    contactName: string,
    contactEmail: string,
    logo: AttachmentDto
  ): PutPartnerDtoIn {
    return {
      ...data,
      contactName: contactName,
      contactEmail: contactEmail,
      logo: logo,
    };
  }
}
