import { AvailabilityStatus, MemberStatus } from '../models/enums';
import { Role } from '../models/partner';
import { AppState } from './index';

// User selectors
export const userSelector = (state: AppState) => state.auth?.user;
export const tokenAcquiredSelector = (state: AppState) =>
  state.auth.tokenAcquired;

// Product selectors
export const productsSelector = (state: AppState) => state.products.products;
export const productSelector = (id: string, state: AppState) => {
  return id
    ? state.products.product && state.products.product.id === id
      ? state.products.product
      : undefined
    : undefined;
};
export const productsLoadingSelector = (state: AppState) =>
  state.products.loading;
export const productsResponseSelector = (state: AppState) =>
  state.products.loadProductErrorMessage;

// Dashboard Products selector
export const dashboardProductsSelector = (state: AppState) =>
  state.dashboardProducts.products;
export const dashboardProductsLoadingSelector = (state: AppState) =>
  state.dashboardProducts.loading;
export const dashboardProductsErrorSelector = (state: AppState) =>
  state.dashboardProducts.error;

// Dashboard Products selector
export const dashboardStatisticsSelector = (state: AppState) =>
  state.dashboardStatistics.statistics;
export const dashboardStatisticsLoadingSelector = (state: AppState) =>
  state.dashboardStatistics.loading;

// Payment method selectors
export const sepaBankAccountsSelector = (state: AppState) =>
  state.paymentMethods.bankAccounts;
export const creditCardsSelector = (state: AppState) =>
  state.paymentMethods.cards;
export const paymentMethodsLoadingSelector = (state: AppState) =>
  state.paymentMethods.loading;
export const selectedPaymentMethodSelector = (state: AppState) =>
  state.paymentMethods.selectedPaymentMethod;

// Subscription selectors
export const subscriptionsSelector = (state: AppState) =>
  state.subscriptions.subscriptions;
export const subscriptionsLoadingSelector = (state: AppState) =>
  state.subscriptions.subscriptionsLoading;
export const subscriptionCreateLoadingSelector = (state: AppState) =>
  state.subscriptions.loadingCreateSubscription;
export const subscriptionPrimaryKeyLoadingSelector = (
  subscriptionId: string
) => {
  return (state: AppState) =>
    state.subscriptions.subscriptionDetailsStatus[subscriptionId]
      .loadingPrimaryKey;
};
export const subscriptionSecondaryKeyLoadingSelector = (
  subscriptionId: string
) => {
  return (state: AppState) =>
    state.subscriptions.subscriptionDetailsStatus[subscriptionId]
      .loadingSecondaryKey;
};
export const getSubscriptionKeysErrorSelector = (subscriptionId: string) => {
  return (state: AppState) =>
    state.subscriptions.subscriptionDetailsStatus[subscriptionId]
      .subscriptionKeyLoadError;
};
export const subscriptionCreateErrorMessage = (state: AppState) =>
  state.subscriptions.subscriptionCreateErrorMessage;

// Partner
export const partnerSelector = (state: AppState) => state.partner.partner;
export const partnerLoadingSelector = (state: AppState) =>
  state.partner.loadingPartner;
export const partnerFormSubmissionLoadingSelector = (state: AppState) =>
  state.partner.loadingPartnerFormSubmission;
export const partnersLoadingSelector = (state: AppState) =>
  state.partner.loadingPartners;
export const partnerErrorSelector = (state: AppState) => state.partner.error;
export const addMemberLoadingSelector = (state: AppState) =>
  state.partner.addMemberLoading;
export const addMemberErrorSelector = (state: AppState) =>
  state.partner.addMemberError;
export const removeMemberLoadingSelector = (state: AppState) =>
  state.partner.removeMemberEmail;
export const removeMemberErrorSelector = (state: AppState) =>
  state.partner.removeMemberError;
export const updateMemberRoleLoadingSelector = (state: AppState) =>
  state.partner.updateMemberRoleLoading;
export const updateMemberRoleErrorSelector = (state: AppState) =>
  state.partner.updateMemberRoleError;
  export const addApplicationLoadingSelector = (state: AppState) =>
    state.partner.addApplicationLoading;
  export const addApplicationErrorSelector = (state: AppState) =>
    state.partner.addApplicationError;
export const hasOwnerRoleSelector = (state: AppState) => {
  if (state && state.auth && state.auth.user) {
    const userId = state.auth.user.id;
    const member =
      state.partner.partner &&
      state.partner.partner.members &&
      state.partner.partner.members.find((member) => member.user.id === userId);
    return (
      member &&
      member.roles[0].role === Role.OWNER &&
      member.status === MemberStatus.ACTIVE
    );
  } else {
    return false;
  }
};
export const hasMaintainerRoleSelector = (state: AppState) => {
  if (state && state.auth && state.auth.user) {
    const userId = state.auth.user.id;
    const member =
      state.partner.partner &&
      state.partner.partner.members &&
      state.partner.partner.members.find((member) => member.user.id === userId);
    return (
      member &&
      member.roles[0].role === Role.MAINTAINER &&
      member.status === MemberStatus.ACTIVE
    );
  } else {
    return false;
  }
};

export const hasMaintainableProductsSelector = (state: AppState) => {
  if (state && state.auth && state.auth.user) {
    let hasServices = false;
    const currentPartner = state.partner.currentPartner;
    if (currentPartner) {
      const currentUserPartner =
        state.partner.userPartners &&
        state.partner.userPartners.find(
          (partner) => partner.id === currentPartner.id
        );
      hasServices = !!currentPartner.hasServices;
      if (
        currentUserPartner &&
        (currentUserPartner.roles[0].role === Role.MAINTAINER ||
          currentUserPartner.roles[0].role === Role.OWNER) &&
        hasServices
      )
        return true;
    }
  }
  return false;
};

export const isPartnerDisabledSelector = (state: AppState) => {
  return (
    state.partner.currentPartner?.availabilityStatus ===
    AvailabilityStatus.DISABLED
  );
};
export const partnerCurrentPartner = (state: AppState) =>
  state.partner.currentPartner;
export const userPartnersSelector = (state: AppState) =>
  state.partner.userPartners;
export const partnerDashboardCreateState = (state: AppState) =>
  state.partner.dashboardPartnerCreateMode;
export const partnerSecurityLogs = (state: AppState) =>
  state.partner.securityLogs;
export const invitationStatusSelector = (state: AppState) =>
  state.partner.invitationStatus;

// Subscriptions Usages selector
export const usagesBySubscriptionsSelector = (state: AppState) =>
  state.subscriptionsUsages.usagesBySubscriptions;
export const usagesLoadingSelector = (state: AppState) =>
  state.subscriptionsUsages.loading;
export const usagesByProductsSelector = (state: AppState) =>
  state.subscriptionsUsages.usagesByProducts;

// Subscriptions Costs selector
export const costsBySubscriptionsSelector = (state: AppState) =>
  state.subscriptionsCosts.costsBySubscriptions;
export const costsLoadingSelector = (state: AppState) =>
  state.subscriptionsCosts.loading;
export const costsByProductsSelector = (state: AppState) =>
  state.subscriptionsCosts.costsByProducts;

// Api Specifications selectors
export const specificationByProductIdSelector = (
  productId: string,
  state: AppState
) => {
  return productId
    ? state.productsApiSpecification.productsApiSpecification[productId]
    : undefined;
};

// Notifications
export const notificationsSignalRAlertSelector = (state: AppState) =>
  state.notifications.signalRAlert;
export const notificationsBasicAlertSelector = (state: AppState) =>
  state.notifications.basicAlert;

// User notifications
export const notificationsSelector = (state: AppState) =>
  state.userNotifications.notifications;
export const notificationsNumberSelector = (state: AppState) =>
  state.userNotifications.numberOfNotificationsInLastBatch;
export const notificationsUnreadSelector = (state: AppState) =>
  state.userNotifications.numberOfUnreadNotifications;
export const notificationCurrentPageSelector = (state: AppState) =>
  state.userNotifications.currentPage;
export const notificationhasMorePagesSelector = (state: AppState) =>
  state.userNotifications.hasMorePages;
