import { createContext } from 'react';

type GlobalCookieContent = {
  statisticCookie: boolean;
  setStatisticCookieContext: (c: boolean) => void;
};
export const CookieContext = createContext<GlobalCookieContent>({
  statisticCookie: false,
  setStatisticCookieContext: () => {
    //this is the initialization of the Context. intentional empty function
  },
});
