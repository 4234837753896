import { AuthenticatedTemplate } from '@azure/msal-react';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Container,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { NUMBER_OF_NOTIFICATIONS_IN_ONE_BATCH } from '../../constants/constants';
import { useTranslation } from 'react-i18next';
import { supportedLngs } from '../../i18n';
import routes from '../../routes';
import {
  archiveUserNotification,
  loadBatchOfUserNotifications,
  markAllNotificationsAsRead,
  updateHasMorePages,
} from '../../store/actions/userNotificationsActions';
import {
  notificationCurrentPageSelector,
  notificationhasMorePagesSelector,
  notificationsNumberSelector,
  notificationsSelector,
  notificationsUnreadSelector,
  partnerCurrentPartner,
  partnerDashboardCreateState,
} from '../../store/selectors';
import { openContact } from '../contact-form/ContactFormOverlay';
import LanguageMenu from '../language-menu/LanguageMenu';
import LoadingIndicator from '../loading/Loading';
import useStyles from './NavigationBar.styles';
import MPNotificationCard from './notification/MPNotificationCard';
import SignInSignUp from './signin-signup/SignInSignUp';
import { getLocalPicturePath } from '../../configuration/local-dev-utils';
import {
  setCurrentPartner,
  setDashboardPartnerCreate,
} from '../../store/actions/partnerActions';
import { localService } from '../../services/localService';

export const NavigationBar: React.FC = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentPartner = useSelector(partnerCurrentPartner);
  const currentPage = useSelector(notificationCurrentPageSelector);
  const hasMoreNotifications = useSelector(notificationhasMorePagesSelector);
  const numberOfNotificationsInLastBatch = useSelector(
    notificationsNumberSelector
  );
  const dashboardPartnerCreate = useSelector(partnerDashboardCreateState);

  const handleClickOutsideNotifications = () => {
    if (isNotificationCenterOpen) {
      setIsNotificationCenterOpen(false);
      markNotificationsAsRead();
    }
  };

  const mobileMenuId = 'app-menu-mobile';
  const location = useLocation();
  const route = location.pathname;

  const noSidebarDisplayed =
    location.pathname.includes('/checkout') ||
    location.pathname.includes('/service/') ||
    location.pathname.includes(routes.dashboard.base);

  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const [backIconPath, setBackIconPath] = useState<string>();
  const [backNavigationPath, setBackNavigationPath] = useState<string>('');
  const [backNavigationText, setBackNavigationText] = useState<string>('');

  useEffect(() => {
    setBackIconPath(
      location.pathname.includes(routes.dashboard.base)
        ? '/icons/sidebar/all_solutions_red.svg'
        : '/icons/arrow_left_yellow.svg'
    );
    setBackNavigationText(
      location.pathname.includes(routes.dashboard.base)
        ? 'goToAllSolutions'
        : 'backAllSolutions'
    );
    setBackNavigationPath((previous) => {
      if (
        location.pathname.includes('/service') ||
        location.pathname.includes('/checkout')
      ) {
        if (previous) {
          return previous;
        } else {
          return routes.productCatalog;
        }
      } else {
        if (location.pathname.includes(routes.dashboard.base)) {
          return routes.productCatalog;
        } else {
          if (location.search) {
            return location.pathname + location.search;
          } else {
            return location.pathname;
          }
        }
      }
    });
  }, [location]);

  const isMobileMenuOpen = Boolean(mobileMenuAnchorEl);

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  const handleLanguageItemClick = async (index: number) => {
    setSelectedIndex(index);
    await i18n.changeLanguage(supportedLngs[index].key);
  };

  const defaultLanguageIndex = supportedLngs.findIndex(
    (l) => l.key === i18n.language
  );
  const [selectedIndex, setSelectedIndex] = useState(defaultLanguageIndex);

  const [isNotificationCenterOpen, setIsNotificationCenterOpen] =
    useState(false);

  const numberOfUndreadNotifications = useSelector(notificationsUnreadSelector);

  const userNotifications = Object.values(
    useSelector(notificationsSelector)
  ).sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

  const markNotificationsAsRead = () => {
    if (!currentPartner) {
      return;
    }

    if (isNotificationCenterOpen && userNotifications.length >= 1) {
      dispatch(
        markAllNotificationsAsRead(
          currentPartner.id,
          userNotifications[0].createdAt
        )
      );
    }
  };

  const handleNotifCenterDisplay = () => {
    if (currentPartner) {
      markNotificationsAsRead();

      if (!isNotificationCenterOpen) {
        dispatch(updateHasMorePages(true));
      }
    }

    setIsNotificationCenterOpen(!isNotificationCenterOpen);
  };

  const dismissNotification = (notificationId: string) => {
    if (currentPartner) {
      dispatch(archiveUserNotification(currentPartner.id, notificationId));
    }
  };

  const fetchMoreData = () => {
    if (currentPartner && currentPartner.id) {
      if (
        numberOfNotificationsInLastBatch < NUMBER_OF_NOTIFICATIONS_IN_ONE_BATCH
      ) {
        dispatch(updateHasMorePages(false));
      }
      dispatch(loadBatchOfUserNotifications(currentPartner.id, currentPage));
    }
    return;
  };

  const onCancelPartnerCreation = () => {
    if (dashboardPartnerCreate) {
      dispatch(setDashboardPartnerCreate(false));
      const previousPartner = localService.getCurrentPartner();
      if (previousPartner) {
        dispatch(setCurrentPartner(previousPartner));
      }
    }
  };

  return (
    <>
      <AppBar className={classes.appBar}>
        <Container className={classes.container}>
          <Toolbar disableGutters className={classes.toolbar}>
            <Hidden mdDown>
              <Grid container className={classes.leftAndRight}>
                <Grid item xs={6}>
                  {noSidebarDisplayed && (
                    <Box className={classes.leftPart}>
                      <IconButton
                        component={Link}
                        to={backNavigationPath}
                        className={classes.backButtonIcon}
                        size="large"
                        onClick={() => {
                          onCancelPartnerCreation();
                        }}
                      >
                        <img src={backIconPath} alt="arrow_left_yellow" />
                      </IconButton>
                      <Typography
                        variant="text"
                        component={Link}
                        to={backNavigationPath}
                        className={classes.backButtonLink}
                        onClick={() => {
                          onCancelPartnerCreation();
                        }}
                      >
                        {t(backNavigationText)}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Box className={classes.rightPart}>
                    <Box component="span" className={classes.languageMenu}>
                      <LanguageMenu />
                    </Box>
                    <Box component="span">
                      <SignInSignUp />
                    </Box>
                    <AuthenticatedTemplate>
                      <IconButton
                        aria-label="notification-bell"
                        color="inherit"
                        data-testid="notification-bell"
                        onClick={handleNotifCenterDisplay}
                        className={classes.notificationButton}
                        size="large"
                      >
                        <Box component="span">
                          <Badge
                            classes={{ badge: classes.notificationBadge }}
                            badgeContent={numberOfUndreadNotifications}
                          >
                            <Box
                              component="span"
                              className={classes.notificationBellIcon}
                            >
                              <img
                                src="/icons/notifications_bell.png"
                                alt="Notifications bell"
                              />
                            </Box>
                          </Badge>
                        </Box>
                      </IconButton>
                    </AuthenticatedTemplate>
                  </Box>
                </Grid>
              </Grid>
            </Hidden>

            <Hidden mdUp>
              <Button
                component={Link}
                to={routes.home}
                className={classes.logoButton}
              >
                <img
                  src={getLocalPicturePath('/img/logo_72dpi.png')}
                  height={20}
                  alt="msg.Marketplace Logo"
                />
              </Button>

              <IconButton
                aria-label="open menu"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
                size="large"
              >
                <MenuIcon />
              </IconButton>

              <Menu
                anchorEl={mobileMenuAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={mobileMenuId}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={handleMobileMenuClose}
              >
                <SignInSignUp />
                <Divider />
                <MenuItem
                  component={Link}
                  to={routes.productCatalog}
                  className={
                    route === routes.productCatalog
                      ? classes.activeMenuButton
                      : ''
                  }
                >
                  {t('productsAndServices')}
                </MenuItem>
                {/* <MenuItem
                  component={Link}
                  to={routes.ourVision}
                  className={`${
                    route === routes.ourVision ? classes.activeMenuButton : ''
                  }`}
                >
                  {t('about')}
                </MenuItem> // will be used again later*/}
                <AuthenticatedTemplate>
                  <MenuItem
                    component={Link}
                    to={routes.dashboard.base}
                    data-cy="dashboard-button"
                    className={`${
                      route.startsWith(routes.dashboard.base)
                        ? classes.activeMenuButton
                        : ''
                    }`}
                  >
                    {t('dashboard')}
                  </MenuItem>
                </AuthenticatedTemplate>
                <Divider />
                <MenuItem onClick={() => openContact()}>
                  {t('contactUs')}
                </MenuItem>
                {supportedLngs.map((option, index) => (
                  <MenuItem
                    key={option.key}
                    selected={index === selectedIndex}
                    onClick={async () => await handleLanguageItemClick(index)}
                  >
                    {option.text}
                  </MenuItem>
                ))}
              </Menu>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>

      {isNotificationCenterOpen && (
        <ClickAwayListener onClickAway={handleClickOutsideNotifications}>
          <Box className={classes.notificationCenter} id="notification-center">
            {userNotifications.length > 0 ? (
              <>
                <Grid container>
                  <Grid item xl={11} lg={11} md={11}>
                    <Typography
                      className={classes.notificationCenterTitle}
                      variant="h5"
                    >
                      {t('notificationCenter')}
                    </Typography>
                  </Grid>
                  <Grid item xl={1} lg={1} md={1}>
                    <IconButton
                      aria-label="notification-setting"
                      color="inherit"
                      data-testid="notification-setting"
                      className={classes.settingIcon}
                      size="large"
                    >
                      <Box component="span">
                        <img src="/icons/settings_icon.png" alt="Settings" />
                      </Box>
                    </IconButton>
                  </Grid>
                  <Box className={classes.notificationBox}>
                    <InfiniteScroll
                      dataLength={userNotifications.length}
                      next={fetchMoreData}
                      hasMore={hasMoreNotifications}
                      loader={
                        <Box display="flex" height="50px" width="100%">
                          <LoadingIndicator />
                        </Box>
                      }
                      className={classes.infiniteScroll}
                      scrollableTarget="notification-center"
                    >
                      {Object.values(userNotifications).map(
                        (userNotification) => (
                          <MPNotificationCard
                            key={userNotification.id}
                            userNotification={userNotification}
                            onClick={handleNotifCenterDisplay}
                            onDismiss={dismissNotification}
                          />
                        )
                      )}
                    </InfiniteScroll>
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                <Grid container>
                  <Grid item xl={11} lg={11} md={11}>
                    <Typography
                      className={classes.notificationCenterTitle}
                      variant="h5"
                    >
                      {t('notificationCenter')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      className={classes.emptyNotificationCenter}
                      variant="text"
                    >
                      {t('noNotificationsYet')}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </ClickAwayListener>
      )}
    </>
  );
};
