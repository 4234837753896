import { AccountInfo } from '@azure/msal-browser';

export enum AuthActionTypes {
  LOGIN_SUCCESS = '[auth] Login success',
  AUTO_LOGIN = '[auth] Auto login',
  LOGOUT_SUCCESS = '[auth] Logout success',
  TOKEN_ACQUIRED = '[auth] Token acquired',
}

interface LoginSuccessAction {
  type: AuthActionTypes;
  payload: AccountInfo;
}

export const loginSuccess = (payload: AccountInfo): LoginSuccessAction => ({
  type: AuthActionTypes.LOGIN_SUCCESS,
  payload,
});

interface AutoLoginAction {
  type: AuthActionTypes;
  payload: AccountInfo;
}

export const autoLogin = (payload: AccountInfo): AutoLoginAction => ({
  type: AuthActionTypes.AUTO_LOGIN,
  payload,
});

interface LogoutSuccessAction {
  type: AuthActionTypes;
}

export const logoutSuccess = (): LogoutSuccessAction => ({
  type: AuthActionTypes.LOGOUT_SUCCESS,
});

interface TokenAcquiredAction {
  type: AuthActionTypes;
}
export const tokenAcquired = (): TokenAcquiredAction => ({
  type: AuthActionTypes.TOKEN_ACQUIRED,
});
