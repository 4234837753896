import { DashboardProduct } from '../../../../models/dashboard-product';
import {
  AvailabilityStatus,
  Category,
  ProductStatus,
  ProductType,
  SubscriptionStatus,
} from '../../../../models/enums';
import { ProductStatistics } from '../../../../models/productStatistics';

export class GetPartnersProductsAllDtoOut {
  /**
   * @member {string} [id]
   */
  id: string;

  /**
   * @member {string} [productType]
   */
  productType: ProductType;

  /**
   * @member {string} [displayName]
   */
  displayName: string;

  /**
   * @member {Category[]} [categories]
   */
  categories: Category[];

  /**
   * @member {ProductStatistics} [statistics]
   */
  statistics?: ProductStatistics;

  /**
   * @member {ProductStatus} [status]
   */
  status: ProductStatus;

  /**
   * @member {AvailabilityStatus} [providerAvailabilityStatus]
   */
  providerAvailabilityStatus?: AvailabilityStatus;

  /**
   * @member {string} [providerId]
   */
  providerId: string;

  /**
   * @member {AvailabilityStatus} [productAvailabilityStatus]
   */
  productAvailabilityStatus?: AvailabilityStatus;

  /**
   * @member {ProductSubscription[]} [subscriptions]
   */
  subscriptions: ProductSubscription[];

  /**
   * @member {boolean} [internal]
   */
  internal?: boolean;

  static fromDto(
    getPartnersProductsAllDtoOut: GetPartnersProductsAllDtoOut
  ): DashboardProduct {
    return getPartnersProductsAllDtoOut;
  }
}

interface ProductSubscription {
  /**
   * @member {string} [id]
   */
  id: string;

  /**
   * @member {string} [productId]
   */
  productId: string;

  /**
   * @member {string} [partnerId]
   */
  partnerId: string;

  /**
   * @member {string} [partnerName]
   */
  partnerName: string;

  /**
   * @member {SubscriptionStatus} [subscriptionStatus]
   */
  subscriptionStatus: SubscriptionStatus;

  /**
   * @description Average latency of the API calls of this subscription
   */
  latencyAvg?: number;

  /**
   * @description Average success rate of the API calls of this subscription
   */
  successRateAvg?: number;
}
