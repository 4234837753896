import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.onscroll = () => {
      window.scrollTo({
        top: 0,
      });
    };
    setTimeout(() => {
      window.onscroll = () => {
        //this is intentional, but try removing the onscroll, and scrolling in the app :D 
      };
    }, 30);
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  }, [pathname]);

  return null;
}
