import { UserPartner } from '../models/partner';

const CURRENT_PARTNER = 'currentPartner';

class LocalService {
  setCurrentPartner(partner: UserPartner) {
    localStorage.setItem(CURRENT_PARTNER, JSON.stringify(partner));
  }
  getCurrentPartner(): UserPartner | undefined {
    const partnerJson = localStorage.getItem(CURRENT_PARTNER);
    if (!partnerJson) {
      return undefined;
    }

    return JSON.parse(partnerJson);
  }
  clearCurrentPartner() {
    localStorage.removeItem(CURRENT_PARTNER);
  }
}

export const localService = new LocalService();
