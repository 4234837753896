import { Reducer } from 'redux';
import {
  PaymentMethodsActionTypes,
  PaymentMethodsActions,
} from '../actions/paymentMethodsActions';
import { SepaBankAccount } from '../../models/sepaBankAccount';
import { mapKeys, omit } from 'lodash';
import { PaymentMethods } from '../../services/payment-methods/payment-methods-service';
import { CreditCard } from '../../models/creditCard';
import { PaymentMethodId } from '../../models/enums';

export interface PaymentMethodsState {
  cards: { [cardId: string]: CreditCard };
  bankAccounts: { [accountId: string]: SepaBankAccount };
  selectedPaymentMethod: string;
  loading: boolean;
}

const initialPaymentMethodsState: PaymentMethodsState = {
  cards: {},
  bankAccounts: {},
  selectedPaymentMethod: PaymentMethodId.INVOICE_PAYMENT_ID,
  loading: true,
};

export const paymentMethodsReducer: Reducer<
  PaymentMethodsState,
  PaymentMethodsActions
> = (state = initialPaymentMethodsState, action) => {
  switch (action.type) {
    case PaymentMethodsActionTypes.LOAD_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        cards: mapKeys((action.payload as PaymentMethods).cards, 'id'),
        bankAccounts: mapKeys(
          (action.payload as PaymentMethods).bankAccounts,
          'id'
        ),
      };
    }
    case PaymentMethodsActionTypes.DELETE_SUCCESS: {
      const deletedPaymentMethodId = action.payload as unknown as string;

      return {
        ...state,
        loading: false,
        cards: omit(state.cards, deletedPaymentMethodId),
        bankAccounts: omit(state.bankAccounts, deletedPaymentMethodId),
      };
    }
    case PaymentMethodsActionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.payload as unknown as boolean,
      };
    }
    case PaymentMethodsActionTypes.SET_SELECTED_PAYMENT_METHOD: {
      return {
        ...state,
        selectedPaymentMethod: action.payload as unknown as string,
      };
    }
    default:
      return state;
  }
};
