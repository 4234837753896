import axios from '../httpClient';
import { CreditCard } from '../../models/creditCard';
import { SepaBankAccount } from '../../models/sepaBankAccount';
import {
  authScopes,
  getCurrentAccount,
  acquireTokenSilentOrRefreshToken,
} from '../auth';
import { DeletePartnersPaymentMethodsDeduplicateDtoOut } from './dtos/delete-partners-payment-methods-deduplicate/delete-partners-payment-methods-deduplicate-dto-out';
import { GetPartnersPaymentMethodsDtoOut } from './dtos/get-partners-payment-methods-all/get-partners-payment-methods-all-dto-out';
import { PostPartnersPaymentMethodsDtoOut } from './dtos/post-partners-payment-methods/post-partners-payment-methods-dto-out';
import { appConfiguration } from '../../configuration';

export interface PaymentMethods {
  cards: CreditCard[];
  bankAccounts: SepaBankAccount[];
}

class PaymentMethodsService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async deletePaymentMethod(
    partnerId: string,
    paymentMethodId: string
  ): Promise<undefined> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return await axios.delete(
      `${this.baseUrl}/partners/${partnerId}/payment-methods/${paymentMethodId}`,
      {
        headers: {
          Authorization: `Bearer ${authResult.idToken}`,
        },
      }
    );
  }

  async deletePaymentMethodIfDuplicated(
    partnerId: string,
    paymentMethodId: string
  ): Promise<DeletePartnersPaymentMethodsDeduplicateDtoOut> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return (
      await axios.delete(
        `${this.baseUrl}/partners/${partnerId}/payment-methods/${paymentMethodId}/deduplicate`,
        {
          headers: {
            Authorization: `Bearer ${authResult.idToken}`,
          },
        }
      )
    )?.data.wasDuplicateFound;
  }

  async getAllPaymentMethods(
    partnerId: string
  ): Promise<GetPartnersPaymentMethodsDtoOut> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    const { data } = await axios.get(
      `${this.baseUrl}/partners/${partnerId}/payment-methods`,
      {
        headers: {
          Authorization: `Bearer ${authResult.idToken}`,
        },
      }
    );

    return data;
  }

  async createSetupIntent(
    partnerId: string
  ): Promise<PostPartnersPaymentMethodsDtoOut> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    const { data } = await axios.post(
      `${this.baseUrl}/partners/${partnerId}/payment-methods`,
      {},
      {
        headers: {
          Authorization: `Bearer ${authResult.idToken}`,
        },
      }
    );

    return data;
  }
}

export const paymentMethodsService = new PaymentMethodsService(
  appConfiguration()?.coreApiUrl ?? ''
);
