import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { supportedLngs } from '../../i18n';
import useStyles from './LanguageMenu.styles';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LanguageMenu: React.FC = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const defaultLanguageIndex = supportedLngs.findIndex(
    (l) => l.key === i18n.language
  );
  const [selectedIndex, setSelectedIndex] = useState(defaultLanguageIndex);

  const handleLanguageItemClick = async (index: number) => {
    setSelectedIndex(index);
    await i18n.changeLanguage(supportedLngs[index].key);
  };

  return (
    <Box component="span">
      {supportedLngs.map((_option, index) => (
        <Button
          key={'language-' + index}
          color="inherit"
          className={`${classes.language} ${
            index === selectedIndex ? classes.activeLanguage : ''
          }`}
          onClick={async () => await handleLanguageItemClick(index)}
        >
          {t(supportedLngs[index].text)}
        </Button>
      ))}
    </Box>
  );
};

export default LanguageMenu;
