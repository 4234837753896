import React from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { clsx } from 'clsx';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  NotificationReadStatus,
  NotificationSeverityType,
} from '../../../models/enums';
import NotificationCardProps from './NotificationCardProps';
import useStyles from './MPNotificationCard.styles';
import {
  checkIfNotificationWasCreatedToday,
  convertUnixTimestampToDateAndTime,
  convertUnixTimestampToTime,
} from '../../../util/date-time';
import { determineNotificationSourceURL } from '../../../util/util';

const MPNotificationCard: React.FC<NotificationCardProps> = ({
  userNotification,
  onClick,
  onDismiss,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const formatNotificationDate = (createdAt: number) => {
    if (checkIfNotificationWasCreatedToday(createdAt)) {
      const time = convertUnixTimestampToTime(createdAt);
      return t('today').concat(' | ').concat(time);
    } else {
      return convertUnixTimestampToDateAndTime(createdAt).replace(/, /, ' | ');
    }
  };

  const handleNotificationClick = () => {
    history.push(determineNotificationSourceURL(userNotification));
    onClick();
  };

  const handleNotificationDismiss = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    onDismiss(userNotification.id);
  };

  return (
    <Box
      className={`${
        userNotification.readStatus === NotificationReadStatus.UNREAD
          ? clsx(classes.notificationWrapper, classes.unreadNotificaton)
          : classes.notificationWrapper
      }`}
      onClick={handleNotificationClick}
    >
      <Grid container>
        <Grid item lg={5}>
          <Typography
            className={`${
              userNotification.severityType ===
              NotificationSeverityType.CRITICAL
                ? clsx(
                    classes.notificationLabel,
                    classes.criticalNotificationLabel
                  )
                : userNotification.severityType ===
                  NotificationSeverityType.IMPORTANT
                ? clsx(
                    classes.notificationLabel,
                    classes.importantNotificationLabel
                  )
                : clsx(
                    classes.notificationLabel,
                    classes.informativeNotificationLabel
                  )
            }`}
            variant="text"
          >
            {userNotification.severityType}
          </Typography>
        </Grid>
        <Grid item lg={5}>
          <Typography className={classes.notificationDate} variant="text">
            {formatNotificationDate(userNotification.createdAt)}
          </Typography>
        </Grid>
        <Grid item lg={2}>
          <IconButton
            aria-label="close-notification"
            color="inherit"
            data-testid="close-notification"
            className={classes.closeNotificationIconButton}
            onClick={handleNotificationDismiss}
            size="large"
          >
            <CloseIcon className={classes.closeNotification}></CloseIcon>
          </IconButton>
        </Grid>
      </Grid>
      <Grid container>
        <Typography className={classes.notificationTitle} variant="textBold">
          {t(userNotification.data.title)}
        </Typography>
      </Grid>
      <Grid container>
        <Typography className={classes.notificationDescription} variant="text">
          {t(userNotification.data.description, {
            serviceName: userNotification.data.productName,
            companyName: userNotification.data.partnerName,
          })}
        </Typography>
      </Grid>
    </Box>
  );
};

export default MPNotificationCard;
