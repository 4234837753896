import { BasicAlert, SignalRAlert } from '../reducers/notificationsReducer';

export enum NotificationsActionTypes {
  SET_SIGNALR_ALERT = 'SET_SIGNALR_ALERT',
  CLEAR_SIGNALR_ALERT = 'CLEAR_SIGNALR_ALERT',
  SET_BASIC_ALERT = 'SET_BASIC_ALERT',
  CLEAR_BASIC_ALERT = 'CLEAR_BASIC_ALERT',
}

interface SetAlertAction {
  type: NotificationsActionTypes;
  alert: BasicAlert;
}

export const setSignalRAlert = (alert: SignalRAlert): SetAlertAction => ({
  type: NotificationsActionTypes.SET_SIGNALR_ALERT,
  alert,
});

export const setBasicAlert = (alert: BasicAlert): SetAlertAction => ({
  type: NotificationsActionTypes.SET_BASIC_ALERT,
  alert,
});

interface ClearAlertAction {
  type: NotificationsActionTypes;
}

export const clearSignalRAlert = (): ClearAlertAction => ({
  type: NotificationsActionTypes.CLEAR_SIGNALR_ALERT,
});

export const clearBasicAlert = (): ClearAlertAction => ({
  type: NotificationsActionTypes.CLEAR_BASIC_ALERT,
});

export type NotificationsActions = SetAlertAction;
