import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

export type CheckboxStyleProps = { isGreen: boolean; isSmall?: boolean };

export const useSharedCheckboxStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxInput: (props: CheckboxStyleProps) => ({
      color: 'transparent',
      borderRadius: '3px',
      backgroundColor: 'white',
      border: '1px solid gray',
      height: '10px',
      width: '10px',
      padding: props.isSmall === true ? '8px' : '9px',
      margin: 0,
      '& svg': {
        fontSize: props.isSmall === true ? '1.4rem' : 'inherited',
      },
      '&:hover': {
        backgroundColor: 'white',
      },
      '&.Mui-checked': {
        color:
          props.isGreen === true
            ? theme.palette.accentGreen.main
            : theme.palette.primary.main,
      },
      '&.Mui-disabled': {
        color: theme.palette.grey.A200,
      },
    }),
  })
);
