import axios from '../httpClient';
import { NUMBER_OF_NOTIFICATIONS_IN_ONE_BATCH } from '../../constants/constants';
import { NotificationDataType } from '../../models/userNotification';
import {
  authScopes,
  getCurrentAccount,
  acquireTokenSilentOrRefreshToken,
} from '../auth';
import { appConfiguration } from '../../configuration';

class UserNotificationsService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async getUnreadNotificationsCount(partnerId: string): Promise<number> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return (
      await axios.get(`${this.baseUrl}/notifications/count`, {
        headers: { Authorization: `Bearer ${authResult.idToken}` },
        params: {
          partnerId: partnerId,
        },
      })
    ).data.count;
  }

  async getBatchOfUserNotifications(
    partnerId: string,
    page: number
  ): Promise<NotificationDataType[]> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });
    return (
      await axios.get(`${this.baseUrl}/notifications/`, {
        headers: { Authorization: `Bearer ${authResult.idToken}` },
        params: {
          partnerId: partnerId,
          page: page,
          size: NUMBER_OF_NOTIFICATIONS_IN_ONE_BATCH,
        },
      })
    ).data;
  }

  async markAllNotificationsAsRead(
    partnerId: string,
    latestNotificationTimestamp: number
  ) {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return axios.put(`${this.baseUrl}/notifications/read`, null, {
      headers: {
        Authorization: `Bearer ${authResult.idToken}`,
      },
      params: {
        partnerId: partnerId,
        timestamp: latestNotificationTimestamp,
      },
    });
  }

  async archiveUserNotification(partnerId: string, notificationId: string) {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return axios.put(`${this.baseUrl}/notifications/archive`, null, {
      headers: {
        Authorization: `Bearer ${authResult.idToken}`,
      },
      params: {
        partnerId: partnerId,
        notificationId: notificationId,
      },
    });
  }
}

export const notificationService = new UserNotificationsService(
  appConfiguration()?.coreApiUrl ?? ''
);
