import React from 'react';
// yes there is: export default class Loader
/* eslint-disable import/default*/
import Loader from 'react-loader-spinner';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import useStyles from './Loading.styles';
import { clsx } from 'clsx';

interface LoadingIndicatorProps {
  color?: string;
  width?: number;
  height?: number;
  wrapperClassName?: string;
}

const LoadingIndicator = ({
  color,
  width = 30,
  height = 30,
  wrapperClassName,
}: LoadingIndicatorProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const loaderColor = color || theme.palette.primary.main;

  return (
    <Box className={clsx(classes.loadingWrapper, wrapperClassName)}>
      <Loader
        type="TailSpin"
        color={loaderColor}
        width={width}
        height={height}
      />
    </Box>
  );
};

export default LoadingIndicator;
