declare global {
  interface Window {
    MPConfig: AppConfiguration;
  }
}

interface AppConfiguration {
  coreApiUrl: string;
  billingApiUrl: string;
  apimApiUrl: string;
  stripePublicKey: string;
  picturesCdnUrl: string;
  filesCdnUrl: string;
  friendlyCaptchaSiteKey: string;
  appInsightsInstrumentationKey: string;
  auth: {
    tenantName: string;
    clientId: string;
  };
  flags: {
    serviceCreationAndEditing: boolean;
  };
  env: string;
  local: {
    localePath: string;
    picturePath: string;
  };
  theme: string;
  htmlHeader: string;
}

export const appConfiguration = () => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  return window.MPConfig;
};
