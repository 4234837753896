import React, { Suspense, useState } from 'react';
import { MsalProvider } from '@azure/msal-react';
import {
  ReactPlugin,
  withAITracking,
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  Box,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import './App.css';
import AppContent from './AppContent';
import { theme } from './AppTheme';
import LoadingIndicator from './components/loading/Loading';
import { appConfiguration } from './configuration';
import {
  COOKIE_ACCEPT_ALL,
  COOKIE_ONLY_NECESSARY,
} from './constants/constants';
import { msalPca } from './services/auth';
import { history, store } from './store';
import {
  isCookieUseDisabledByUser,
  setUserPreferenceCookie,
} from './util/cookies/cookies';
import { CookieContext } from './util/global-context';

const reactPlugin = new ReactPlugin();
const appInsights = reactPlugin
  ? new ApplicationInsights({
      config: {
        instrumentationKey: appConfiguration()?.appInsightsInstrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: history },
        },
        isCookieUseDisabled: isCookieUseDisabledByUser(),
        enableAutoRouteTracking: true,
      },
    })
  : undefined;
appInsights?.loadAppInsights();

const App: React.FC = () => {
  const [statisticCookie, setStatisticCookieContext] = useState<boolean>(false);

  const handleSelectedCookies = () => {
    if (statisticCookie) {
      handleAllCookies();
    } else {
      appInsights?.getCookieMgr().setEnabled(false);
      setUserPreferenceCookie(COOKIE_ONLY_NECESSARY);
    }
  };

  const handleAllCookies = () => {
    appInsights?.getCookieMgr().setEnabled(true);
    setUserPreferenceCookie(COOKIE_ACCEPT_ALL);
  };

  return (
    <CookieContext.Provider
      value={{
        statisticCookie,
        setStatisticCookieContext,
      }}
    >
      <Provider store={store}>
        <MsalProvider instance={msalPca}>
          <ConnectedRouter history={history}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <CssBaseline>
                  <Suspense
                    fallback={
                      <Box display="flex" height="100vh">
                        <LoadingIndicator />
                      </Box>
                    }
                  >
                    <AppContent
                      handleSelectedCookies={handleSelectedCookies}
                      handleAllCookies={handleAllCookies}
                    />
                  </Suspense>
                </CssBaseline>
              </ThemeProvider>
            </StyledEngineProvider>
          </ConnectedRouter>
        </MsalProvider>
      </Provider>
    </CookieContext.Provider>
  );
};

export default reactPlugin ? withAITracking(reactPlugin, App) : App;
