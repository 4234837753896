import ContractPeriodTerms from '../../../../models/contract-period-terms.model';
import {
  BillingSystem,
  Interval,
  PurchaseType,
  SubscriptionStatus,
} from '../../../../models/enums';
import { PriceComponentType } from '../../../../models/price.model';
import { Subscription } from '../../../../models/subscription';

export class GetSubscriptionsByPartnerIdDtoOut {
  id: string;
  note: string;
  partnerId: string;
  createdBy: string;
  productId: string;
  productName: string;
  baseURL: string;
  contractPeriodEnd: number;
  subscriptionStatus: SubscriptionStatus;
  plan: {
    id: string;
    name: string;
    contractPeriodTerms?: ContractPeriodTerms;
    purchaseType: PurchaseType;
    sellingPrice: {
      billingCycle?: {
        interval: Interval;
        intervalCount: number;
      };
      currency: string;
      priceComponents: PriceComponentType[];
    };
  };
  productHasDocumentation?: boolean;
  productHasAPI?: boolean;
  hasJwtAuth: boolean;
  billingSystem: BillingSystem;

  static fromDto(dto: GetSubscriptionsByPartnerIdDtoOut): Subscription {
    return {
      ...dto,
    };
  }
}
