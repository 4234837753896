import React from 'react';
import { clsx } from 'clsx';
import { Link } from 'react-router-dom';
import { Button, ButtonProps, Icon, Typography } from '@mui/material';
import { MPButton2BaseProps } from './MPButtonBase';
import useStyles from './MPButtonBase2.styles';

type CustomButtonProps = Omit<ButtonProps, 'variant' | 'color'>;

const MPButton2: React.FC<MPButton2BaseProps & CustomButtonProps> = ({
  styleVariant,
  customClasses,
  children,
  disabled,
  square,
  navigate,
  ...buttonProps
}) => {
  const classes = useStyles();

  return (
    <Button
      {...buttonProps}
      disableRipple
      disabled={disabled}
      startIcon={
        (styleVariant === 'filter' && (
          <Icon
            fontSize="small"
            className={clsx(classes.icon, disabled && classes.disabled)}
          >
            <img src="/icons/filter.svg" alt="filter" />
          </Icon>
        )) ||
        ((styleVariant === 'close' || styleVariant === 'closeFilter') && (
          <Icon
            fontSize="small"
            className={clsx(classes.closeIcon, disabled && classes.disabled)}
          >
            X
          </Icon>
        )) ||
        (styleVariant === 'primaryBack' && (
          <Icon
            fontSize="small"
            className={clsx(classes.icon, disabled && classes.disabled)}
          >
            <img src="/icons/arrow_left.png" alt="arrow_left" />
          </Icon>
        ))
      }
      endIcon={
        (styleVariant === 'primary' && (
          <Icon
            fontSize="small"
            className={clsx(classes.icon, disabled && classes.disabled)}
          >
            <img
              src="/icons/arrow_right.svg"
              alt="arrow_right"
              className={
                disabled ? classes.disabledArrow : classes.enabledArrow
              }
            />
          </Icon>
        )) ||
        (styleVariant === 'selector' && (
          <Icon
            fontSize="small"
            className={clsx(
              classes.icon,
              classes.downwardsArrowIcon,
              disabled && classes.disabled
            )}
          >
            <img
              src="/icons/arrows/arrow_downwards.png"
              alt="arrow_downwards"
            />
          </Icon>
        ))
      }
      className={clsx(
        classes.button,
        disabled && classes.disabled,
        styleVariant === 'primary' && classes.primary,
        styleVariant === 'primaryBack' && classes.primary,
        (styleVariant === 'secondarySmall' ||
          styleVariant === 'secondaryBig' ||
          styleVariant === 'selector') &&
          classes.secondary,
        styleVariant === 'selector' && classes.selector,
        styleVariant === 'secondarySmall' && classes.secondarySmall,
        styleVariant === 'secondaryBig' && classes.secondaryBig,
        styleVariant === 'tertiary' && classes.tertiary,
        styleVariant === 'close' && classes.secondary,
        styleVariant === 'close' && classes.close,
        (styleVariant === 'filter' || styleVariant === 'closeFilter') &&
          classes.filterAndClose,
        styleVariant === 'contactMenu' && classes.contactMenu,
        styleVariant === 'contactForm' && classes.contactForm,
        customClasses,
        square && classes.noBorderRadius
      )}
      component={navigate ? Link : Button}
      to={navigate ? navigate : undefined}
    >
      <Typography
        variant={
          styleVariant === 'filter' ||
          styleVariant === 'closeFilter' ||
          styleVariant === 'contactMenu' ||
          styleVariant === 'contactForm' ||
          styleVariant === 'selector'
            ? styleVariant === 'filter' || styleVariant === 'closeFilter'
              ? 'textBold'
              : 'text'
            : 'buttons'
        }
        className={styleVariant === 'selector' ? classes.selectorTypography : ''}
      >
        {children}
      </Typography>
    </Button>
  );
};

export default MPButton2;
