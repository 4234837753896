import { SubscriptionCoreUsage } from '../../../../models/subscriptionUsages';

export class GetUsageByPartnerIdDtoOut {
  /**
   * @description the id of the subscription in cosmos db
   * @member {string} [subscriptionId]
   */
  subscriptionId: string;

  /**
   * @description the note of the subscription provided by the customer
   * @member {string} [note]
   */
  note: string;

  /**
   * @member {string} [unitLabel]
   */
  unitLabel?: string;

  /**
   * @member {string} [planName]
   */
  planName: string;

  /**
   * @description core product id
   * @member {string} [productId]
   */
  productId: string;

  /**
   * @description name of the subscribed product
   * @member {string} [productName]
   */
  productName: string;

  /**
   * @description the percentage of failed requests
   * @member {number} [errorRate]
   */
  errorRate: number;

  /**
   * @description the total count of requests
   * @member {number} [callCountTotal]
   */
  callCountTotal: number;

  /**
   * @description the average api response time
   * @member {number} [latencyAvg]
   */
  latencyAvg: number;

  /**
   * @description the usage quantity
   * @member {number} [quantity]
   */
  quantity: number;

  /**
   * @description flag that shows if the subscription has jwt consumer auth
   * This flag is used to render/hide usage data in the UI
   * @member {boolean} [hasJwtAuth]
   */
  hasJwtAuth: boolean;

  static fromDto(
    getUsageByPartnerIdDtoOut: GetUsageByPartnerIdDtoOut
  ): SubscriptionCoreUsage {
    return {
      ...getUsageByPartnerIdDtoOut,
    };
  }
}
