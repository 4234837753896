import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { SepaBankAccount } from '../../models/sepaBankAccount';
import {
  paymentMethodsService,
  PaymentMethods,
} from '../../services/payment-methods/payment-methods-service';
import { CreditCard } from '../../models/creditCard';
import { AppState } from '..';
import { partnerService } from '../../services/partners/partner-service';
import { setCurrentPartner, setUserPartners } from './partnerActions';
import { localService } from '../../services/localService';
import { isEmpty } from 'lodash';

export enum PaymentMethodsActionTypes {
  LOAD_ALL_SUCCESS = 'Load all payment methods of an user success',
  DELETE_SUCCESS = 'Delete a payment method from a user',
  SET_SELECTED_PAYMENT_METHOD = 'Set the selected payment method',
  SET_LOADING = 'Payment methods loading',
}

interface LoadAllPaymentMethodsSuccessAction {
  type: PaymentMethodsActionTypes;
  payload: PaymentMethods;
}

const loadAllPaymentMethodsSuccess = (
  bankAccounts: SepaBankAccount[],
  cards: CreditCard[]
): LoadAllPaymentMethodsSuccessAction => ({
  type: PaymentMethodsActionTypes.LOAD_ALL_SUCCESS,
  payload: { bankAccounts, cards },
});

export const loadPaymentMethods: ActionCreator<
  ThunkAction<Promise<any>, AppState, null, LoadAllPaymentMethodsSuccessAction>
> = () => {
  return async (dispatch: Dispatch, getState) => {
    dispatch(setPaymentMethodsLoading(true));
    try {
      const currentPartner = getState().partner.currentPartner;

      if (!!currentPartner) {
        const paymentMethods = await paymentMethodsService.getAllPaymentMethods(
          currentPartner.id
        );
        dispatch(
          loadAllPaymentMethodsSuccess(
            paymentMethods.bankAccounts,
            paymentMethods.cards
          )
        );
      } else {
        const partners = await partnerService.getUserPartners();
        dispatch(setUserPartners(partners));
        if (!isEmpty(partners)) {
          const cachedPartner = localService.getCurrentPartner();
          let finalPartner;

          if (cachedPartner) {
            finalPartner =
              partners.find((p) => p.id === cachedPartner.id) || partners[0];
          } else {
            finalPartner = partners[0];
          }

          dispatch(setCurrentPartner(finalPartner));
          const paymentMethods =
            await paymentMethodsService.getAllPaymentMethods(finalPartner.id);
          dispatch(
            loadAllPaymentMethodsSuccess(
              paymentMethods.bankAccounts,
              paymentMethods.cards
            )
          );
        } else {
          dispatch(loadAllPaymentMethodsSuccess([], []));
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
};

interface DeletePaymentMethodFromUserSuccessAction {
  type: PaymentMethodsActionTypes;
  payload: string;
}

const deletePaymentMethodFromUserSuccess = (
  paymentMethodId: string
): DeletePaymentMethodFromUserSuccessAction => ({
  type: PaymentMethodsActionTypes.DELETE_SUCCESS,
  payload: paymentMethodId,
});

export const deletePaymentMethodFromUser: ActionCreator<
  ThunkAction<
    Promise<any>,
    AppState,
    null,
    DeletePaymentMethodFromUserSuccessAction
  >
> = (paymentMethodId: string) => {
  return async (dispatch: Dispatch, getState) => {
    dispatch(setPaymentMethodsLoading(true));
    try {
      const currentPartner = getState().partner.currentPartner;
      const partnerId = currentPartner?.id;
      if (!!partnerId) {
        await paymentMethodsService.deletePaymentMethod(
          partnerId,
          paymentMethodId
        );
        dispatch(deletePaymentMethodFromUserSuccess(paymentMethodId));
      }
    } catch (err) {
      console.error(err);
    }
  };
};

interface SetLoadingAction {
  type: typeof PaymentMethodsActionTypes.LOAD_ALL_SUCCESS;
  payload: boolean;
}

const setPaymentMethodsLoading = (loading: boolean) => ({
  type: PaymentMethodsActionTypes.SET_LOADING,
  payload: loading,
});

interface SetSelectedPaymentMethodAction {
  type: typeof PaymentMethodsActionTypes.SET_SELECTED_PAYMENT_METHOD;
  payload: string;
}

export const setSelectedPaymentMethod = (paymentMethod: string) => ({
  type: PaymentMethodsActionTypes.SET_SELECTED_PAYMENT_METHOD,
  payload: paymentMethod,
});

export type PaymentMethodsActions =
  | LoadAllPaymentMethodsSuccessAction
  | DeletePaymentMethodFromUserSuccessAction
  | SetLoadingAction
  | SetSelectedPaymentMethodAction;
