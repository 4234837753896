import axios from '../httpClient';
import { SubscriptionCoreCost } from '../../models/subscriptionCosts';
import {
  authScopes,
  getCurrentAccount,
  acquireTokenSilentOrRefreshToken,
} from '../auth';
import { GetCostByPartnerIdDtoOut } from './dtos/get-cost-by-partner-id/get-cost-by-partner-id-dto-out';
import { appConfiguration } from '../../configuration';

class CostService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async getCostsForPartner(partnerId: string): Promise<SubscriptionCoreCost[]> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return (
      await axios.get(`${this.baseUrl}/cost/${partnerId}`, {
        headers: {
          Authorization: `Bearer ${authResult.idToken}`,
        },
      })
    ).data?.map(GetCostByPartnerIdDtoOut.fromDto);
  }
}

export const subscriptionCostsService = new CostService(
  appConfiguration()?.coreApiUrl ?? ''
);
