import { Money } from 'ts-money';
import { AttachmentDto } from '../dtos/attachment';
import {
  NotificationType,
  SubscriptionNotificationPhase,
} from '../models/enums';
import Plan from '../models/plan';
import { PriceComponentType } from '../models/price.model';
import {
  authScopes,
  getCurrentAccount,
  acquireTokenSilentOrRefreshToken,
} from '../services/auth';

const timeout = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Attempt to call the provided function refreshing the JWT token between each retry.
 * @param retryCount How many time should the call be retried
 * @param delay The delay between retries in milliseconds
 * @param tryFunc The function to call
 * @returns The result of the called function
 */
export const retryRefreshToken = async <T>(
  retryCount: number,
  delay: number,
  tryFunc: () => Promise<T>
): Promise<T> => {
  let error;
  while (retryCount > 0) {
    try {
      await timeout(delay);
      return await tryFunc();
    } catch (err) {
      await acquireTokenSilentOrRefreshToken({
        scopes: authScopes,
        account: getCurrentAccount(),
        forceRefresh: true,
      });
      retryCount--;
      error = err;
    }
  }
  throw error;
};

export const convertFileToJSON = async (file: File): Promise<AttachmentDto> => {
  return new Promise<AttachmentDto>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const arrayBuffer = reader.result as ArrayBuffer;
      const base64Content = Buffer.from(arrayBuffer).toString('base64');
      resolve({
        type: file.type,
        content: base64Content,
      } as AttachmentDto);
    };
    reader.onerror = () =>
      reject('Error converting the file to JSON document.');
    reader.readAsArrayBuffer(file);
  });
};

const roundNumber = (number: number, decimals = 2) => {
  const factor = Math.pow(10, decimals);
  return Math.round((number + Number.EPSILON) * factor) / factor;
};

export const formatLatency = (latencyAvg: number) =>
  `${roundNumber(latencyAvg)} ms`;

export const formatPercentage = (latencyAvg: number) =>
  `${roundNumber(latencyAvg)} %`;

export const formatUsage = (usage: number) => `${roundNumber(usage)}`;

export const formatCost = (cost: number, currency: string) => {
  const money = new Money(cost, currency);
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: money.currency,
  });
  return formatter.format(money.toDecimal());
};

export const readAsDataURL = (file: File): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onerror = reject;
    fr.onload = () => {
      resolve(fr.result?.toString());
    };
    fr.readAsDataURL(file);
  });
};

export const downloadStringAsFile = (
  fileName: string,
  mimeType: 'application/xml' | 'application/pdf',
  content?: string,
  url?: string
) => {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    url
      ? url
      : `data:${mimeType};charset=utf-8,` +
          encodeURIComponent(content as string)
  );
  element.setAttribute('download', fileName);

  element.style.display = 'none';
  if (mimeType === 'application/pdf') {
    element.target = '_blank';
  }

  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

const determineNotificationSourceURLForSubscriptions = (
  notification: any
): string => {
  switch (notification.data.phase) {
    case SubscriptionNotificationPhase.USER_READY_TO_USE:
    case SubscriptionNotificationPhase.PARTNER_READY_TO_USE:
      return `/dashboard/subscriptions?selectedSubscriptionId=${notification.data.subscriptionId}`;
    case SubscriptionNotificationPhase.PARTNER_NEW_SUBSCRIBER:
      return `/dashboard/products?selectedProductId=${notification.data.productId}`;
    default:
      return `/service/${notification.data.productId}`;
  }
};

export const determineNotificationSourceURL = (notification: any) => {
  switch (notification.type) {
    case NotificationType.SERVICE_SUBSCRIPTION_CREATED:
      return determineNotificationSourceURLForSubscriptions(notification);
    default:
      return '#';
  }
};

export const convertLogo = async (
  logos: FileList | undefined
): Promise<AttachmentDto> => {
  if (logos && logos.length === 1) {
    return await convertFileToJSON(logos[0]);
  }

  return Promise.resolve({ type: '', content: '' });
};

export const findFirstUnitLabel = (plan: Plan): string =>
  (
    plan?.sellingPrice?.priceComponents?.length === 1 &&
    (plan?.sellingPrice?.priceComponents?.find(
      (component: PriceComponentType) =>
        'unitLabel' in component.priceModelConfiguration
    )?.priceModelConfiguration as any)
  )?.unitLabel;
