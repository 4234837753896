import { appConfiguration } from '../../configuration';
import { RenewedSubscriptionKeys } from '../../dtos/subscription';
import { SubscriptionAction } from '../../models/enums';
import { Subscription } from '../../models/subscription';
import {
  authScopes,
  getCurrentAccount,
  acquireTokenSilentOrRefreshToken,
} from '../auth';
import axios from '../httpClient';
import { GetSubscriptionApiKeysDtoOut } from './dtos/get-subscription-api-keys/get-subscription-api-keys-dto-out';
import { GetSubscriptionsByPartnerIdDtoOut } from './dtos/get-subscriptions-by-partner-id/get-subscriptions-by-partner-id-dto-out';
import { PatchWebappSubscriptionActionsDtoIn } from './dtos/patch-subscription-action/patch-subscription-action-dto-in';
import { PatchWebappSubscriptionActionsDtoOut } from './dtos/patch-subscription-action/patch-subscription-action-dto-out';
import { PostSubscriptionsDtoIn } from './dtos/post-subscriptions/post-subscriptions-dto-in';
import { PostSubscriptionsDtoOut } from './dtos/post-subscriptions/post-subscriptions-dto-out';

class SubscriptionsService {
  private apiBaseUrl: string;

  constructor(baseUrl: string) {
    this.apiBaseUrl = baseUrl;
  }

  async createSubscription(
    subscriptionDetails: PostSubscriptionsDtoIn,
    partnerId: string
  ): Promise<Subscription> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return PostSubscriptionsDtoOut.fromDto(
      (
        await axios.post<PostSubscriptionsDtoOut>(
          `${this.apiBaseUrl}/partners/${partnerId}/subscriptions`,
          subscriptionDetails,
          {
            headers: {
              Authorization: `Bearer ${authResult.idToken}`,
            },
          }
        )
      ).data
    );
  }

  async cancelSubscription(
    subscriptionId: string,
    partnerId: string
  ): Promise<any> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return axios.patch(
      `${this.apiBaseUrl}/partners/${partnerId}/subscriptions/${subscriptionId}/cancel`,
      null,
      {
        headers: {
          Authorization: `Bearer ${authResult.idToken}`,
        },
      }
    );
  }

  async getSubscriptionsByPartnerId(
    partnerId: string
  ): Promise<Subscription[]> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return (
      await axios.get<GetSubscriptionsByPartnerIdDtoOut[]>(
        `${this.apiBaseUrl}/partners/${partnerId}/subscriptions`,
        {
          headers: {
            Authorization: `Bearer ${authResult.idToken}`,
          },
        }
      )
    ).data.map(GetSubscriptionsByPartnerIdDtoOut.fromDto);
  }

  async getSubscriptionKeys(
    partnerId: string,
    subscriptionId: string
  ): Promise<GetSubscriptionApiKeysDtoOut> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return (
      await axios.get<GetSubscriptionApiKeysDtoOut>(
        `${this.apiBaseUrl}/partners/${partnerId}/subscriptions/${subscriptionId}/api-keys`,
        {
          headers: {
            Authorization: `Bearer ${authResult.idToken}`,
          },
        }
      )
    ).data;
  }

  async renewSubscriptionKeys(
    partnerId: string,
    subscriptionId: string,
    requestedKey: string
  ): Promise<RenewedSubscriptionKeys> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return (
      await axios.put(
        `${this.apiBaseUrl}/partners/${partnerId}/subscriptions/${subscriptionId}/api-key-renewal/${requestedKey}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${authResult.idToken}`,
          },
        }
      )
    ).data;
  }

  async subscriptionAction(
    subscriptionId: string,
    subscriptionAction: SubscriptionAction
  ): Promise<PatchWebappSubscriptionActionsDtoOut> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });
    const subscriptionPayload: PatchWebappSubscriptionActionsDtoIn = {
      action: subscriptionAction,
    };

    const response = await axios.patch(
      `${this.apiBaseUrl}/subscriptions/${subscriptionId}/actions`,
      subscriptionPayload,
      {
        headers: {
          Authorization: `Bearer ${authResult.idToken}`,
          subscriptionId: subscriptionId,
        },
      }
    );
    return response.data;
  }
}

export const subscriptionsService = new SubscriptionsService(
  appConfiguration()?.coreApiUrl ?? ''
);
