import { mapKeys } from 'lodash';
import { Reducer } from 'redux';
import { GetPartnersProductsAllDtoOut } from '../../services/partners-products/dtos/get-partners-products-all/get-partners-products-all-dto-out';
import {
  DashboardProductsActions,
  DashboardProductsActionTypes,
} from '../actions/dashboardProductsActions';

export interface DashboardProductsState {
  products: { [id: string]: GetPartnersProductsAllDtoOut };
  loading: boolean;
  error: any;
}

const initialState: DashboardProductsState = {
  products: {},
  loading: false,
  error: null,
};

export const dashboardProductsReducer: Reducer<
  DashboardProductsState,
  DashboardProductsActions
> = (state = initialState, action): DashboardProductsState => {
  switch (action.type) {
    case DashboardProductsActionTypes.LOAD_ALL_DASHBOARD_PRODUCTS_SUCCESS: {
      return {
        ...state,
        products: mapKeys(action.payload, 'id'),
        loading: false,
        error: null,
      };
    }
    case DashboardProductsActionTypes.LOAD_ALL_DASHBOARD_PRODUCTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    case DashboardProductsActionTypes.SET_DASHBOARD_PRODUCTS_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case DashboardProductsActionTypes.SET_PRODUCT_STATUS: {
      const product = state.products[action.payload.productId];
      if (product) {
        const products = { ...state.products };
        products[action.payload.productId] = {
          ...product,
          status: action.payload.status,
        };
        return {
          ...state,
          products,
        };
      }
      return state;
    }
    case DashboardProductsActionTypes.DELETE_DASHBOARD_PRODUCT_SUCCESS: {
      const newDashboardProducts = { ...state.products };
      delete newDashboardProducts[action.payload];

      return {
        ...state,
        products: newDashboardProducts,
      };
    }
    case DashboardProductsActionTypes.CLEAR_DASHBOARD_PRODUCTS: {
      return {
        ...state,
        products: {},
        loading: action.payload,
      };
    }
    default:
      return state;
  }
};
