import { User } from './user';
import { MemberStatus, AvailabilityStatus } from './enums';

export default interface Partner {
  id: string;
  companyName: string;
  displayName?: string;
  billingAddress: string;
  billingCity: string;
  billingPostalCode: string;
  billingCountry: string;
  homePage?: string;
  taxNumber?: string;
  vatNumber?: string;
  contactName: string;
  contactEmail: string;
  members?: PartnerMember[];
  applications?: PartnerApplication[];
  partnerCreatedInAPIM?: boolean;
  partnerCreatedInStripe?: boolean;
  availabilityStatus: AvailabilityStatus;
  hasSubscriptions: boolean;
  hasServices: boolean;
  consumerFrameContractSigned: boolean;
  providerFrameContractSigned: boolean;
  shortDescription: string;
}

export interface PartnerAbstract {
  companyName: string;
  displayName?: string;
}

export interface NewPartnerMember {
  email: string;
  roles: RoleConfig[];
}

export interface PartnerMember {
  user: User;
  status: MemberStatus;
  roles: RoleConfig[];
}

export interface RoleConfig {
  role: Role;
  scope: Scope;

  /**
   * The id of the entity (e.g. product ID)
   */
  entityIds?: string[];
}

export enum Role {
  OWNER = 'owner',
  MEMBER = 'member',
  MAINTAINER = 'maintainer',
}

export enum Scope {
  PARTNER = 'partner',
  PRODUCT = 'product',
}

export interface UserPartner {
  id: string;
  companyName: string;
  displayName?: string;
  roles: RoleConfig[];
  partnerCreatedInAPIM: boolean | undefined;
  partnerCreatedInStripe: boolean | undefined;
  hasSubscriptions: boolean;
  hasServices: boolean;
  availabilityStatus: AvailabilityStatus;
  consumerFrameContractSigned: boolean;
  providerFrameContractSigned: boolean;
  shortDescription: string;
}

export interface PartnerApplication {
  id: string;
  applicationId: string;
  secretHint: string;
  displayName: string;
  roles: RoleConfig[];
}

export interface NewPartnerApplication {
  displayName: string;
  roles: RoleConfig[];
}
