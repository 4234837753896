import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Distances } from '../../AppTheme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalWrapper: {
      display: 'block',
      borderTop: `1px solid ${theme.palette.border.main}`,
      paddingBottom: '20px',
      paddingTop: '20px',
      background: 'white',
      bottom: 0,
      position: 'fixed',
      float: 'right',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    modalSizeNoSidebar: {
      width: '100% !important',
    },
    modalSizeWithSidebar: {
      width: 'calc(100%  - 260px)',
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    textWrapper: {
      justifySelf: 'start',
      display: 'flex',
      flexDirection: 'column',
      paddingRight: Distances.FIELD,
    },
    smallTextWrapper: {
      flexDirection: 'row',
      minHeight: '48px',
    },
    checkboxWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
    },
    formLabel: {
      '&.MuiFormControlLabel-root': {
        marginLeft: 0,
        paddingLeft: theme.spacing(0.5),
        marginTop: Distances.HALF_FIELD_20,
      },
      '& .MuiFormControlLabel-label': {
        marginLeft: theme.spacing(1),
        fontFamily: ['Calibri', 'sans-serif'].join(','),
        fontSize: '14px',
      },
    },
    buttonWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'end',
      [theme.breakpoints.down(1500)]: {
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    button: {
      width: '210px',
      margin: '0',
      marginTop: Distances.HALF_FIELD_20,
      marginBottom: Distances.HALF_FIELD_20,
      marginLeft: Distances.HALF_FIELD_20,
    },
    linkColor: {
      color: theme.palette.accentGreen.main,
    },
  }),
);

export default useStyles;
