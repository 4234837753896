import { AccountInfo } from '@azure/msal-browser';
import { AnyAction, Reducer } from 'redux';
import { User } from '../../models/user';
import { localService } from '../../services/localService';
import { AuthActionTypes } from '../actions/authActions';

export interface AuthState {
  user: User | null;
  tokenAcquired: boolean;
}

const initialState: AuthState = {
  user: null,
  tokenAcquired: false,
};

export const authReducer: Reducer<AuthState, AnyAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AuthActionTypes.AUTO_LOGIN:
    case AuthActionTypes.LOGIN_SUCCESS: {
      const account = action.payload as AccountInfo;
      const claims = account?.idTokenClaims as any;

      return {
        ...state,
        user: {
          id: account?.localAccountId as string,
          email: claims?.emails?.[0],
          firstName: claims?.given_name,
          lastName: claims?.family_name,
          fullName: `${claims?.given_name} ${claims?.family_name}`,
        },
      };
    }
    case AuthActionTypes.LOGOUT_SUCCESS: {
      localService.clearCurrentPartner();
      return {
        ...state,
        user: initialState.user,
      };
    }
    case AuthActionTypes.TOKEN_ACQUIRED: {
      return {
        ...state,
        tokenAcquired: true,
      };
    }
    default:
      return state;
  }
};
