import { Reducer } from 'redux';
import { MemberStatus } from '../../models/enums';
import Partner, {
  PartnerApplication,
  PartnerMember,
  UserPartner,
} from '../../models/partner';
import { SecurityLog } from '../../models/security-log';
import { PartnerActionTypes, PartnerActions } from '../actions/partnerActions';

export interface PartnerState {
  userPartners?: UserPartner[];
  currentPartner?: UserPartner;
  partner?: Partner;
  loadingPartner: boolean;
  loadingPartnerFormSubmission: boolean;
  loadingPartners: boolean;
  error?: string;
  securityLogs?: SecurityLog[];
  addMemberLoading: boolean;
  addMemberError: string;
  removeMemberEmail: string;
  removeMemberError: string;
  updateMemberRoleLoading: boolean;
  updateMemberRoleError: string;
  addApplicationLoading: boolean;
  addApplicationError: string;
  dashboardPartnerEditMode: boolean;
  dashboardPartnerCreateMode: boolean;
  inviteMemberLoading: boolean;
  invitationStatus?: MemberStatus;
}

const initialState: PartnerState = {
  userPartners: undefined,
  currentPartner: undefined,
  partner: undefined,
  loadingPartner: true,
  loadingPartnerFormSubmission: false,
  loadingPartners: false,
  error: undefined,
  securityLogs: undefined,
  addMemberLoading: false,
  addMemberError: '',
  removeMemberEmail: '',
  removeMemberError: '',
  updateMemberRoleLoading: false,
  updateMemberRoleError: '',
  addApplicationLoading: false,
  addApplicationError: '',
  dashboardPartnerEditMode: false,
  dashboardPartnerCreateMode: false,
  inviteMemberLoading: false,
  invitationStatus: undefined,
};

export const partnerReducer: Reducer<PartnerState, PartnerActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PartnerActionTypes.SET_PARTNER_LOADING: {
      return {
        ...state,
        loadingPartner: action.payload as boolean,
      };
    }
    case PartnerActionTypes.SET_PARTNER_FORM_SUBMISSION_LOADING: {
      return {
        ...state,
        loadingPartnerFormSubmission: action.payload as boolean,
      };
    }
    case PartnerActionTypes.SET_PARTNERS_LOADING: {
      return {
        ...state,
        loadingPartners: action.payload as boolean,
      };
    }
    case PartnerActionTypes.SET_PARTNER_ERROR: {
      return {
        ...state,
        loadingPartner: false,
        loadingPartnerFormSubmission: false,
        loadingPartners: false,
        error: action.payload as string,
      };
    }
    case PartnerActionTypes.LOAD_PARTNER_SUCCESS: {
      return {
        ...state,
        partner: action.payload as Partner,
        error: undefined,
        loadingPartner: false,
      };
    }
    case PartnerActionTypes.ADD_MEMBER_LOADING: {
      return {
        ...state,
        addMemberLoading: action.payload as boolean,
      };
    }
    case PartnerActionTypes.ADD_MEMBER_ERROR: {
      return {
        ...state,
        addMemberLoading: false,
        addMemberError: action.payload as string,
      };
    }
    case PartnerActionTypes.ADD_MEMBER_SUCCESS: {
      let newMembers: PartnerMember[];
      state.partner && state.partner.members
        ? (newMembers = state.partner.members)
        : (newMembers = []);
      newMembers.push(action.payload as PartnerMember);
      if (state.partner) {
        return {
          ...state,
          addMemberLoading: false,
          partner: { ...state.partner, members: newMembers },
        };
      } else {
        return state;
      }
    }
    case PartnerActionTypes.ADD_APPLICATION_LOADING: {
      return {
        ...state,
        addApplicationLoading: action.payload as boolean,
      };
    }
    case PartnerActionTypes.ADD_APPLICATION_ERROR: {
      return {
        ...state,
        addApplicationLoading: false,
        addApplicationError: action.payload as string,
      };
    }
    case PartnerActionTypes.ADD_APPLICATION_SUCCESS: {
      let newApplications: PartnerApplication[];
      state.partner && state.partner.applications
        ? (newApplications = state.partner.applications)
        : (newApplications = []);
      newApplications.push(action.payload as PartnerApplication);
      if (state.partner) {
        return {
          ...state,
          addApplicationLoading: false,
          partner: { ...state.partner, applications: newApplications },
        };
      } else {
        return state;
      }
    }
    case PartnerActionTypes.REMOVE_MEMBER_LOADING: {
      return {
        ...state,
        removeMemberEmail: action.payload as string,
      };
    }
    case PartnerActionTypes.REMOVE_MEMBER_ERROR: {
      return {
        ...state,
        removeMemberEmail: '',
        removeMemberError: action.payload as string,
      };
    }
    case PartnerActionTypes.REMOVE_MEMBER_SUCCESS: {
      let newMembers: PartnerMember[];
      state.partner && state.partner.members
        ? (newMembers = state.partner.members)
        : (newMembers = []);
      const removedMember = action.payload as {
        userId: string;
        email: string;
      };

      if (removedMember.userId)
        newMembers = newMembers.filter(
          (member) => member.user.email !== removedMember.userId
        );
      else
        newMembers = newMembers.filter(
          (member) => member.user.email !== removedMember.email
        );

      if (state.partner) {
        return {
          ...state,
          removeMemberEmail: '',
          partner: { ...state.partner, members: newMembers },
        };
      } else {
        return state;
      }
    }
    case PartnerActionTypes.UPDATE_MEMBER_ROLE_LOADING: {
      return {
        ...state,
        updateMemberRoleLoading: action.payload as boolean,
      };
    }
    case PartnerActionTypes.UPDATE_MEMBER_ROLE_ERROR: {
      return {
        ...state,
        updateMemberRoleLoading: false,
        updateMemberRoleError: action.payload as string,
      };
    }
    case PartnerActionTypes.UPDATE_MEMBER_ROLE_SUCCESS: {
      let newMembers: PartnerMember[];
      state.partner && state.partner.members
        ? (newMembers = state.partner.members)
        : (newMembers = []);
      newMembers[
        newMembers.findIndex(
          (member) =>
            member.user.id === (action.payload as PartnerMember).user.id
        )
      ] = action.payload as PartnerMember;
      if (state.partner) {
        return {
          ...state,
          removeMemberEmail: '',
          partner: { ...state.partner, members: newMembers },
        };
      } else {
        return state;
      }
    }
    case PartnerActionTypes.LOAD_PARTNERS_OF_USER_SUCCESS: {
      return {
        ...state,
        userPartners: action.payload as UserPartner[],
        loadingPartners: false,
      };
    }
    case PartnerActionTypes.SET_CURRENT_PARTNER: {
      return {
        ...state,
        currentPartner: action.payload as UserPartner,
      };
    }
    case PartnerActionTypes.SET_DASHBOARD_PARTNER_CREATE: {
      return {
        ...state,
        dashboardPartnerCreateMode: action.payload as boolean,
      };
    }
    case PartnerActionTypes.SET_PARTNER_SECURITY_LOGS: {
      return {
        ...state,
        securityLogs: action.payload as SecurityLog[],
      };
    }
    case PartnerActionTypes.SET_INVITATION_LOADING: {
      return {
        ...state,
        inviteMemberLoading: action.payload as boolean,
      };
    }
    case PartnerActionTypes.SET_INVITATION_STATUS: {
      return {
        ...state,
        invitationStatus: action.payload as MemberStatus,
      };
    }
    default:
      return state;
  }
};
