import { mapKeys } from 'lodash';
import { Reducer } from 'redux';
import { Category } from '../../models/enums';
import { Product } from '../../models/product';
import {
  ProductsActions,
  ProductsActionTypes,
} from '../actions/productsActions';

export interface ProductsState {
  product?: Product;
  products: { [id: string]: Product };
  loading: boolean;
  loadProductErrorMessage: string | undefined;
  selectedProductCategory: Category;
}

const initialState: ProductsState = {
  product: undefined,
  products: {},
  loading: false,
  loadProductErrorMessage: undefined,
  selectedProductCategory: Category.ALL_PRODUCTS,
};

export const productsReducer: Reducer<ProductsState, ProductsActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ProductsActionTypes.CREATE_PRODUCT_SUCCESS: {
      const newProduct = action.data as unknown as Product;
      state.products[newProduct.id] = newProduct;
      return {
        ...state,
      };
    }
    case ProductsActionTypes.UPDATE_PRODUCT_SUCCESS: {
      const updatedProduct = action.data as unknown as Product;
      state.products[updatedProduct.id] = updatedProduct;
      return {
        ...state,
      };
    }
    case ProductsActionTypes.LOAD_ALL_SUCCESS: {
      return {
        ...state,
        products: mapKeys(action.data, 'id'),
        loading: false,
      };
    }
    case ProductsActionTypes.LOAD_BY_ID_SUCCESS: {
      return {
        ...state,
        product: action.data,
        loading: false,
        loadProductErrorMessage: undefined,
      };
    }
    case ProductsActionTypes.LOAD_BY_ID_FAIL: {
      return {
        ...state,
        loading: false,
        loadProductErrorMessage: action.data,
      };
    }
    case ProductsActionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.payload as unknown as boolean,
      };
    }
    default:
      return state;
  }
};
