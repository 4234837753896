import axios from '../httpClient';
import { convertDateToUnixTimestamp } from '../../util/date-time';
import {
  authScopes,
  getCurrentAccount,
  acquireTokenSilentOrRefreshToken,
} from '../auth';
import { GetUsageByPartnerIdDtoOut } from './dtos/get-usage-by-partner-id/get-usage-by-partner-id-dto-out';
import { appConfiguration } from '../../configuration';

class SubscriptionUsagesService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async getUsagesWithinIntervalForPartner(
    partnerId: string,
    startDate: Date,
    endDate: Date
  ): Promise<GetUsageByPartnerIdDtoOut[]> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return (
      await axios.get(`${this.baseUrl}/usage/${partnerId}`, {
        headers: {
          Authorization: `Bearer ${authResult.idToken}`,
        },
        params: {
          startTimestamp: convertDateToUnixTimestamp(startDate),
          endTimestamp: convertDateToUnixTimestamp(endDate),
        },
      })
    ).data?.map(GetUsageByPartnerIdDtoOut.fromDto);
  }
}

export const subscriptionUsagesService = new SubscriptionUsagesService(
  appConfiguration()?.coreApiUrl ?? ''
);
