import { EffectCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ProductStatus, SignalRMessageType } from '../models/enums';
import Partner from '../models/partner';
import { Subscription } from '../models/subscription';
import { NotificationDataType } from '../models/userNotification';
import { getNotificationService } from '../services/notificationService';
import { setProductStatus } from '../store/actions/dashboardProductsActions';
import { setSignalRAlert } from '../store/actions/notificationsActions';
import { loadUserPartners, setPartner } from '../store/actions/partnerActions';
import { setSubscription } from '../store/actions/subscriptionsActions';
import { notificationReceivedSuccess } from '../store/actions/userNotificationsActions';
import { partnerCurrentPartner, userSelector } from '../store/selectors';

export const useNotifications: EffectCallback = () => {
  const location = useLocation();
  const user = useSelector(userSelector);
  const currentPartner = useSelector(partnerCurrentPartner);
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (!currentPartner || !user || !location) {
      return;
    }

    const handleSubscriptionNotifications = (data: {
      payload: Subscription;
      messageType: SignalRMessageType;
    }) => {
      const onSubscription = location.pathname.includes('subscriptions');

      if (data.payload.partnerId !== currentPartner.id) {
        return;
      }

      if (onSubscription) {
        dispatch(setSubscription(data.payload));
      }

      dispatch(
        setSignalRAlert({
          severity: 'success',
          messageKey: data.payload.productName,
          subscriptionId: data.payload.id,
          messageType: data.messageType,
        })
      );
    };

    getNotificationService(
      currentPartner.id,
      user.id
    ).registerSubscriptionHandler(handleSubscriptionNotifications);

    const handlePartnerNotifications = (data: {
      payload: Partner;
      messageType: SignalRMessageType;
    }) => {
      const onPartner = location.pathname.includes('partner');

      if (data.payload.id !== currentPartner.id) {
        return;
      }

      if (onPartner) {
        dispatch(setPartner(data.payload));
      }

      if (data.messageType === SignalRMessageType.PARTNER_UPDATED) {
        // Also update the current (user) partner (displayed in the menus)
        dispatch(loadUserPartners(data.payload.id));
      } else {
        dispatch(
          setSignalRAlert({
            severity: 'success',
            messageKey: '',
            subscriptionId: '',
            messageType: data.messageType,
          })
        );
      }
    };

    const handleUserNotifications = (data: {
      payload: NotificationDataType;
      messageType: SignalRMessageType;
    }) => {
      dispatch(notificationReceivedSuccess(data.payload));
    };

    getNotificationService(currentPartner.id, user.id).registerPartnerHandler(
      handlePartnerNotifications
    );

    getNotificationService(
      currentPartner.id,
      user.id
    ).registerUserNotificationHandler(handleUserNotifications);

    const handleProductNotifications = (data: {
      payload: { productId: string; partnerId: string; productName: string };
      messageType: SignalRMessageType;
    }) => {
      if (data.payload.partnerId !== currentPartner.id) {
        return;
      }

      const onServices = location.pathname.includes('products');

      switch (data.messageType) {
        case SignalRMessageType.PRODUCT_CREATION_FAILED:
          if (onServices) {
            dispatch(
              setProductStatus({
                productId: data.payload.productId,
                status: ProductStatus.FAILED,
              })
            );

            dispatch(
              setSignalRAlert({
                severity: 'error',
                messageKey: data.payload.productName,
                subscriptionId: '',
                messageType: data.messageType,
              })
            );
          }
          break;
        case SignalRMessageType.PRODUCT_CREATED:
          if (onServices) {
            dispatch(
              setProductStatus({
                productId: data.payload.productId,
                status: ProductStatus.ACTIVE,
              })
            );

            dispatch(
              setSignalRAlert({
                severity: 'success',
                messageKey: data.payload.productName,
                subscriptionId: '',
                messageType: data.messageType,
              })
            );
          }
          break;
        default:
          break;
      }
    };

    getNotificationService(currentPartner.id, user.id).registerProductHandler(
      handleProductNotifications
    );

    return () => {
      getNotificationService(
        currentPartner.id,
        user.id
      ).clearSubscriptionHandler();
      getNotificationService(currentPartner.id, user.id).clearPartnerHandler();
      getNotificationService(currentPartner.id, user.id).clearProductHandler();
      getNotificationService(
        currentPartner.id,
        user.id
      ).clearUserNotificationHandler();
    };
  }, [currentPartner, user, location, dispatch]);
};
