import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';
import { ActionCreator, AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '..';
import { RenewedSubscriptionKeys } from '../../dtos/subscription';
import { SubscriptionApiKey } from '../../models/enums';
import { Subscription } from '../../models/subscription';
import routes from '../../routes';
import { subscriptionsService } from '../../services/subscriptions/subscriptions-service';
import { PostSubscriptionsDtoIn } from '../../services/subscriptions/dtos/post-subscriptions/post-subscriptions-dto-in';
import { GetSubscriptionApiKeysDtoOut } from '../../services/subscriptions/dtos/get-subscription-api-keys/get-subscription-api-keys-dto-out';

export enum SubscriptionsActionTypes {
  CREATE_SUBSCRIPTION_SUCCESS = '[CREATE_SUBSCRIPTION_SUCCESS] Create a new subscription success',
  CREATE_SUBSCRIPTION_FAIL = '[CREATE_SUBSCRIPTION_FAIL] Create a new subscription failed',
  LOAD_BY_PARTNERID_SUCCESS = 'Load subscriptions by partner id success',
  SET_SUBSCRIPTIONS_LOADING = 'Subscriptions loading',
  SET_LOADING_SUBSCRIPTION_CREATE = '[SET_LOADING_SUBSCRIPTION_CREATE] create subscription loading',
  SET_PRIMARY_KEY_LOADING = 'Subscription primary key loading',
  SET_SECONDARY_KEY_LOADING = 'Subscription secondary key loading',
  GET_SUBSCRIPTION_KEYS_SUCCESS = '[SUBSCRIPTION]Get subscription keys success',
  GET_SUBSCRIPTION_KEYS_ERROR = '[SUBSCRIPTION]Get subscription keys error',
  SET_PRIMARY_KEY = 'Subscription primary key',
  SET_SECONDARY_KEY = 'Subscription secondary key',
  SET_SUBSCRIPTION = 'Set subscription',
}

export const createSubscriptionStart: ActionCreator<
  ThunkAction<Promise<any>, AppState, null, AnyAction>
> = (data: {
  subscriptionDetails: PostSubscriptionsDtoIn;
  currentPartnerId: string;
}) => {
  return async (dispatch: Dispatch) => {
    try {
      const responseData = await subscriptionsService.createSubscription(
        data.subscriptionDetails,
        data.currentPartnerId
      );
      dispatch(createSubscriptionSuccess(responseData));
      dispatch(
        push(
          `${generatePath(routes.checkout.base, {
            id: data.subscriptionDetails.productId,
            planId: data.subscriptionDetails.planId,
          })}${generatePath(routes.checkout.success, {
            subscriptionId: responseData.id,
          })}`
        )
      );
    } catch (error: any) {
      dispatch(createSubscriptionFail(error.response.statusText));
      dispatch(setSubscriptionCreateLoading(false));
    }
  };
};

interface CreateSubscriptionSuccessAction {
  type: SubscriptionsActionTypes.CREATE_SUBSCRIPTION_SUCCESS;
  payload: Subscription;
}

const createSubscriptionSuccess = (
  subscription: Subscription
): CreateSubscriptionSuccessAction => ({
  type: SubscriptionsActionTypes.CREATE_SUBSCRIPTION_SUCCESS,
  payload: subscription,
});

interface CreateSubscriptionFailAction {
  type: SubscriptionsActionTypes.CREATE_SUBSCRIPTION_FAIL;
  payload: string | undefined;
}

export const createSubscriptionFail = (
  errorMessage: string | undefined
): CreateSubscriptionFailAction => ({
  type: SubscriptionsActionTypes.CREATE_SUBSCRIPTION_FAIL,
  payload: errorMessage,
});

interface LoadSubscriptionsByPartnerIdSuccessAction {
  type: SubscriptionsActionTypes.LOAD_BY_PARTNERID_SUCCESS;
  payload: Subscription[];
}

const loadSubscriptionsByPartnerIdSuccess = (
  subscriptions: Subscription[]
): LoadSubscriptionsByPartnerIdSuccessAction => ({
  type: SubscriptionsActionTypes.LOAD_BY_PARTNERID_SUCCESS,
  payload: subscriptions,
});

export const loadSubscriptionsByPartnerIdStart: ActionCreator<
  ThunkAction<Promise<any>, AppState, null, AnyAction>
> = (partnerId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      if (!!partnerId) {
        dispatch(setSubscriptionsLoading(true));
        const subscriptions =
          await subscriptionsService.getSubscriptionsByPartnerId(partnerId);
        dispatch(loadSubscriptionsByPartnerIdSuccess(subscriptions));
      } else {
        dispatch(loadSubscriptionsByPartnerIdSuccess([]));
      }
    } catch (err) {
      console.error(err);
    }
  };
};

interface SetSubscriptionsLoadingAction {
  type: SubscriptionsActionTypes.SET_SUBSCRIPTIONS_LOADING;
  payload: boolean;
}

const setSubscriptionsLoading = (
  loading: boolean
): SetSubscriptionsLoadingAction => ({
  type: SubscriptionsActionTypes.SET_SUBSCRIPTIONS_LOADING,
  payload: loading,
});

interface SetSubscriptionCreateLoadingAction {
  type: SubscriptionsActionTypes.SET_LOADING_SUBSCRIPTION_CREATE;
  payload: boolean;
}

export const setSubscriptionCreateLoading = (
  loading: boolean
): SetSubscriptionCreateLoadingAction => ({
  type: SubscriptionsActionTypes.SET_LOADING_SUBSCRIPTION_CREATE,
  payload: loading,
});

interface SetSubscriptionPrimaryKeyLoadingAction {
  type: SubscriptionsActionTypes.SET_PRIMARY_KEY_LOADING;
  payload: { subscriptionId: string; loading: boolean };
}

const setSubscriptionPrimaryKeyLoading = (
  subscriptionId: string,
  loading: boolean
): SetSubscriptionPrimaryKeyLoadingAction => ({
  type: SubscriptionsActionTypes.SET_PRIMARY_KEY_LOADING,
  payload: {
    subscriptionId: subscriptionId,
    loading: loading,
  },
});

interface SetSubscriptionPrimaryKeyAction {
  type: SubscriptionsActionTypes.SET_PRIMARY_KEY;
  payload: RenewedSubscriptionKeys;
}

const setSubscriptionPrimaryKey = (
  renewedKeys: RenewedSubscriptionKeys
): SetSubscriptionPrimaryKeyAction => ({
  type: SubscriptionsActionTypes.SET_PRIMARY_KEY,
  payload: renewedKeys,
});

interface SetSubscriptionSecondaryKeyLoadingAction {
  type: SubscriptionsActionTypes.SET_SECONDARY_KEY_LOADING;
  payload: { subscriptionId: string; loading: boolean };
}

const setSubscriptionSecondaryKeyLoading = (
  subscriptionId: string,
  loading: boolean
): SetSubscriptionSecondaryKeyLoadingAction => ({
  type: SubscriptionsActionTypes.SET_SECONDARY_KEY_LOADING,
  payload: {
    subscriptionId: subscriptionId,
    loading: loading,
  },
});

interface SetSubscriptionSecondaryKeyAction {
  type: SubscriptionsActionTypes.SET_SECONDARY_KEY;
  payload: RenewedSubscriptionKeys;
}

const setSubscriptionSecondaryKey = (
  renewedKeys: RenewedSubscriptionKeys
): SetSubscriptionSecondaryKeyAction => ({
  type: SubscriptionsActionTypes.SET_SECONDARY_KEY,
  payload: renewedKeys,
});

export const renewSubscriptionKeysStart: ActionCreator<
  ThunkAction<Promise<any>, AppState, null, AnyAction>
> = (subscriptionId: string, requestedKey: string) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      const currentPartnerId = getState().partner.currentPartner?.id;
      if (!currentPartnerId) {
        console.error('Current partner not present in state');
        return;
      }
      requestedKey === SubscriptionApiKey.PRIMARY
        ? dispatch(setSubscriptionPrimaryKeyLoading(subscriptionId, true))
        : dispatch(setSubscriptionSecondaryKeyLoading(subscriptionId, true));
      const newKeys = await subscriptionsService.renewSubscriptionKeys(
        currentPartnerId,
        subscriptionId,
        requestedKey
      );
      requestedKey === SubscriptionApiKey.PRIMARY
        ? dispatch(setSubscriptionPrimaryKey(newKeys))
        : dispatch(setSubscriptionSecondaryKey(newKeys));
      requestedKey === SubscriptionApiKey.PRIMARY
        ? dispatch(setSubscriptionPrimaryKeyLoading(subscriptionId, false))
        : dispatch(setSubscriptionSecondaryKeyLoading(subscriptionId, false));
    } catch (err) {
      console.error(err);
      requestedKey === SubscriptionApiKey.PRIMARY
        ? dispatch(setSubscriptionPrimaryKeyLoading(subscriptionId, false))
        : dispatch(setSubscriptionSecondaryKeyLoading(subscriptionId, false));
    }
  };
};

interface GetSubscriptionKeysSuccessAction {
  type: SubscriptionsActionTypes.GET_SUBSCRIPTION_KEYS_SUCCESS;
  payload: GetSubscriptionApiKeysDtoOut;
}

const getSubscriptionKeysSuccess = (
  response: GetSubscriptionApiKeysDtoOut
): GetSubscriptionKeysSuccessAction => ({
  type: SubscriptionsActionTypes.GET_SUBSCRIPTION_KEYS_SUCCESS,
  payload: response,
});

interface GetSubscriptionKeysErrorAction {
  type: SubscriptionsActionTypes.GET_SUBSCRIPTION_KEYS_ERROR;
  payload: { subscriptionId: string; isError: boolean };
}

const getSubscriptionKeysError = (
  subscriptionId: string,
  isError: boolean
): GetSubscriptionKeysErrorAction => ({
  type: SubscriptionsActionTypes.GET_SUBSCRIPTION_KEYS_ERROR,
  payload: {
    subscriptionId: subscriptionId,
    isError: isError,
  },
});

export const getSubscriptionKeysStart: ActionCreator<
  ThunkAction<Promise<any>, AppState, null, AnyAction>
> = (subscriptionId: string) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      const currentPartnerId = getState().partner.currentPartner?.id;
      if (!currentPartnerId) {
        console.error('Current partner not present in state');
        return;
      }

      dispatch(setSubscriptionPrimaryKeyLoading(subscriptionId, true));
      dispatch(setSubscriptionSecondaryKeyLoading(subscriptionId, true));
      dispatch(getSubscriptionKeysError(subscriptionId, false));
      const getSubscriptionApiKeysDtoOut =
        await subscriptionsService.getSubscriptionKeys(
          currentPartnerId,
          subscriptionId
        );
      dispatch(getSubscriptionKeysSuccess(getSubscriptionApiKeysDtoOut));
      dispatch(setSubscriptionPrimaryKeyLoading(subscriptionId, false));
      dispatch(setSubscriptionSecondaryKeyLoading(subscriptionId, false));
    } catch (err) {
      console.error(err);
      dispatch(setSubscriptionPrimaryKeyLoading(subscriptionId, false));
      dispatch(setSubscriptionSecondaryKeyLoading(subscriptionId, false));
      dispatch(getSubscriptionKeysError(subscriptionId, true));
    }
  };
};

interface SetSubscriptionAction {
  type: SubscriptionsActionTypes.SET_SUBSCRIPTION;
  payload: Subscription;
}

export const setSubscription = (
  subscription: Subscription
): SetSubscriptionAction => ({
  type: SubscriptionsActionTypes.SET_SUBSCRIPTION,
  payload: subscription,
});

export type SubscriptionsActions =
  | CreateSubscriptionSuccessAction
  | CreateSubscriptionFailAction
  | LoadSubscriptionsByPartnerIdSuccessAction
  | SetSubscriptionsLoadingAction
  | SetSubscriptionCreateLoadingAction
  | SetSubscriptionPrimaryKeyLoadingAction
  | SetSubscriptionSecondaryKeyLoadingAction
  | SetSubscriptionPrimaryKeyAction
  | SetSubscriptionSecondaryKeyAction
  | GetSubscriptionKeysSuccessAction
  | GetSubscriptionKeysErrorAction
  | SetSubscriptionAction;
