import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Box,
  Snackbar,
  SnackbarCloseReason,
  Alert as MaterialUIAlert,
} from '@mui/material';
import {
  clearBasicAlert,
  clearSignalRAlert,
} from '../../store/actions/notificationsActions';
import {
  BasicAlert,
  SignalRAlert,
} from '../../store/reducers/notificationsReducer';
import { SignalRMessageType } from '../../models/enums';
import { SNACKBAR_EXPIRATION_TIME_MS } from '../../constants/constants';

interface MPAlertProps {
  /**
   * @description The prop that provides alert information
   * @member {BasicAlert | null} [alert]
   */
  alert: BasicAlert | null;

  /**
   * @description The prop that determines if the alert will be contained in a Snackbar or not
   * Use sticky = true for an alert inside the page
   * Use sticky = false for an alert inside a Snackbar
   * There are 2 components using this sticky = false approach:
   * - one is placed globally inside the AppContent.tsx and is triggered using setBasicAlert action
   * - one is placed inside the Dashboard.tsx and is triggered using setSignalRAlert action
   * @member {boolean} [sticky]
   */
  sticky?: boolean;

  /**
   * @description The prop that is needed for SignalRAlerts
   * @member {JSX.Element} [action]
   */
  action?: JSX.Element;
}

const MPAlert: React.FC<MPAlertProps> = ({ alert, sticky, action }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getSignalRAlertText = () => {
    if (!alert) {
      return;
    }

    const signalRAlert = alert as SignalRAlert;
    switch (signalRAlert.messageType) {
      case SignalRMessageType.SUBSCRIPTION_ACTIVATED:
        return t('subscriptionUpdated', {
          productName: signalRAlert.messageKey,
        });
      case SignalRMessageType.SUBSCRIPTION_CANCELED:
        return t('subscriptionCanceled', {
          productName: signalRAlert.messageKey,
        });
      case SignalRMessageType.SUBSCRIPTION_TERMINATED:
        return t('subscriptionTerminated', {
          productName: signalRAlert.messageKey,
        });
      case SignalRMessageType.MEMBER_ROLE_CHANGED:
        return t('roleSuccessfullyUpdated');
      case SignalRMessageType.PRODUCT_CREATED:
        return t('productCreated', {
          productName: signalRAlert.messageKey,
        });
      case SignalRMessageType.PRODUCT_CREATION_FAILED:
        return t('productCreationFailed', {
          productName: signalRAlert.messageKey,
        });
      default:
        return t('successfulOperation');
    }
  };

  const getBasicAlertText = () => {
    const basicAlert = alert as BasicAlert;
    if (!basicAlert || !basicAlert.messageKey) {
      return;
    }
    return t(basicAlert.messageKey);
  };

  const handleAlertClose = (
    event: Event | SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    action ? dispatch(clearSignalRAlert()) : dispatch(clearBasicAlert());
  };

  return (
    <Box hidden={!alert}>
      {sticky ? (
        <MaterialUIAlert
          severity={alert?.severity}
          action={action}
          variant="outlined"
        >
          <strong>
            {action ? getSignalRAlertText() : getBasicAlertText()}
          </strong>
        </MaterialUIAlert>
      ) : (
        <Snackbar
          open={!!alert}
          autoHideDuration={SNACKBAR_EXPIRATION_TIME_MS}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <MaterialUIAlert
            severity={alert?.severity}
            action={action}
            variant="standard"
            onClose={handleAlertClose}
          >
            <strong>
              {action ? getSignalRAlertText() : getBasicAlertText()}
            </strong>
          </MaterialUIAlert>
        </Snackbar>
      )}
    </Box>
  );
};

export default MPAlert;
