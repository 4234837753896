import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '..';
import { ProductStatus } from '../../models/enums';
import { GetPartnersProductsAllDtoOut } from '../../services/partners-products/dtos/get-partners-products-all/get-partners-products-all-dto-out';
import { dashboardProductsService } from '../../services/partners-products/partners-products-service';
import { ProductsState } from '../reducers/productsReducer';

export enum DashboardProductsActionTypes {
  LOAD_ALL_DASHBOARD_PRODUCTS = '[DashboardProducts] Load all dashboard products',
  LOAD_ALL_DASHBOARD_PRODUCTS_SUCCESS = '[DashboardProducts] Load all dashboard products success',
  LOAD_ALL_DASHBOARD_PRODUCTS_FAIL = '[DashboardProducts] Load all dashboard products fail',
  SET_DASHBOARD_PRODUCTS_LOADING = '[DashboardProducts] Set dashboard products loading',
  SET_PRODUCT_STATUS = '[DashboardProducts] Set dashboard product status',
  DELETE_DASHBOARD_PRODUCT_SUCCESS = 'Delete a product success',
  CLEAR_DASHBOARD_PRODUCTS = 'Clear current dashboard products',
}

interface LoadAllDashboardProductsAction {
  type: typeof DashboardProductsActionTypes.LOAD_ALL_DASHBOARD_PRODUCTS;
}

export const loadAllDashboardProducts: ActionCreator<
  ThunkAction<Promise<any>, AppState, null, LoadAllDashboardProductsAction>
> = () => {
  return async (dispatch: Dispatch, getState) => {
    try {
      const currentPartner = getState().partner.currentPartner;
      if (!currentPartner) {
        dispatch(loadAllDashboardProductsFail('Current partner not loaded'));
        return;
      }
      dispatch(setDashboardProductsLoading(true));

      const products = await dashboardProductsService.getAllDashboardProducts(
        currentPartner.id
      );

      dispatch(loadAllDashboardProductsSuccess(products));
    } catch (err) {
      dispatch(loadAllDashboardProductsFail(err));
    }
  };
};

interface LoadAllDashboardProductsSuccessAction {
  type: typeof DashboardProductsActionTypes.LOAD_ALL_DASHBOARD_PRODUCTS_SUCCESS;
  payload: GetPartnersProductsAllDtoOut[];
}

const loadAllDashboardProductsSuccess = (
  payload: GetPartnersProductsAllDtoOut[]
): LoadAllDashboardProductsSuccessAction => ({
  type: DashboardProductsActionTypes.LOAD_ALL_DASHBOARD_PRODUCTS_SUCCESS,
  payload,
});

interface LoadAllDashboardProductsFailAction {
  type: typeof DashboardProductsActionTypes.LOAD_ALL_DASHBOARD_PRODUCTS_FAIL;
  payload: any;
}

const loadAllDashboardProductsFail = (
  payload: any
): LoadAllDashboardProductsFailAction => ({
  type: DashboardProductsActionTypes.LOAD_ALL_DASHBOARD_PRODUCTS_FAIL,
  payload,
});

interface SetDashboardProductsLoadingAction {
  type: typeof DashboardProductsActionTypes.SET_DASHBOARD_PRODUCTS_LOADING;
  payload: boolean;
}

const setDashboardProductsLoading = (
  payload: boolean
): SetDashboardProductsLoadingAction => ({
  type: DashboardProductsActionTypes.SET_DASHBOARD_PRODUCTS_LOADING,
  payload,
});

interface SetProductStatusAction {
  type: typeof DashboardProductsActionTypes.SET_PRODUCT_STATUS;
  payload: { productId: string; status: ProductStatus };
}

export const setProductStatus = (payload: {
  productId: string;
  status: ProductStatus;
}): SetProductStatusAction => ({
  type: DashboardProductsActionTypes.SET_PRODUCT_STATUS,
  payload,
});

interface DeleteProductSuccessAction {
  type: typeof DashboardProductsActionTypes.DELETE_DASHBOARD_PRODUCT_SUCCESS;
  payload: string;
}

const deleteProductSuccess = (
  productId: string
): DeleteProductSuccessAction => ({
  type: DashboardProductsActionTypes.DELETE_DASHBOARD_PRODUCT_SUCCESS,
  payload: productId,
});

export const deleteDashboardProduct: ActionCreator<
  ThunkAction<Promise<any>, ProductsState, null, DeleteProductSuccessAction>
> = (productId: string, partnerId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const deletedProduct = await dashboardProductsService.deleteProduct(
        productId,
        partnerId
      );
      dispatch(deleteProductSuccess(deletedProduct.id));
    } catch (err) {}
  };
};

interface ClearDashboardProductsAction {
  type: typeof DashboardProductsActionTypes.CLEAR_DASHBOARD_PRODUCTS;
  payload: boolean;
}
export const clearDashboardProducts = (dashboardProductsLoading: boolean): ClearDashboardProductsAction => ({
  type: DashboardProductsActionTypes.CLEAR_DASHBOARD_PRODUCTS,
  payload: dashboardProductsLoading
});

export type DashboardProductsActions =
  | LoadAllDashboardProductsAction
  | LoadAllDashboardProductsSuccessAction
  | LoadAllDashboardProductsFailAction
  | SetDashboardProductsLoadingAction
  | SetProductStatusAction
  | DeleteProductSuccessAction
  | ClearDashboardProductsAction;
