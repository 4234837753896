import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FontWeightProperty } from '../../types/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: { display: 'none' },
    disabledInput: {
      '& > .MuiInputBase-formControl': {
        backgroundColor: 'transparent !important',
        fontSize: '14px',
      },
      '& input, & .MuiSelect-select, & textarea': {
        color: theme.palette.text.primary,
        border: 'none !important',
        padding: '2px 0 !important',
      },
      '& > label': {
        display: 'none',
      },
      '& .MuiInputBase-root': {
        border: 'none !important',
        padding: '0 !important',
      },
      '& .MuiFilledInput-underline.Mui-disabled:before': {
        border: 'none',
        borderBottomStyle: 'none',
      },
    },
    select: {
      fontSize: '14px',
      '& .MuiFilledInput-input': { padding: '18.5px' },
      '& > div': { borderRadius: '14px !important' },
    },
    inputRoot: {
      position: 'relative',
      '& > .MuiFormLabel-root': {
        color: theme.palette.text.primary,
        borderRadius: '14px',
        '& > span': {
          color: theme.palette.secondary.main,
          borderRadius: '14px',
        },
        '&.Mui-focused': {
          color: theme.palette.secondary.main,
          borderRadius: '14px',
          '& > span': {
            color: theme.palette.secondary.main,
            borderRadius: '14px',
          },
        },
      },
      '& > div.MuiInputBase-root.MuiInputBase-multiline': {
        backgroundColor: 'white',
        border: `1px solid ${theme.palette.border.main}`,
        borderRadius: '14px',
        padding: 0,
        fontWeight: theme.typography.fontWeightRegular as FontWeightProperty,
        '&:hover, &.Mui-focused': {
          border: `1px solid ${theme.palette.secondary.main}`,
        },
        '&.Mui-error': {
          border: '1px solid #f44336',
          '&:hover, &.Mui-focused': {
            border: `1px solid ${theme.palette.secondary.main}`,
          },
        },
      },
      '& > p#note-helper-text': {
        position: 'absolute',
        top: '24px',
        right: 0,
      },
      '& > p#note-helper-text.Mui-focused > span > span#note-length': {
        color: theme.palette.secondary.main,
      },
      '& > .MuiInputBase-root': {
        backgroundColor: 'white',
        fontSize: '14px',
        borderRadius: '14px',
        '& > input, & > .MuiSelect-root, & > div#card, & > div#iban': {
          border: `1px solid ${theme.palette.border.main}`,
          borderRadius: '14px',
          fontWeight: theme.typography.fontWeightRegular as FontWeightProperty,
          '&:hover': {
            border: `1px solid ${theme.palette.secondary.main}`,
          },
        },
        '& > div#card, & > div#iban': {
          backgroundColor: 'white !important',
        },
        '&.Mui-focused': {
          backgroundColor: 'white',
          '& > input, & > .MuiSelect-root, & > div#card, & > div#iban': {
            border: `1px solid ${theme.palette.secondary.main}`,
            borderRadius: '14px',
          },
          '& > .MuiSelect-root:focus': {
            backgroundColor: 'white',
          },
        },
        '&.Mui-error > input, &.Mui-error > .MuiSelect-root': {
          border: '1px solid #f44336',
          borderRadius: '14px',
        },
        '&:before': {
          border: 'none',
          borderRadius: '14px',
        },
        '&:hover': {
          backgroundColor: 'white',
          '&:before': {
            border: 'none',
          },
        },
        '&:after': {
          border: 'none',
          borderRadius: '14px',
        },
      },
    },
    inputRootWithIcon: {
      '& > .MuiInputBase-root': {
        backgroundColor: 'white',
        fontWeight: theme.typography.fontWeightRegular as FontWeightProperty,
        border: `1px solid ${theme.palette.border.main}`,
        borderRadius: '4px',
        '&:hover': {
          border: `1px solid ${theme.palette.secondary.main}`,
        },
        '&.Mui-focused': {
          border: `1px solid ${theme.palette.secondary.main}`,
        },
        '& > input, & > .MuiSelect-root, & > div#card, & > div#iban': {
          border: 0,
          '&:hover': {
            border: 0,
          },
        },
        '&.Mui-error': {
          border: '1px solid #f44336',
          '&:hover, &.Mui-focused': {
            border: `1px solid ${theme.palette.secondary.main}`,
          },
        },
        '&:before': {
          border: 'none',
        },
        '&:after': {
          border: 'none',
        },
      },
    },
    multiSelect: {
      '--rmsc-main': `${theme.palette.secondary.main} !important`,
      '--rmsc-hover': `${theme.palette.grey[200]} !important`,
      '--rmsc-selected': `${theme.palette.border.main} !important`,
      '--rmsc-border': `${theme.palette.border.main} !important`,
      '--rmsc-h': '56px !important',
    },
    helperText: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '5px',
      '& > span': {
        margin: '2px 0px 0px 5px',
      },
    },
    textFieldRoot: {
      '& .MuiFormLabel-root': {
        fontSize: '15px',
      },
      '& .MuiInputLabel-shrink': {
        fontSize: '18px',
      },
      '& .MuiOutlinedInput-root': {
        fontSize: '14px',
        '& fieldset span': {
          fontSize: '14px',
        },
        '&.Mui-focused fieldset': {
          borderWidth: '2px',
          borderColor: '#5e6f77',
        },
        '&.Mui-error fieldset': {
          borderWidth: '2px',
          borderColor: `${theme.palette.red.main} !important`,
          fontSize: '14px',
        },
        borderRadius: '10px',
      },
      '& > label': {
        color: 'grey !important',
        '&.Mui-focused.Mui-error': {
          color: `${theme.palette.red.main} !important`,
        },
      },
      '& .MuiFormHelperText-root': {
        height: '20px',
        '&.Mui-error': {
          color: `${theme.palette.red.main} !important`,
        },
      },
      '& .MuiOutlinedInput-input': {
        borderRadius: '14px',
        fontSize: '14px',
        background: 'white',
      },
      '& .MuiInputBase-multiline': {
        background: 'white',
      },
      '& .Mui-disabled': {
        background: 'none',
      },
    },
  })
);

export default useStyles;
