import {
  connectRouter,
  routerMiddleware,
  RouterState,
} from 'connected-react-router';
import { createBrowserHistory, History } from 'history';
import { isEmpty } from 'lodash';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import { authReducer, AuthState } from './reducers/authReducer';
import {
  DashboardProductsState,
  dashboardProductsReducer,
} from './reducers/dashboardProductsReducer';
import {
  dashboardStatisticsReducer,
  DashboardStatisticsState,
} from './reducers/dashboardStatisticsReducer';
import {
  notificationsReducer,
  NotificationsState,
} from './reducers/notificationsReducer';
import { partnerReducer, PartnerState } from './reducers/partnerReducer';
import {
  paymentMethodsReducer,
  PaymentMethodsState,
} from './reducers/paymentMethodsReducer';
import {
  productsApiSpecificationReducer,
  ProductsApiSpecificationState,
} from './reducers/productsApiSpecificationReducer';
import { productsReducer, ProductsState } from './reducers/productsReducer';
import {
  subscriptionsCostsReducer,
  SubscriptionsCostsState,
} from './reducers/subscriptionsCostsReducer';
import {
  subscriptionsReducer,
  SubscriptionsState,
} from './reducers/subscriptionsReducer';
import {
  subscriptionsUsagesReducer,
  SubscriptionsUsagesState,
} from './reducers/subscriptionsUsagesReducer';
import {
  UserNotificationState,
  userNoticationsReducer,
} from './reducers/userNotificationsReducer';

export interface AppState {
  router: RouterState;
  auth: AuthState;
  products: ProductsState;
  dashboardProducts: DashboardProductsState;
  dashboardStatistics: DashboardStatisticsState;
  paymentMethods: PaymentMethodsState;
  subscriptions: SubscriptionsState;
  partner: PartnerState;
  subscriptionsUsages: SubscriptionsUsagesState;
  subscriptionsCosts: SubscriptionsCostsState;
  productsApiSpecification: ProductsApiSpecificationState;
  notifications: NotificationsState;
  userNotifications: UserNotificationState;
}

const publicUrl = process.env.PUBLIC_URL || '';
export const history = createBrowserHistory({ basename: publicUrl });

const createRootReducer = (history: History) =>
  combineReducers<AppState>({
    router: !isEmpty(history) ? connectRouter(history) : ({} as any),
    auth: authReducer,
    products: productsReducer,
    dashboardProducts: dashboardProductsReducer,
    dashboardStatistics: dashboardStatisticsReducer,
    paymentMethods: paymentMethodsReducer,
    subscriptions: subscriptionsReducer,
    partner: partnerReducer,
    subscriptionsUsages: subscriptionsUsagesReducer,
    subscriptionsCosts: subscriptionsCostsReducer,
    productsApiSpecification: productsApiSpecificationReducer,
    notifications: notificationsReducer,
    userNotifications: userNoticationsReducer,
  });

const configureStore = (initialState?: AppState): Store<AppState, any> => {
  const composeEnhancer: typeof compose =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    createRootReducer(history),
    initialState as any,
    !isEmpty(history)
      ? composeEnhancer(applyMiddleware(thunk, routerMiddleware(history)))
      : composeEnhancer(applyMiddleware(thunk))
  );
  return store;
};

export const store = configureStore();
