import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '..';
import { NotificationDataType } from '../../models/userNotification';
import { notificationService } from '../../services/user-notifications/user-notifications-service';

export enum UserNotificationsActionTypes {
  LOAD_BATCH = 'Load all user notifications',
  LOAD_BATCH_OF_NOTIFICATIONS_SUCCESS = 'Load batch of notifications success',
  LOAD_FIRST_BATCH_OF_NOTIFICATIONS_SUCCESS = 'Load first batch of notifications success',
  NOTIFICATION_RECEIVED = 'Notification received',
  GET_UNREAD_COUNT = 'Get unread count',
  GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS = 'Get unread notifications count success',
  UPDATE_HAS_MORE_PAGES = 'Update has more pages',
  READ_ALL_NOTIFICATIONS = 'Read all notifications',
  READ_ALL_NOTIFICATIONS_SUCCESS = 'Read all notifications success',
  UPDATE_CURRENT_PAGE_NUMBER = 'Update current page number',
  REMOVE_NOTIFICATION_BY_ID = 'Removes a notification from the list by its ID',
}

interface LoadBatchOfUserNotificationsAction {
  type: typeof UserNotificationsActionTypes.LOAD_BATCH;
}

export const loadBatchOfUserNotifications: ActionCreator<
  ThunkAction<Promise<any>, AppState, null, LoadBatchOfUserNotificationsAction>
> = (partnerId: string, page: number) => {
  return async (dispatch: Dispatch) => {
    try {
      const notifications =
        await notificationService.getBatchOfUserNotifications(partnerId, page);
      if (page === 0) {
        dispatch(loadFirstBatchOfNotificationsSuccess(notifications));
      } else {
        dispatch(loadBatchOfNotificationsSuccess(notifications));
      }
    } catch (err) {
      console.error(err);
    }
  };
};

interface LoadFirstBatchOfNotificationsSuccessAction {
  type: typeof UserNotificationsActionTypes.LOAD_FIRST_BATCH_OF_NOTIFICATIONS_SUCCESS;
  data: NotificationDataType[];
}

const loadFirstBatchOfNotificationsSuccess = (
  data: NotificationDataType[]
): LoadFirstBatchOfNotificationsSuccessAction => ({
  type: UserNotificationsActionTypes.LOAD_FIRST_BATCH_OF_NOTIFICATIONS_SUCCESS,
  data,
});

interface LoadBatchOfNotificationsSuccessAction {
  type: typeof UserNotificationsActionTypes.LOAD_BATCH_OF_NOTIFICATIONS_SUCCESS;
  data: NotificationDataType[];
}

const loadBatchOfNotificationsSuccess = (
  data: NotificationDataType[]
): LoadBatchOfNotificationsSuccessAction => ({
  type: UserNotificationsActionTypes.LOAD_BATCH_OF_NOTIFICATIONS_SUCCESS,
  data,
});

interface LoadUnreadNotificationsCountAction {
  type: typeof UserNotificationsActionTypes.GET_UNREAD_COUNT;
}

export const loadUnreadNotificationsCount: ActionCreator<
  ThunkAction<Promise<any>, AppState, null, LoadUnreadNotificationsCountAction>
> = (partnerId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const unreadNotificatonsCount =
        await notificationService.getUnreadNotificationsCount(partnerId);
      dispatch(loadUnreadNotificationsCountSuccess(unreadNotificatonsCount));
    } catch (err) {
      console.error(err);
    }
  };
};

interface LoadUnreadNotificationsCountSuccessAction {
  type: typeof UserNotificationsActionTypes.GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS;
  data: number;
}

const loadUnreadNotificationsCountSuccess = (
  data: number
): LoadUnreadNotificationsCountSuccessAction => ({
  type: UserNotificationsActionTypes.GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS,
  data,
});

interface ReadAllUserNotificationsAction {
  type: typeof UserNotificationsActionTypes.READ_ALL_NOTIFICATIONS;
}

export const markAllNotificationsAsRead: ActionCreator<
  ThunkAction<Promise<any>, AppState, null, ReadAllUserNotificationsAction>
> = (partnerId: string, latestNotificationTimestamp: number) => {
  return async (dispatch: Dispatch, getState) => {
    if (Object.keys(getState().userNotifications.notifications).length >= 1) {
      try {
        await notificationService.markAllNotificationsAsRead(
          partnerId,
          latestNotificationTimestamp
        );
        dispatch(readAllNotificationsSuccess());
      } catch (err) {
        console.error(err);
      }
    }
  };
};

export const archiveUserNotification: ActionCreator<
  ThunkAction<Promise<any>, AppState, null, ReadAllUserNotificationsAction>
> = (partnerId: string, notificationId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      await notificationService.archiveUserNotification(
        partnerId,
        notificationId
      );
      dispatch(archieveNotificationById(notificationId));
    } catch (err) {
      console.error(err);
    }
  };
};

interface ReadAllNotificationsSuccessAction {
  type: typeof UserNotificationsActionTypes.READ_ALL_NOTIFICATIONS_SUCCESS;
}

const readAllNotificationsSuccess = (): ReadAllNotificationsSuccessAction => ({
  type: UserNotificationsActionTypes.READ_ALL_NOTIFICATIONS_SUCCESS,
});

interface ArchiveNotificationByIdAction {
  type: typeof UserNotificationsActionTypes.REMOVE_NOTIFICATION_BY_ID;
  data: string;
}

const archieveNotificationById = (
  notificationId: string
): ArchiveNotificationByIdAction => ({
  type: UserNotificationsActionTypes.REMOVE_NOTIFICATION_BY_ID,
  data: notificationId,
});

interface UpdateHasMorePagesAction {
  type: typeof UserNotificationsActionTypes.UPDATE_HAS_MORE_PAGES;
  data: boolean;
}

export const updateHasMorePages = (
  data: boolean
): UpdateHasMorePagesAction => ({
  type: UserNotificationsActionTypes.UPDATE_HAS_MORE_PAGES,
  data,
});

interface UpdateCurrentPageNumberAction {
  type: typeof UserNotificationsActionTypes.UPDATE_CURRENT_PAGE_NUMBER;
  data: number;
}

export const updateCurrentPageNumber = (
  data: number
): UpdateCurrentPageNumberAction => ({
  type: UserNotificationsActionTypes.UPDATE_CURRENT_PAGE_NUMBER,
  data,
});

interface NotificationReceivedAction {
  type: typeof UserNotificationsActionTypes.NOTIFICATION_RECEIVED;
  data: NotificationDataType;
}

export const notificationReceivedSuccess = (
  data: NotificationDataType
): NotificationReceivedAction => ({
  type: UserNotificationsActionTypes.NOTIFICATION_RECEIVED,
  data,
});

export type UserNotificationsActions =
  | LoadBatchOfUserNotificationsAction
  | LoadBatchOfNotificationsSuccessAction
  | NotificationReceivedAction
  | LoadUnreadNotificationsCountAction
  | LoadUnreadNotificationsCountSuccessAction
  | UpdateHasMorePagesAction
  | UpdateCurrentPageNumberAction
  | ReadAllUserNotificationsAction
  | ReadAllNotificationsSuccessAction
  | LoadFirstBatchOfNotificationsSuccessAction
  | ArchiveNotificationByIdAction;
