import { AvailabilityStatus } from '../../../../models/enums';
import Partner, { PartnerMember } from '../../../../models/partner';

export default class PutPartnerDtoOut {
  id: string;
  companyName: string;
  displayName?: string;
  billingAddress: string;
  billingCity: string;
  billingPostalCode: string;
  billingCountry: string;
  homePage?: string;
  taxNumber?: string;
  vatNumber?: string;
  contactName: string;
  contactEmail: string;
  members: PartnerMember[];
  partnerCreatedInAPIM?: boolean;
  availabilityStatus: AvailabilityStatus;
  hasSubscriptions: boolean;
  hasServices: boolean;
  consumerFrameContractSigned: boolean;
  providerFrameContractSigned: boolean;
  shortDescription: string;

  static fromDto(putPartnersDtoOut: PutPartnerDtoOut): Partner {
    return {
      ...putPartnersDtoOut,
    };
  }
}
