import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { upperFirst } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ConfirmModalProperties {
  title?: string;
  message?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  onYes: () => void;
  onNo?: () => void;
}

interface ConfirmModalState {
  title?: string;
  message?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  onYes: () => void;
  onNo: () => void;
}

export let confirm: (properties: ConfirmModalProperties) => void;

const ConfirmModal: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const [state, setState] = useState<ConfirmModalState>({
    title: '',
    message: '',
    cancelButtonText: '',
    confirmButtonText: '',
    onNo: () => {
      //initialization. empty by design
    },
    onYes: () => {
      //initialization. empty by design
    },
  });

  useEffect(() => {
    const mapPropsToState = (properties: ConfirmModalProperties) => ({
      title: properties.title || upperFirst(t('confirm')),
      message: properties.message || t('areYouSureYouWantToProceed?'),
      cancelButtonText:
        properties.cancelButtonText || upperFirst(t('cancelAction')),
      confirmButtonText: properties.confirmButtonText || 'OK',
      onYes: properties.onYes,
      onNo:
        properties.onNo ||
        (() => {
          //initialization. empty by design
        }),
    });

    confirm = (props) => {
      setState(mapPropsToState(props));
      setOpen(true);
    };
  }, [setState, setOpen, t]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          state.onNo();
        }}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">{state.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            {state.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              state.onNo();
            }}
            color="primary"
          >
            {state.cancelButtonText}
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              state.onYes();
            }}
            color="primary"
            autoFocus
          >
            {state.confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmModal;
