import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Distances } from '../../AppTheme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      transition: 'all 0.10s',
      padding: `8px 25px`,
      letterSpacing: '0.5px',
      textTransform: 'none',
      color: theme.palette.typography.main,
      lineHeight: '0px',
    },
    primary: {
      width: Distances.BUTTON_LENGTH,
      height: '40px',
      borderRadius: '20px',
      lineHeight: '24px',
      backgroundColor: theme.palette.yellow.main,
      '&:hover': {
        backgroundColor: theme.palette.yellow.main,
        boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.3)',
      },
    },
    secondary: {
      borderRadius: '20px',
      backgroundColor: theme.palette.white.main,
      border: '1px solid',
      borderColor: theme.palette.typography.main,
      '&:hover': {
        backgroundColor: theme.palette.white.main,
        boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.3)',
      },
    },
    secondarySmall: {
      width: '103px',
      height: '36px',
    },
    secondaryBig: {
      width: Distances.BUTTON_LENGTH,
      height: '40px',
    },
    tertiary: {
      width: '103px',
      height: '36px',
      borderRadius: '20px',
      backgroundColor: 'transparent',
    },
    filterAndClose: {
      width: '81px',
      height: '30px',
      borderRadius: '20px',
      backgroundColor: theme.palette.yellow.main,
      '&:hover': {
        backgroundColor: theme.palette.yellow.main,
        boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.3)',
      },
      minWidth: 'max-content',
    },
    filterOption: {},
    closeIcon: {
      fontSize: '13px !important',
      lineHeight: '13px',
    },
    contactMenu: {
      width: '150px',
      height: '40px',
      borderRadius: '5px',
      padding: '10px',
      backgroundColor: theme.palette.lightRed.main,
      color: theme.palette.white.main,
      '&:hover': {
        backgroundColor: theme.palette.lightRed.main,
        boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.3)',
      },
    },
    contactForm: {
      width: '100%',
      height: '50px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      borderRadius: '5px',
      padding: '9px',

      '&:hover': {
        boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.3)',
      },
    },
    disabled: {
      filter: 'grayscale(50%)',
      borderColor: theme.palette.border.main,
    },
    disabledArrow: {
      filter: 'opacity(0.5)',
    },
    enabledArrow: {
      filter: 'opacity(1.0)',
    },
    icon: {
      display: 'flex',
      justifyContent: 'center',
      height: '13px',
    },
    downwardsArrowIcon: {
      height: '7px !important',
    },
    noBorderRadius: {
      borderRadius: '0 !important',
    },
    selector: {
      height: '27px !important',
    },
    close: {
      lineHeight: '24px',
    },
    selectorTypography: {
      lineHeight: '27px !important'
    }
  })
);

export default useStyles;
