import { mapKeys } from 'lodash';
import { Reducer } from 'redux';
import { GetPartnerProductStatisticsDtoOut } from '../../services/partner-statistics/dtos/get-partners-statistics-all-dto-out';
import {
  DashboardProductStatisticsActions,
  DashboardProductStatisticsActionTypes,
} from '../actions/dashboardStatisticsActions';

export interface DashboardStatisticsState {
  statistics: { [id: string]: GetPartnerProductStatisticsDtoOut };
  loading: { [id: string]: boolean };
  error: any;
}

const initialState: DashboardStatisticsState = {
  statistics: {},
  loading: {},
  error: null,
};

export const dashboardStatisticsReducer: Reducer<
  DashboardStatisticsState,
  DashboardProductStatisticsActions
> = (state = initialState, action): DashboardStatisticsState => {
  switch (action.type) {
    case DashboardProductStatisticsActionTypes.LOAD_PRODUCT_DASHBOARD_STATISTICS_SUCCESS: {
      const newStatistic = mapKeys([action.payload], 'id');
      return {
        ...state,
        statistics: { ...state.statistics, ...newStatistic },
        loading: { ...state.loading, [action.payload.id]: false },
        error: null,
      };
    }
    case DashboardProductStatisticsActionTypes.LOAD_PRODUCT_DASHBOARD_STATISTICS_FAIL: {
      return {
        ...state,
        error: action.payload.err,
        loading: { ...state.loading, [action.payload.id]: false },
      };
    }
    case DashboardProductStatisticsActionTypes.SET_DASHBOARD_PRODUCT_STATISTICS_LOADING: {
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.id]: action.payload.value,
        },
      };
    }
    default:
      return state;
  }
};
