import React from 'react';
import { Box, Divider, Grid, Hidden, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routes from '../../routes';
import useStyles from './Footer.styles';

const Footer: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  interface FooterLink {
    translationKey: string;
    route: string;
  }

  const footerLinks: FooterLink[] = [
    { translationKey: 'termsOfUse', route: routes.termsOfUse },
    { translationKey: 'dataPrivacy', route: routes.dataPrivacy },
    { translationKey: 'imprint', route: routes.imprint },
  ];

  const generateFooterLink = (
    route: string,
    translationKey: string,
    hasDivider: boolean,
    mdColumns: number
  ) => (
    <Grid
      item
      xs={12}
      md={mdColumns as any}
      key={translationKey}
      className={classes.linkWrapper}
    >
      <RouterLink className={classes.link} to={route}>
        {t(translationKey)}
      </RouterLink>
      <Hidden mdDown>
        <Box hidden={!hasDivider}>
          <Divider
            orientation="vertical"
            variant="middle"
            className={classes.divider}
          />
        </Box>
      </Hidden>
    </Grid>
  );

  return (
    <Box className={classes.footer}>
      <Typography className={classes.companyNameItem}>
        ©msg systems ag {new Date().getFullYear()}
      </Typography>
      <Grid container className={classes.linksContainer}>
        {footerLinks.map((footerLink, index) =>
          generateFooterLink(
            footerLink.route,
            footerLink.translationKey,
            index < footerLinks.length - 1,
            Math.floor(12 / footerLinks.length)
          )
        )}
      </Grid>
    </Box>
  );
};

export default Footer;
