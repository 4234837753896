import { Reducer } from 'redux';
import { SignalRMessageType } from '../../models/enums';
import {
  NotificationsActionTypes,
  NotificationsActions,
} from '../actions/notificationsActions';

export interface BasicAlert {
  messageKey: string;
  severity: 'success' | 'info' | 'warning' | 'error' | undefined;
}

export interface SignalRAlert extends BasicAlert {
  subscriptionId?: string;
  messageType?: SignalRMessageType;
}

export interface NotificationsState {
  signalRAlert: SignalRAlert | null;
  basicAlert: BasicAlert | null;
}

const initialNotificationsState: NotificationsState = {
  signalRAlert: null,
  basicAlert: null,
};

export const notificationsReducer: Reducer<
  NotificationsState,
  NotificationsActions
> = (state = initialNotificationsState, action) => {
  switch (action.type) {
    case NotificationsActionTypes.SET_SIGNALR_ALERT: {
      return {
        ...state,
        signalRAlert: action.alert,
      };
    }
    case NotificationsActionTypes.CLEAR_SIGNALR_ALERT: {
      return {
        ...state,
        signalRAlert: null,
      };
    }
    case NotificationsActionTypes.SET_BASIC_ALERT: {
      return {
        ...state,
        basicAlert: action.alert,
      };
    }
    case NotificationsActionTypes.CLEAR_BASIC_ALERT: {
      return {
        ...state,
        basicAlert: null,
      };
    }
    default:
      return state;
  }
};
