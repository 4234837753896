import { groupBy, map, mapKeys, mean } from 'lodash';
import { Reducer } from 'redux';
import {
  ProductCoreUsage,
  SubscriptionCoreUsage,
} from '../../models/subscriptionUsages';
import {
  SubscriptionsUsagesActions,
  SubscriptionsUsagesActionTypes,
} from '../actions/subscriptionsUsagesActions';

export interface SubscriptionsUsagesState {
  usagesBySubscriptions: { [subscriptionId: string]: SubscriptionCoreUsage };
  usagesByProducts: { [productId: string]: ProductCoreUsage };
  loading: boolean;
  error: any;
}

const initialState: SubscriptionsUsagesState = {
  usagesBySubscriptions: {},
  usagesByProducts: {},
  loading: false,
  error: null,
};

const bySubscriptionToByProduct = ({
  subscriptionId,
  note,
  ...byProduct
}: SubscriptionCoreUsage) => byProduct;

const groupUsageByProduct = (subscriptionUsage: SubscriptionCoreUsage[]) =>
  mapKeys(
    map(groupBy(subscriptionUsage, 'productId'), (c) =>
      c.reduce((u1, u2) => ({
        ...u1,
        unitLabel: u1.unitLabel || u2.unitLabel,
        quantity: u1.quantity + u2.quantity,
        callCountTotal: u1.callCountTotal + u2.callCountTotal,
        errorRate: mean([u1.errorRate, u2.errorRate]),
        latencyAvg: mean([u1.latencyAvg, u2.latencyAvg]),
      }))
    ).map(bySubscriptionToByProduct),
    'productId'
  );

export const subscriptionsUsagesReducer: Reducer<
  SubscriptionsUsagesState,
  SubscriptionsUsagesActions
> = (state = initialState, action): SubscriptionsUsagesState => {
  switch (action.type) {
    case SubscriptionsUsagesActionTypes.LOAD_USAGE_SUCCESS: {
      return {
        ...state,
        usagesBySubscriptions: mapKeys(action.payload, 'subscriptionId'),
        usagesByProducts: groupUsageByProduct(action.payload),
        loading: false,
        error: null,
      };
    }
    case SubscriptionsUsagesActionTypes.LOAD_USAGE_FAIL: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    case SubscriptionsUsagesActionTypes.SET_USAGE_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default:
      return state;
  }
};
