const routes = {
  home: '/',
  productCatalog: '/catalog',
  checkout: {
    base: '/checkout/:id/:planId',
    success: '/checkout-success/:subscriptionId',
  },
  dashboard: {
    base: '/dashboard',
    paymentMethods: '/payment-methods',
    subscriptions: '/subscriptions',
    products: '/products',
    billing: '/billing',
    partnerDetails: '/partner-details',
    partnerMembers: '/partner-members',
    partnerApplications: '/partner-applications',
    subscriptionUsage: '/subscription-usage',
    subscriptionCost: '/subscription-cost',
    securityLogs: '/security-logs',
    newProduct: '/product-configuration',
    productConfiguration: '/product-configuration/:productId?',
  },
  service: '/service/:id/:tab',
  profile: '/profile',
  ourVision: '/our-vision',
  favoriteProducts: '/favoriteProducts',
  imprint: '/imprint',
  dataPrivacy: '/data-privacy',
  termsOfUse: '/terms-of-use',
  notFound: '*',
  invitation: '/invitation/:id',
};

export default routes;
