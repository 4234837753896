import { Dispatch, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '..';
import { GetPartnerProductStatisticsDtoOut } from '../../services/partner-statistics/dtos/get-partners-statistics-all-dto-out';
import { partnerStatisticsService } from '../../services/partner-statistics/partner-statistics-service';

export enum DashboardProductStatisticsActionTypes {
  LOAD_PRODUCT_DASHBOARD_STATISTICS = '[DashboardStatistics] Load product dashboard statistics',
  LOAD_PRODUCT_DASHBOARD_STATISTICS_SUCCESS = '[DashboardStatistics] Load product dashboard statistics success',
  LOAD_PRODUCT_DASHBOARD_STATISTICS_FAIL = '[DashboardStatistics] Load product dashboard statistics fail',
  SET_DASHBOARD_PRODUCT_STATISTICS_LOADING = '[DashboardStatistics] Set dashboard product statistics loading',
}
interface LoadDashboardProductStatisticsAction {
  type: typeof DashboardProductStatisticsActionTypes.LOAD_PRODUCT_DASHBOARD_STATISTICS;
}

export const loadDashboardProductStatistics: ActionCreator<
  ThunkAction<
    Promise<any>,
    AppState,
    null,
    LoadDashboardProductStatisticsAction
  >
> = (productId: string) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      const currentPartner = getState().partner.currentPartner;
      if (!currentPartner) {
        dispatch(
          loadDashboardProductStatisticsFail('Current partner not loaded')
        );
        return;
      }
      dispatch(setDashboardStatisticsLoading({ id: productId, value: true }));
      const statistics =
        await partnerStatisticsService.getProductDashboardStatistics(
          currentPartner.id,
          productId
        );
      dispatch(loadDashboardProductStatisticsSuccess(statistics));
    } catch (err) {
      dispatch(loadDashboardProductStatisticsFail({ err: err, id: productId }));
    }
  };
};

const loadDashboardProductStatisticsFail = (
  payload: any
): LoadDashboardProductStatisticsFailAction => ({
  type: DashboardProductStatisticsActionTypes.LOAD_PRODUCT_DASHBOARD_STATISTICS_FAIL,
  payload,
});

interface LoadDashboardProductStatisticsFailAction {
  type: typeof DashboardProductStatisticsActionTypes.LOAD_PRODUCT_DASHBOARD_STATISTICS_FAIL;
  payload: any;
}

interface SetDashboardStatisticsLoadingAction {
  type: typeof DashboardProductStatisticsActionTypes.SET_DASHBOARD_PRODUCT_STATISTICS_LOADING;
  payload: { id: string; value: boolean };
}

const setDashboardStatisticsLoading = (payload: {
  id: string;
  value: boolean;
}): SetDashboardStatisticsLoadingAction => ({
  type: DashboardProductStatisticsActionTypes.SET_DASHBOARD_PRODUCT_STATISTICS_LOADING,
  payload,
});

interface LoadDashboardProductStatisticsSuccessAction {
  type: typeof DashboardProductStatisticsActionTypes.LOAD_PRODUCT_DASHBOARD_STATISTICS_SUCCESS;
  payload: GetPartnerProductStatisticsDtoOut;
}

const loadDashboardProductStatisticsSuccess = (
  payload: GetPartnerProductStatisticsDtoOut
): LoadDashboardProductStatisticsSuccessAction => ({
  type: DashboardProductStatisticsActionTypes.LOAD_PRODUCT_DASHBOARD_STATISTICS_SUCCESS,
  payload,
});

export type DashboardProductStatisticsActions =
  | LoadDashboardProductStatisticsAction
  | LoadDashboardProductStatisticsSuccessAction
  | LoadDashboardProductStatisticsFailAction
  | SetDashboardStatisticsLoadingAction;
