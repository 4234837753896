import { appConfiguration } from '../../configuration';
import {
  authScopes,
  getCurrentAccount,
  acquireTokenSilentOrRefreshToken,
} from '../auth';
import axios from '../httpClient';
import { GetPartnersProductsAllDtoOut } from './dtos/get-partners-products-all/get-partners-products-all-dto-out';

class DashboardProductsService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async getAllDashboardProducts(
    partnerId: string
  ): Promise<GetPartnersProductsAllDtoOut[]> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return (
      await axios.get(`${this.baseUrl}/partners/${partnerId}/products`, {
        headers: {
          Authorization: `Bearer ${authResult.idToken}`,
        },
      })
    ).data?.map(GetPartnersProductsAllDtoOut.fromDto);
  }
  async deleteProduct(
    productId: string,
    partnerId: string
  ): Promise<{ id: string }> {
    const authResult = await acquireTokenSilentOrRefreshToken({
      scopes: authScopes,
      account: getCurrentAccount(),
    });

    return (
      await axios.delete(`${this.baseUrl}/products/${productId}`, {
        headers: {
          Authorization: `Bearer ${authResult.idToken}`,
          'partner-id': partnerId,
        },
      })
    ).data;
  }
}

export const dashboardProductsService = new DashboardProductsService(
  appConfiguration()?.coreApiUrl ?? ''
);
